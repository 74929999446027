import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState
} from 'react'
import { GlobalNotification } from './GlobalNotification'
import { ShowNotification } from '../../service/interface'
import { Notification, NotificationType } from './Types'
import {AxiosBackend} from "../../service/HTTP/AxiosInstance";

export const GlobalNotificationContext = createContext<ShowNotification>(
  (): void => {
    // Just initial value function
  }
)

export const GlobalNotificationProvider = (props: {
  children: ReactNode
}): JSX.Element => {
  const [notificationText, setNotificationText] = useState<string>('')
  const [notificationType, setNotificationType] = useState<Notification>(
    NotificationType.INFO
  )
  const [isNotificationVisible, setIsNotificationVisible] =
    useState<boolean>(false)
  const { children: childComponents } = props

  const show = useCallback(
    (type: Notification, text: string): void => {
      setNotificationText(text)
      if (type !== notificationType) {
        setNotificationType(type)
      }
      setIsNotificationVisible(true)
    },
    [notificationType]
  )

  useEffect((): (() => void) => {
    if (isNotificationVisible) {
      const timer = setTimeout((): void => {
        setIsNotificationVisible(false)
      }, 4000)

      return (): void => clearTimeout(timer)
    }

    return (): void => {
      // Empty function just for make TS happy
    }
  }, [isNotificationVisible])

  useEffect((): (() => void) => {
    AxiosBackend.interceptors.response.use(undefined, (error): Promise<void> => {
      if (error.data) {
        const { data } = error
        if (data && data.view_message) {
          show(NotificationType.ERROR, data.view_message)
        }
        return Promise.reject(error)
      }
      return Promise.reject(error)
    })
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return (): void => {}
  }, [])

  return (
    <GlobalNotificationContext.Provider value={show}>
      <GlobalNotification
        text={notificationText}
        type={notificationType}
        visible={isNotificationVisible}
      />
      {childComponents}
    </GlobalNotificationContext.Provider>
  )
}
