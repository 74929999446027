import { Button } from 'semantic-ui-react'
import styles from './SwitchViewMode.module.css'

interface Props {
  primaryHandler: () => void
  secondaryHandler: () => void
}

export const SwitchViewMode = ({
  primaryHandler,
  secondaryHandler
}: Props): JSX.Element => (
  <section className={styles.SwitchViewModeContainer}>
    <Button onClick={(): void => primaryHandler()} primary>
      Edycja produktu
    </Button>
    <Button onClick={(): void => secondaryHandler()} secondary>
      Podgląd produktu
    </Button>
  </section>
)
