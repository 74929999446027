import { stripePromise } from '../../const/stripe'
import {APP_DOMAIN_APP, getProperAppUrl, STRIPE_NOT_DEFINED} from '../../const/consts'
import { SubscriptionTypes } from '../Product/ProductService'
import { PaymentType, Subscription } from '../../Context/Dictionaries/api'
import {AxiosBackend} from "../HTTP/AxiosInstance";

interface CheckoutProductPrzelewy24Props {
  productId: string
  email: string
}

interface CheckoutSubscriptionPrzelewy24Props {
  subscriptionId: string
  email: string
}

/** ************************** Start Product Przelewy 24 Payment ******************************* */

export const CheckoutProductPrzelewy24 = async (
  data: CheckoutProductPrzelewy24Props
) => {
  // Get Stripe.js instance
  const stripe = await stripePromise

  if (!stripe) {
    throw Error(STRIPE_NOT_DEFINED)
  }

  // Call your backend to create the Checkout Session
  const response = await AxiosBackend.post('/create-przelewy24-product-checkout', {
    productId: data.productId
  })
  if (response && response.data) {
    const clientSecret = response.data.client_secret
    await stripe
      .confirmP24Payment(clientSecret, {
        payment_method: {
          billing_details: { email: data.email }
        },
        return_url: `${getProperAppUrl()}/checkout/product/success`
      })
      .then(null)
  }
}

/** ------------------------------ END Product Przelewy 24 Payment ------------------------------ */

/** ************************** Start Subscription Przelewy 24 Payment ******************************* */

export const CheckoutSubscriptionPrzelewy24 = async (
  data: CheckoutSubscriptionPrzelewy24Props
) => {
  // Get Stripe.js instance
  const stripe = await stripePromise

  if (!stripe) {
    throw Error(STRIPE_NOT_DEFINED)
  }

  // Call your backend to create the Checkout Session
  const response = await AxiosBackend.post(
    '/create-przelewy24-subscription-checkout',
    { subscriptionId: data.subscriptionId }
  )
  if (response && response.data) {
    const clientSecret = response.data.client_secret
    await stripe
      .confirmP24Payment(clientSecret, {
        payment_method: {
          billing_details: { email: data.email }
        },
        return_url: `${getProperAppUrl()}/checkout/subscription/success`
      })
      .then(data => {
        console.log('data', data)
      })
      .catch(err => console.log(err))
  }
}

/** ------------------------------ END Subscription Przelewy 24 Payment ------------------------------ */

/** ************************** Start Subscription Session ******************************* */

export const checkoutSubscription = async (
  subscriptionType: SubscriptionTypes
) => {
  // Get Stripe.js instance
  const stripe = await stripePromise
  const queryString = `?requestedSubscriptionType=${subscriptionType}`

  if (!stripe) {
    throw Error(STRIPE_NOT_DEFINED)
  }

  // Call your backend to create the Checkout Session
  const response = await AxiosBackend.post(
    `/create-checkout-subscription-session${queryString}`
  )

  // When the customer clicks on the button, redirect them to Checkout.
  const result = await stripe.redirectToCheckout({
    sessionId: response.data.id
  })

  if (result.error) {
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `result.error.message`.
  }
}

/** ------------------------------ END Subscription Session ------------------------------ */

export const initiateProperPaymentProcess = async (
  chosenSubl: Subscription,
  email: string
) => {
  if (chosenSubl.paymentType === PaymentType.SUBSCRIPTION) {
    try {
      await checkoutSubscription(chosenSubl.subType)
    } catch (err) {
      throw new Error('Something went wrong with single payment initialization')
    }
  }
  if (chosenSubl.paymentType === PaymentType.ONE_TIME_PAYMENT) {
    try {
      await CheckoutSubscriptionPrzelewy24({
        email,
        subscriptionId: chosenSubl.id
      })
    } catch (err) {
      throw new Error('Something went wrong with sub initialization')
    }
  }
}
