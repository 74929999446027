import { squareCakeValidator } from './SquareCakeValidator'
import {
  getSquareCakePortionsMin,
  getSquareCakePortionsMax,
  CalculatorOperations
} from '../../../../helpers/cake'

interface Result {
  MIN: number
  MAX: number
}

interface SquarePortionsCalculatorActions {
  height: string
  sideLength: string
  errors: string[]
  result: Result | null
  calculate: () => void
}

export const initialSquarePortionsReducer: SquarePortionsCalculatorActions = {
  height: '',
  sideLength: '',
  errors: [],
  result: null,
  calculate: (): void => {
    // INITIAL VALUE
  }
}

const updateValidationErrors = (
  errors: string[],
  state: SquarePortionsCalculatorActions
): SquarePortionsCalculatorActions => ({
  ...state,
  errors,
  result: null
})

const calculateResult = (
  state: SquarePortionsCalculatorActions
): SquarePortionsCalculatorActions => ({
  ...state,
  result: {
    MIN: Math.round(
      getSquareCakePortionsMin(
        parseFloat(state.height),
        parseFloat(state.sideLength)
      )
    ),
    MAX: Math.round(
      getSquareCakePortionsMax(
        parseFloat(state.height),
        parseFloat(state.sideLength)
      )
    )
  },
  errors: []
})

export enum SquarePortionsReducerActionName {
  CALCULATE_RESULT = 'CALCULATE_RESULT',
  UPDATE_SQUARE_PORTIONS_CALC_DATA = 'UPDATE_SQUARE_PORTIONS_CALC_DATA'
}

export type SquarePortionsReducer =
  | {
      type: SquarePortionsReducerActionName.CALCULATE_RESULT
    }
  | {
      type: SquarePortionsReducerActionName.UPDATE_SQUARE_PORTIONS_CALC_DATA
      data: CalculatorOperations
    }

const calculate = (
  state: SquarePortionsCalculatorActions
): SquarePortionsCalculatorActions => {
  const validationErrors = squareCakeValidator(state.height, state.sideLength)
  if (validationErrors.length > 0) {
    return updateValidationErrors(validationErrors, state)
  }
  return calculateResult(state)
}

const updateCalcData = (
  data: CalculatorOperations,
  state: SquarePortionsCalculatorActions
): SquarePortionsCalculatorActions => ({
  ...state,
  [data.fieldName]: data.fieldValue
})

export const squareCakeReducer = (
  state: SquarePortionsCalculatorActions,
  action: SquarePortionsReducer
): SquarePortionsCalculatorActions => {
  switch (action.type) {
    case SquarePortionsReducerActionName.CALCULATE_RESULT:
      return calculate(state)
    case SquarePortionsReducerActionName.UPDATE_SQUARE_PORTIONS_CALC_DATA:
      return updateCalcData(action.data, state)
    default:
      return state
  }
}
