import { useContext } from 'react'
import { getQueryParamsFromLocationSearch } from '../../../../helpers/http'
import { ButtonByAdam } from '../../../UI/buttons/ButtonByAdam/ButtonByAdam'
import { ResetPasswordModal } from '../../../ResetPasswordModal/ResetPasswordModal'
import { GlobalNotificationContext } from '../../../../Context/GlobalNotification/GlobalNotificationProvider'
import { GlobalLoaderContext } from '../../../../Context/GlobalLoader/GlobalLoader'
import { ParagraphDefault } from '../../../UI/Paragraphs/ParagraphDefault/ParagraphDefault'
import { NotificationType } from '../../../../Context/GlobalNotification/Types'
import { resetPassword } from '../../../../service/User/api'

export const ResetPasswordSection = (): JSX.Element => {
  const showNotification = useContext(GlobalNotificationContext)
  const showLoader = useContext(GlobalLoaderContext)

  const { token } = getQueryParamsFromLocationSearch(window.location.search)

  return (
    <section>
      <div style={{ marginTop: '20px' }}>
        <ParagraphDefault>
          Kliknij jeżeli chcesz zmienić swoje hasło do konta.
        </ParagraphDefault>
        <ButtonByAdam
          btnType='warning'
          onClick={(): void => {
            showLoader(true)
            resetPassword()
              .then((): void => {
                showLoader(false)
                showNotification(
                  NotificationType.INFO,
                  'Wysłaliśmy Ci link umożliwiający reset hasła dla Twojego konta. Zobacz czy nie ma go w SPAMIE'
                )
              })
              .catch((): void => showLoader(false))
          }}
        >
          Resetuj hasło
        </ButtonByAdam>
      </div>

      {token && <ResetPasswordModal token={token} />}
    </section>
  )
}
