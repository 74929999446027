import {
  createContext,
  FC,
    ReactNode,
  useEffect,
  useLayoutEffect,
  useMemo,
  useReducer,
  useState
} from 'react'
import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem'
import { cakePriceReducer } from './CakePriceCalculatorReducer'
import {
  calculateAllProductsPrice,
  calculateDeliveryTime,
  calculateFixedPrice,
  calculateWorkingTime,
  mainInfoToDropdownProps,
  pricingBackToFrontAdapter
} from '../utils'
import {
  ActionNames,
  CakePriceCalcReducer,
  UpdateAdditionalSpendingsConfig,
  UpdateDeliveryTime,
  UpdateFixedCostsConfig,
  UpdateGroceryConfig,
  UpdateWorkingTime
} from './Reducer/interfaces'
import { CakePriceCalcContext } from './interfaces'
import {
  cakePriceReducerSuperInitial,
  getAllPricings,
  getPricing
} from '../API/mock'

export const CakePriceCalculatorContext =
  createContext<CakePriceCalcContext | null>(null)

interface Props {
    children: ReactNode
}

export const CakePriceCalculatorContextProvider: FC<Props> = ({
  children
}): JSX.Element => {
  const [priceCakeCalculatorData, dispatch] = useReducer(
    cakePriceReducer,
    cakePriceReducerSuperInitial
  )
  const [avaliablePricings, setAvaliablePricings] = useState<
    DropdownItemProps[]
  >([])

  useEffect((): void => {
    if (priceCakeCalculatorData.id === '' && avaliablePricings.length > 0) {
      getPricing(avaliablePricings[0].value as string).then(response => {
        if (response.status === 200) {
          dispatch({
            type: ActionNames.UPDATE_CALCULATOR_DATA,
            data: pricingBackToFrontAdapter(response.data)
          })
        }
      })
    }
  }, [avaliablePricings])

  useLayoutEffect((): void => {
    getAllPricings()
      .then(response => {
        if (response.status === 200 && response.data) {
          setAvaliablePricings(mainInfoToDropdownProps(response.data))
        }
      })
      .finally(null)
  }, [])

  const groceryProductsPriceSummary = calculateAllProductsPrice(
    priceCakeCalculatorData.groceryProducts
  )
  const additionalSpendingsPriceSummary = calculateAllProductsPrice(
    priceCakeCalculatorData.additionalSpending
  )
  const workingTimePriceSummary = calculateWorkingTime(
    priceCakeCalculatorData.workingTime
  )
  const deliveryTimePriceSummary = calculateDeliveryTime(
    priceCakeCalculatorData.deliveryTime
  )
  const fixedCostsSummary = calculateFixedPrice(
    priceCakeCalculatorData.fixedCosts
  )

  const contextValue = useMemo(
    () => ({
      id: priceCakeCalculatorData.id,
      pricingName: priceCakeCalculatorData.pricingName,
      quantity: priceCakeCalculatorData.quantity,
      groceryProducts: {
        products: priceCakeCalculatorData.groceryProducts,
        updateGroceryProduct: (data: UpdateGroceryConfig): void =>
          dispatch({
            type: ActionNames.UPDATE_GROCERY_PRODUCT,
            data
          }),
        addGroceryProduct: (): void =>
          dispatch({ type: ActionNames.ADD_GROCERY_PRODUCT }),
        removeGroceryProduct: (id: string): void =>
          dispatch({
            type: ActionNames.REMOVE_GROCERY_PRODUCT,
            id
          })
      },

      additionalSpending: {
        products: priceCakeCalculatorData.additionalSpending,
        updateAdditionalSpendings: (
          data: UpdateAdditionalSpendingsConfig
        ): void =>
          dispatch({
            type: ActionNames.UPDATE_ADDITIONAL_SPENDINGS,
            data
          }),
        addAdditionalSpendings: (): void =>
          dispatch({ type: ActionNames.ADD_ADDITIONAL_SPENDINGS }),
        removeAdditionalSpendings: (id: string): void =>
          dispatch({
            type: ActionNames.REMOVE_ADDITIONAL_SPENDINGS,
            id
          })
      },

      workingTime: {
        data: priceCakeCalculatorData.workingTime,
        updateWorkingTime: (data: UpdateWorkingTime): void =>
          dispatch({
            type: ActionNames.UPDATE_WORKING_TIME,
            data
          })
      },

      fixedCosts: {
        data: priceCakeCalculatorData.fixedCosts,
        updateCost: (data: UpdateFixedCostsConfig) =>
          dispatch({
            type: ActionNames.UPDATE_FIXED_COST,
            data
          }),
        addFixedCost: () =>
          dispatch({
            type: ActionNames.ADD_FIXED_COST
          }),
        removeFixedCost: (id: string) =>
          dispatch({
            type: ActionNames.REMOVE_FIXED_COST,
            id
          }),
        updateFixedSweetCounter: (value: number) =>
          dispatch({
            type: ActionNames.UPDATE_FIXED_SWEET_COUNTER,
            value
          })
      },

      deliveryTime: {
        data: priceCakeCalculatorData.deliveryTime,
        updateDeliveryTime: (data: UpdateDeliveryTime): void =>
          dispatch({
            type: ActionNames.UPDATE_DELIVERY_TIME,
            data
          })
      },

      summary: {
        groceryProducts: groceryProductsPriceSummary,
        additionalSpendings: additionalSpendingsPriceSummary,
        workingTime: workingTimePriceSummary,
        deliveryTime: deliveryTimePriceSummary,
        fixedCosts: fixedCostsSummary,
        all(): number {
          return (
            groceryProductsPriceSummary +
            workingTimePriceSummary +
            deliveryTimePriceSummary +
            additionalSpendingsPriceSummary +
            fixedCostsSummary
          )
        }
      },
      updateCalculatorData: (data: CakePriceCalcReducer): void =>
        dispatch({
          type: ActionNames.UPDATE_CALCULATOR_DATA,
          data
        }),
      updateCalculatorProductName: (data: string): void =>
        dispatch({
          type: ActionNames.UPDATE_CALCULATOR_PRODUCT_NAME,
          data
        }),
      avaliablePricings,
      setAvaliablePricings
    }),
    [priceCakeCalculatorData, avaliablePricings]
  )

  return (
    <CakePriceCalculatorContext.Provider value={contextValue}>
      {children}
    </CakePriceCalculatorContext.Provider>
  )
}
