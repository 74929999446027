import styles from './HideIcon.module.scss'
import {
  FiChevronLeftIcon,
  FiChevronRightIcon
} from '../../../Assets/ReactIcons'

interface Props {
  isVisible: boolean
  onClickHandler: () => void
}

export const HideIcon = ({ isVisible, onClickHandler }: Props): JSX.Element => (
  <aside
    role='presentation'
    className={`${styles.HideIconContainer} ${
      !isVisible && styles.HiddenState
    }`}
    onClick={(): void => onClickHandler()}
  >
    {isVisible ? FiChevronLeftIcon : FiChevronRightIcon}
  </aside>
)
