import { ErrorsData } from './utils'

const MAX_PRICING_TITLE_LENGTH = 40
const MAX_PRODUCT_NAME_LENGTH = 32

export const CalculatorPriceStringValidator = (text: string): ErrorsData => {
  const errorMessages: string[] = []

  if (text.length >= MAX_PRICING_TITLE_LENGTH) {
    errorMessages.push(`Max ${MAX_PRICING_TITLE_LENGTH} znaków`)
  }

  if (text.length <= 0) {
    errorMessages.push(`Min ${0} znaków`)
  }

  return {
    isValid: errorMessages.length === 0,
    errorMessages
  }
}

export const CalculatorProductNameValidator = (text: string): ErrorsData => {
  const errorMessages: string[] = []

  if (text.length >= MAX_PRODUCT_NAME_LENGTH) {
    errorMessages.push(`Max ${MAX_PRODUCT_NAME_LENGTH} znaków`)
  }

  if (text.length <= 0) {
    errorMessages.push(`Min ${0} znaków`)
  }

  return {
    isValid: errorMessages.length === 0,
    errorMessages
  }
}
