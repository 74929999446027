import {
  CalculatorOperations,
  frostingRoundedCakeAmount,
  getIngredientsByFrosting
} from '../../../../helpers/cake'
import { roundedCakeValidator } from './RoundedCakeValidator'

interface RoundedPortionsCalculatorActions {
  height: string
  d: string
  errors: string[]
  chosenIcing: string
  ingredients: string[]
  result: number | null
  calculate: () => void
}

export const initialRoundedPortionsReducer: RoundedPortionsCalculatorActions = {
  height: '',
  d: '',
  errors: [],
  chosenIcing: '',
  ingredients: [],
  result: null,
  calculate: (): void => {
    // INITIAL VALUE
  }
}

const updateValidationErrors = (
  errors: string[],
  state: RoundedPortionsCalculatorActions
): RoundedPortionsCalculatorActions => ({
  ...state,
  errors,
  ingredients: [],
  result: null
})

const calculateResult = (
  state: RoundedPortionsCalculatorActions
): RoundedPortionsCalculatorActions => {
  const result = Math.round(
    frostingRoundedCakeAmount(parseFloat(state.height), parseFloat(state.d))
  )
  return {
    ...state,
    result,
    ingredients: getIngredientsByFrosting(state.chosenIcing, result),
    errors: []
  }
}

export enum RoundedPortionsReducerActionName {
  CALCULATE_RESULT = 'CALCULATE_RESULT',
  UPDATE_ROUNDED_PORTIONS_CALC_DATA = 'UPDATE_ROUNDED_PORTIONS_CALC_DATA'
}

export type RoundedPortionsReducerActions =
  | {
      type: RoundedPortionsReducerActionName.CALCULATE_RESULT
    }
  | {
      type: RoundedPortionsReducerActionName.UPDATE_ROUNDED_PORTIONS_CALC_DATA
      data: CalculatorOperations
    }

const calculate = (
  state: RoundedPortionsCalculatorActions
): RoundedPortionsCalculatorActions => {
  const validationErrors = roundedCakeValidator(state.height, state.d)
  if (validationErrors.length > 0) {
    return updateValidationErrors(validationErrors, state)
  }
  return calculateResult(state)
}

const updateCalcData = (
  data: CalculatorOperations,
  state: RoundedPortionsCalculatorActions
): RoundedPortionsCalculatorActions => ({
  ...state,
  [data.fieldName]: data.fieldValue
})

export const roundedCakeReducer = (
  state: RoundedPortionsCalculatorActions,
  action: RoundedPortionsReducerActions
): RoundedPortionsCalculatorActions => {
  switch (action.type) {
    case RoundedPortionsReducerActionName.CALCULATE_RESULT:
      return calculate(state)
    case RoundedPortionsReducerActionName.UPDATE_ROUNDED_PORTIONS_CALC_DATA:
      return updateCalcData(action.data, state)
    default:
      return state
  }
}
