import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem'

export const productTypeOptions: DropdownItemProps[] = [
  { key: 'course', value: 'course', text: 'Kurs' },
  { key: 'live', value: 'live', text: 'live' }
]

export const SourceTypeOptions: DropdownItemProps[] = [
  { key: 'vimeo', value: 'vimeo', text: 'Vimeo' },
  { key: 'youtube', value: 'youtube', text: 'Youtube' }
]

export const fieldTypeLabels: DropdownItemProps[] = [
  { key: 'header', value: 'header', text: 'header' },
  { key: 'paragraph', value: 'paragraph', text: 'paragraph' }
]

export const tagsForSelect: DropdownItemProps[] = [
  { key: 'cake', value: 'cake', text: 'torty' },
  { key: 'figurine', value: 'figurine', text: 'figurki' },
  { key: 'decoration', value: 'decoration', text: 'dekoracje' },
  { key: 'creams', value: 'creams', text: 'kremy' },
  { key: 'frosting', value: 'frosting', text: 'tynkowanie' },
  { key: 'equipment', value: 'equipment', text: 'wyposażenie' },
  { key: 'spongecake', value: 'spongecake', text: 'biszkopty' },
  { key: 'soak', value: 'soak', text: 'nasączenia' },
  { key: 'cake3d', value: 'cake3d', text: 'torty 3D' },
  { key: 'fondant', value: 'fondant', text: 'masa cukrowa' },
  { key: 'drip', value: 'drip', text: 'drip' },
  { key: 'live', value: 'live', text: 'Na żywo' }
]
