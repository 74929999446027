import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { DefaultHeader } from '../../components/UI/headers/DefaultHeader/DefaultHeader'
import { ParagraphDefault } from '../../components/UI/Paragraphs/ParagraphDefault/ParagraphDefault'
import { UserReducerActions } from '../../Context/User/UserReducer'
import { UserContext } from '../../Context/User/UserContext'
import { getUserSubscriptionData } from '../../service/Stripe/customer'

export const CanceledSubscriptionPayment = (): JSX.Element => {
  const navigate = useNavigate()
  const userContext: UserReducerActions = useContext(UserContext)

  useEffect((): void => {
    if (userContext.userDetails.stripeCustomerId) {
      getUserSubscriptionData(userContext.userDetails.stripeCustomerId).then(
        null
      )
    }
  }, [])

  setTimeout((): void => {
    navigate('/subskrypcje')
  }, 4000)

  return (
    <section>
      <DefaultHeader style={{ color: 'red' }}>
        Platność za subskrypcję nie powiodła się
      </DefaultHeader>
      <ParagraphDefault>
        Spróbuj jeszcze raz. Jeżeli problem się powtórzy daj znać
        administratowowi! Dziękujemy :)
      </ParagraphDefault>
    </section>
  )
}
