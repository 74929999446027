import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ParagraphDefault } from '../../components/UI/Paragraphs/ParagraphDefault/ParagraphDefault'
import { DefaultHeader } from '../../components/UI/headers/DefaultHeader/DefaultHeader'
import { getUserSubscriptionData } from '../../service/Stripe/customer'
import { UserReducerActions } from '../../Context/User/UserReducer'
import { UserContext } from '../../Context/User/UserContext'

export const SuccessfulSubscriptionPayment = (): JSX.Element => {
  const navigate = useNavigate()
  const userContext: UserReducerActions = useContext(UserContext)

  useEffect((): void => {
    if (userContext.userDetails.stripeCustomerId) {
      getUserSubscriptionData(userContext.userDetails.stripeCustomerId).then(
        null
      )
    }
  }, [])

  setTimeout((): void => {
    navigate('/products/bought')
  }, 4000)

  return (
    <section>
      <DefaultHeader style={{ color: 'green' }}>
        Platność przebiegła pomyślnie
      </DefaultHeader>
      <ParagraphDefault>
        Za moment zostaniesz przekierowany do zakładki z dostępnymi dla Ciebie
        kursami. Udanej nauki!
      </ParagraphDefault>
    </section>
  )
}
