import React, { useEffect, useState } from 'react'
import {Input, LabelProps, SemanticShorthandItem} from 'semantic-ui-react'
import styles from '../SharedInputStyles.module.scss'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string
  labelPosition?: 'left' | 'right' | 'left corner' | 'right corner'
  labelConfig?: SemanticShorthandItem<LabelProps>
  titleColor?: 'pink' | 'blue' | 'green'
  title?: string
  dataRequired?: boolean
  onChangeHandler?: (val: string) => void
}

export const InputTextLabeled = ({
  onChangeHandler,
  value,
  title,
  dataRequired,
  titleColor,
  labelPosition = 'right',
  labelConfig = { basic: true, content: 'cm' },
  ...props
}: Props): JSX.Element => {
  const [inputValue, setInputValue] = useState<string>(value || '')

  useEffect((): void => {
    if (value !== inputValue) {
      setInputValue(value)
    }
  }, [value])

  return (
    <div
      className={`
    ${styles.InputContainer}
    ${titleColor === 'blue' ? styles.LabelBlue : ''}
    ${titleColor === 'pink' ? styles.LabelPinky : ''}
    ${titleColor === 'green' ? styles.LabelGreeny : ''}
    `}
    >
      <span
        className={`${styles.Label}`}
        style={{ visibility: title ? 'visible' : 'hidden' }}
      >
        {title}
        {dataRequired && <span style={{ color: 'red' }}>*</span>}
      </span>
      <Input
        label={labelConfig}
        labelPosition={labelPosition}
        value={inputValue}
        placeholder={props.placeholder}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
          setInputValue(e.target.value)
          if (onChangeHandler) {
            onChangeHandler(e.target.value)
          }
        }}
        type={props.type}
        disabled={props.disabled}
      />
    </div>
  )
}
