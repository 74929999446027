import { Product } from '../../service/Product/ProductService'
import { INIT_USER, User } from '../../service/User/UserService'

export interface UserOperations {
  fieldName: string
  fieldValue: string
}

export interface UserReducerActions {
  userDetails: User
  updateUser: () => void
  updateUserValue: (userOperations: UserOperations) => void
  checkIfAvailableToWatch: (product: Product) => boolean
}

export const initialUserReducer: UserReducerActions = {
  userDetails: { ...INIT_USER },
  updateUser: () => ({}),
  updateUserValue: () => ({}),
  checkIfAvailableToWatch: (): boolean => false
}

export enum UserReducerActionNames {
  UPDATE_USER_FIELD = 'UPDATE_USER_FIELD',
  UPDATE_USER_DATA = 'UPDATE_USER_DATA'
}

export type UserDataReducerAction =
  | {
      type: UserReducerActionNames.UPDATE_USER_DATA
      data: User
    }
  | {
      type: UserReducerActionNames.UPDATE_USER_FIELD
      data: UserOperations
    }

const updateUser = (
  data: User,
  state: UserReducerActions
): UserReducerActions => ({
  ...state,
  userDetails: {
    ...data
  }
})

const updateUserValue = (
  data: UserOperations,
  state: UserReducerActions
): UserReducerActions => ({
  ...state,
  userDetails: {
    ...state.userDetails,
    [data.fieldName]: data.fieldValue
  }
})

export const userReducer = (
  state: UserReducerActions,
  action: UserDataReducerAction
): UserReducerActions => {
  switch (action.type) {
    case UserReducerActionNames.UPDATE_USER_DATA:
      return updateUser(action.data, state)
    case UserReducerActionNames.UPDATE_USER_FIELD:
      return updateUserValue(action.data, state)
    default:
      return state
  }
}
