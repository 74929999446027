import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './BuyProductRow.module.scss'
import { UserReducerActions } from '../../../Context/User/UserReducer'
import { UserContext } from '../../../Context/User/UserContext'
import { ButtonByAdam } from '../../UI/buttons/ButtonByAdam/ButtonByAdam'
import { TiWarningIcon } from '../../../Assets/ReactIcons'
import { ModalPaymentProps } from '../../UI/Modals/ProductPaymentModal/ProductPaymentModal'

interface Props {
  productPrice: number
  productID: string
  getPaymentModalData: React.Dispatch<React.SetStateAction<ModalPaymentProps>>
}

export const BuyProductRow = ({
  getPaymentModalData,
  productID,
  productPrice
}: Props): JSX.Element => {
  const userContext: UserReducerActions = useContext(UserContext)
  const navigate = useNavigate()
  return (
    <section className={styles.ProductPaymentContainer}>
      <div className={styles.Header}>
        {TiWarningIcon}
        <h2>Nie masz dostępu do tego kursu</h2>
      </div>

      <div className={styles.RowsContainer}>
        {/* Single payment */}
        <div className={styles.OptionRow}>
          <div>
            Wybierz kurs, aby uzyskać stały dostęp do materiałów zawartych w tym
            kursie.
          </div>
          <div className={styles.ButtonContainer}>
            <ButtonByAdam
              onClick={(): void => {
                getPaymentModalData({
                  isModalVisible: true,
                  product: {
                    productID,
                    userEmail: userContext.userDetails.email
                  }
                })
              }}
            >
              Kup kurs za <strong>{`${productPrice}`} PLN</strong>
            </ButtonByAdam>
          </div>
        </div>

        <div className={styles.OptionRow}>
          <div>
            Zasubskrybuj aby zyskać pełny dostęp do wszystkich materiałów w
            naszym serwisie.
          </div>
          <div className={styles.ButtonContainer}>
            <ButtonByAdam
              btnType='premium'
              onClick={() => navigate('/subskrypcje')}
            >
              Kup Subskrypcję
            </ButtonByAdam>
          </div>
        </div>
      </div>
    </section>
  )
}
