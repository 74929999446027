import styles from './GroceryProducts.module.scss'
import { MemoizedSingleGroceryProductRow } from './SingleGroceryProductRow/SingleGroceryRandomProductRow'
import { SectionWithTitle } from '../SectionWithTItle/SectionWithTItle'
import {
  GroceryProductInterface,
  UpdateGroceryConfig
} from '../CakePriceCalculatorContext/Reducer/interfaces'
import { GROCERY_PRODUCTS_DESCRIPTION } from '../../../../const/calculators/cakePricing'

interface Props {
  products: GroceryProductInterface[]
  fullPrice: number
  onChangeHandler: (data: UpdateGroceryConfig) => void
  addProductHandler: () => void
  removeRowHandler: (id: string) => void
}

export const GroceryProducts = ({
  products,
  addProductHandler,
  fullPrice,
  onChangeHandler,
  removeRowHandler
}: Props): JSX.Element => (
  <SectionWithTitle
    fullPrice={fullPrice}
    sectionName='Produkty spożywcze:'
    description={GROCERY_PRODUCTS_DESCRIPTION}
  >
    <div className={styles.AddProductContainer}>
      <span role='presentation' onClick={addProductHandler}>
        Dodaj produkt
      </span>
    </div>
    {products.map(
      (product, index): JSX.Element => (
        <MemoizedSingleGroceryProductRow
          key={product.id}
          product={product}
          index={index}
          onChangeHandler={onChangeHandler}
          removeRowHandler={removeRowHandler}
        />
      )
    )}
  </SectionWithTitle>
)
