import { Dropdown } from 'semantic-ui-react'
import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem'

interface Props {
  options: DropdownItemProps[]
  placeholder: string
  defaultValue: string[]
  onBlurHandler: (
    value: boolean | number | string | (boolean | number | string)[]
  ) => void
}

export const DropdownExampleMultipleSearchSelection = ({
  options,
  placeholder,
  defaultValue,
  onBlurHandler
}: Props): JSX.Element => (
  <Dropdown
    defaultValue={defaultValue}
    placeholder={placeholder}
    fluid
    multiple
    search
    selection
    options={options}
    onChange={(a, b): void => {
      if (b && b.value) {
        onBlurHandler(b.value)
      }
    }}
  />
)
