import { Message } from 'semantic-ui-react'

interface Props {
  title: string
  errorList: string[]
}

export const ErrorListMessage = ({ title, errorList }: Props): JSX.Element => (
  <Message error header={title} list={errorList} />
)
