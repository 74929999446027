export interface SidebarProducts {
  name: string
  id: string
  path: string
  productCode: string
}

export const sidebarProducts: SidebarProducts[] = [
  {
    name: 'Twoje kursy',
    id: 'sidebar-products-bought',
    path: '/products/bought',
    productCode: 'bought'
  },
  {
    name: 'Kursy',
    id: 'sidebar-products-courses',
    path: '/products/courses',
    productCode: 'course'
  },
  {
    name: 'Nagrania na żywo',
    id: 'sidebar-products-lives',
    path: '/products/lives',
    productCode: 'live'
  }
]
