import {
  LessonDotListOptionDto,
  LessonDto,
  LessonTextFieldDto,
  LessonDotListFieldDto,
  LessonImageFieldDto
} from '../../service/Product/lessonTypesDto'
import {
  Lesson,
  LessonDotListField,
  LessonDotListOption,
  LessonImageField,
  LessonTextField
} from '../../service/Product/lessonTypes'
import { addUIDProp } from '../utils'

export const DotListFieldDtoToFrontAdapter = (
  sectionField: LessonDotListField
): LessonDotListField => {
  const items = sectionField.list.listItems.map(
    (item: LessonDotListOptionDto): LessonDotListOption => addUIDProp(item)
  )
  return {
    ...addUIDProp(sectionField),
    list: {
      ...sectionField.list,
      listItems: items
    }
  }
}

export const lessonsDtoToFrontAdapter = (lessons: LessonDto[]): Lesson[] =>
  lessons.map(
    (lesson: LessonDto): Lesson => ({
      ...addUIDProp(lesson),
      sectionFields: lesson.sectionFields.map(
        (
          sectionField:
            | LessonTextFieldDto
            | LessonDotListFieldDto
            | LessonImageFieldDto
        ): LessonTextField | LessonDotListField | LessonImageField => {
          if (sectionField.type === 'dotList') {
            return DotListFieldDtoToFrontAdapter(
              sectionField as LessonDotListField
            )
          }
          return addUIDProp(sectionField)
        }
      )
    })
  )
