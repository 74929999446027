import { Dispatch } from 'react'
import { User } from '../../service/User/UserService'
import {
  UserDataReducerAction,
  UserOperations,
  UserReducerActionNames
} from './UserReducer'
import { Product, SubscriptionType } from '../../service/Product/ProductService'

export const updateUser = (
  data: User,
  dispatch: Dispatch<UserDataReducerAction>
): void =>
  dispatch({
    type: UserReducerActionNames.UPDATE_USER_DATA,
    data
  })
export const updateUserValue = (
  data: UserOperations,
  dispatch: Dispatch<UserDataReducerAction>
): void =>
  dispatch({
    type: UserReducerActionNames.UPDATE_USER_FIELD,
    data
  })

export const checkIfAvailableToWatch = (
  product: Product,
  userData: User
): boolean => {
  if (userData.role === 'admin') return true
  if (userData.boughtProducts && userData.boughtProducts.includes(product.id))
    return true
  if (userData.subscriptionType === SubscriptionType.PREMIUM) return true
  if (product.subscription === SubscriptionType.FREE) return true
  if (userData.subscriptionType === SubscriptionType.FREE) return false
  return userData.subscriptionType === product.subscription
}
