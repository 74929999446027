import React from 'react'
import { Pagination } from 'semantic-ui-react'
import { PaginationProps } from 'semantic-ui-react/dist/commonjs/addons/Pagination/Pagination'

interface Props {
  onChangeHandler: (defaultActivePage: number) => void
  totalPages: number
  activePage: number
}

export const PaginationCompact = ({
  onChangeHandler,
  totalPages,
  activePage
}: Props): JSX.Element => (
  <Pagination
    activePage={activePage}
    boundaryRange={0}
    ellipsisItem={null}
    firstItem={null}
    lastItem={null}
    siblingRange={1}
    totalPages={totalPages}
    onPageChange={(
      event: React.MouseEvent<HTMLAnchorElement>,
      data: PaginationProps
    ): void => {
      onChangeHandler(data.activePage as number)
    }}
  />
)
