import {
  LessonDotListField,
  LessonImageField,
  LessonTextField,
  VimeoVideo
} from '../../../service/Product/lessonTypes'

export function getLessonErrors(
  sectionName: string,
  video: VimeoVideo,
  sectionFields: (LessonTextField | LessonDotListField | LessonImageField)[]
): string[] {
  const errors: string[] = []

  if (sectionName === '') {
    errors.push('Wybierz nazwę lekcji')
  }

  if (video.movieId === '') {
    errors.push('Podaj ID filmu')
  }

  // if (video.placeholderImage === '') {
  //   errors.push('Wybierz obrazek w tle do lekcji');
  // }

  if (sectionFields.length <= 2) {
    errors.push('Za mało kontentu dla tej lekcji, dodaj jakieś pole')
  }

  return errors
}
