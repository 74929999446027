import {useState} from 'react'
import {Link} from 'react-router-dom'
import styles from './SubscriptionSection.module.scss'
import {HeaderWithIcon} from '../../UI/headers/CustomHeader/CustomHeader'
import {ExpandedStatus} from '../../UI/ExpandedStatus/ExpandedStatus'
import {SubscriptionData, User} from '../../../service/User/UserService'
import {ButtonByAdam} from '../../UI/buttons/ButtonByAdam/ButtonByAdam'
import {SectionSeparator} from '../../UI/sectionSeparator/sectionSeparator'
import {SingleSubscriptionPanel} from './SingleSubscriptionPanel/SingleSubscriptionPanel'
import {SingleChargePanel} from './SingleChargePanel/SingleChargePanel'
import {translateSubscriptionType} from '../../../helpers/utils'
import {SUBSCRIPTION_TYPES} from "../../../const/consts";
import {SubscriptionType} from "../../../service/Product/ProductService";

interface Props {
    user: User
}

export const SubscriptionSection = ({user}: Props): JSX.Element => {
    const [showSettings, setShowSettings] = useState<boolean>(false)

    const renderUserSubscriptions = () => {
        if (user?.subscriptionData?.length) {
            return user.subscriptionData
                .filter((subData) => user.subscriptionType === SubscriptionType.PREMIUM ? subData.subType !== SUBSCRIPTION_TYPES.BASIC : true).map(
                    (subscriptionData: SubscriptionData): JSX.Element => (
                        <SingleSubscriptionPanel
                            key={subscriptionData.subscriptionId}
                            user={user}
                            subscriptionData={subscriptionData}
                        />
                    )
                )
        }
        return null
    }

    const renderUserCharges = () => {
        if (user?.subscriptionCharges?.length) {
            return user.subscriptionCharges.map((charge, index) => (
                <SingleChargePanel
                    index={index}
                    key={charge.created}
                    created={charge.created}
                    duration={charge.duration}
                    subscriptionType={charge.subscriptionType}
                />
            ))
        }
        return null
    }

    return (
        <section className={styles.SectionContainer}>
            <div
                role='presentation'
                className={styles.MainRow}
                onClick={(): void => setShowSettings(!showSettings)}
            >
                <HeaderWithIcon headerType='typical'>
                    Ustawienia subskrypcji
                </HeaderWithIcon>
                <ExpandedStatus isExpanded={showSettings}/>
            </div>
            {showSettings && (
                <>
                    <p className={styles.SingleRow}>
                        <span>Aktualny poziom konta:</span>
                        <span
                            className={`
                  ${styles.MainSubscriptionType}
                  ${user.subscriptionType === 'free' ? 'free-color' : ''}
                  ${user.subscriptionType === 'basic' ? 'basic-color' : ''}
                  ${user.subscriptionType === 'premium' ? 'premium-color' : ''}
                  `}
                        >
              {` ${translateSubscriptionType(user.subscriptionType) || 'Brak'}`}
            </span>
                    </p>

                    {user.subscriptionType === 'basic' && (
                        <div className={styles.AdditionalBtn}>
                            <Link id='subscription-update-btn' to='/subskrypcje/'>
                                <ButtonByAdam btnType='premium'>Bądź Premium!</ButtonByAdam>
                            </Link>
                        </div>
                    )}
                    <SectionSeparator/>
                    <div className={styles.ActivePackagesTitleContainer}>
                        <HeaderWithIcon headerType='typical'>
                            Lista aktywnych pakietów i subskrypcji:
                        </HeaderWithIcon>
                    </div>
                    {renderUserSubscriptions()}
                    {renderUserCharges()}
                </>
            )}
        </section>
    )
}
