import { checkIfStringHasWhitespaces } from '../../../helpers/validators'

interface Props {
  username: string
  password: string
}

export function getAuthenticateErrors({ username, password }: Props): string[] {
  const errors = []

  if (username === '') {
    errors.push('Uzupełnij nazwę użytkownika')
  }

  if (checkIfStringHasWhitespaces(username)) {
    errors.push('Spacje w nazwie użytkownika są niedozwolone')
  }

  if (password === '') {
    errors.push('Uzupełnij hasło')
  }

  return errors
}
