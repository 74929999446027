import { Label, SemanticSIZES } from 'semantic-ui-react'
import './LabelTag.scss'
import { SubscriptionTypes } from '../../../../service/Product/ProductService'

interface Props {
  text: string
  pillType?: SubscriptionTypes
  size: SemanticSIZES
}

export const LabelTag = ({ text, pillType, size }: Props): JSX.Element => (
  <div
    className={`
      ${pillType === 'premium' && 'PremiumType'}
      ${pillType === 'basic' && 'BasicType'}
      ${pillType === 'free' && 'FreeType'}`}
  >
    <Label tag size={size}>
      {text}
    </Label>
  </div>
)
