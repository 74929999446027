import {
  FixedCostRow,
  UpdateFixedCostsConfig
} from '../../../CakePriceCalculatorContext/Reducer/interfaces'
import styles from './ConfigView.module.scss'
import productRowStyles from '../FixedCostsSectionRow.module.scss'
import { InputTextWithValidator } from '../../../../../UI/inputs/InputTextWithValidator/InputTextWithValidator'
import { CalculatorProductNameValidator } from '../../../validators'
import { InputNumber } from '../../../../../UI/inputs/InputNumber/InputNumber'
import { CalculatorPriceInputNumberValidator } from '../../../utils'

interface Props {
  cost: FixedCostRow
  costIndex: number
  onCostHandler: (data: UpdateFixedCostsConfig) => void
  removeCost: (id: string) => void
  changeView: () => void
}

export const ConfigView = ({
  cost,
  costIndex,
  onCostHandler,
  removeCost,
  changeView
}: Props): JSX.Element => (
  <div className={styles.StandardViewContainer}>
    <p className={productRowStyles.IndexConfig}>{`${costIndex + 1}.`}</p>

    <div className={styles.InputContainer}>
      <p className={styles.ProductName}>Nazwa</p>

      <InputTextWithValidator
        type='text'
        containerStyles={styles.SingleInput}
        // className={productRowStyles.Input}
        value={cost.name}
        onChangeHandler={(value: string): void =>
          onCostHandler({
            id: cost.id,
            inputName: 'name',
            value
          })
        }
        validateFnc={CalculatorProductNameValidator}
      />
    </div>
    <div className={styles.InputContainer}>
      <p className={styles.ProductName}>Cena</p>
      <InputNumber
        type='number'
        value={cost.price}
        placeholder='Cena...'
        containerStyles={styles.SingleInput}
        onChangeHandler={(value: number): void =>
          onCostHandler({
            id: cost.id,
            inputName: 'price',
            value
          })
        }
        validateFnc={CalculatorPriceInputNumberValidator}
        label='szt'
      />
    </div>

    <p
      className={`${productRowStyles.Price} ${productRowStyles.MarginBottom20}`}
    >{`${cost.price} pln`}</p>
    <div
      role='presentation'
      onClick={changeView}
      className={`${productRowStyles.EditText} ${productRowStyles.MarginBottom20}`}
    >
      zapisz zmiany
    </div>
    <p
      role='presentation'
      onClick={(): void => removeCost(cost.id)}
      className={`${productRowStyles.RemoveText} ${productRowStyles.MarginBottom20}`}
    >
      usuń
    </p>
  </div>
)
