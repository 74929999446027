import React from 'react'
import styles from './FilterTypeBtn.module.scss'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isActive?: boolean
  btnType: 'premium' | 'basic' | 'free'
}

export const FilterTypeBtn = ({
  isActive,
  btnType,
  ...props
}: Props): JSX.Element => (
  // eslint-disable-next-line react/button-has-type
  <button
    {...props}
    className={`${styles.Button} ${
      isActive ? styles[`${btnType}Active`] : ''
    } ${styles[btnType] || ''}`}
    onClick={props.onClick}
  >
    {props.children}
  </button>
)
