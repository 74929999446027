import { useEffect, useMemo, useReducer } from 'react'
import { AdvancedImage, placeholder, responsive } from '@cloudinary/react'
import styles from '../../SharedCalculatorCss.module.scss'
import { InputTextLabeled } from '../../../UI/inputs/InputTextLabeled/InputTextLabeled'
import { ButtonByAdam } from '../../../UI/buttons/ButtonByAdam/ButtonByAdam'
import { HeaderWithIcon } from '../../../UI/headers/CustomHeader/CustomHeader'
import { ErrorListMessage } from '../../../UI/MessageComponents/ErrorListMessage/ErrorListMessage'
import { SuccessMessage } from '../../../UI/MessageComponents/SuccessMessage/SuccessMessage'
import {
  initialRectanglePortionsReducer,
  rectangleCakeReducer,
  RectanglePortionsReducerActionName
} from './RectanglePortionsReducer'
import { CalculatorOperations } from '../../../../helpers/cake'
import { useCloudinary } from '../../../../hooks/useCloudinary'

interface Props {
  countAutomatic: boolean
}

export const RectangleCake = ({ countAutomatic }: Props): JSX.Element => {
  const [calculator, dispatch] = useReducer(
    rectangleCakeReducer,
    initialRectanglePortionsReducer
  )

  const { getImageInstance } = useCloudinary()

  const rectangleCakeImage = useMemo(() => {
    const imageInstance = getImageInstance(
      'szkolamajcake/kalkulatory/tort_prostokat_wzor'
    )

    return (
      <AdvancedImage
        cldImg={imageInstance}
        plugins={[responsive(), placeholder()]}
      />
    )
  }, [])

  const calculate = (): void =>
    dispatch({ type: RectanglePortionsReducerActionName.CALCULATE_RESULT })
  const updateCalculatorData = (data: CalculatorOperations): void =>
    dispatch({
      type: RectanglePortionsReducerActionName.UPDATE_RECTANGLE_PORTIONS_CALC_DATA,
      data
    })

  useEffect((): void => {
    if (countAutomatic) {
      calculate()
    }
  }, [calculator.a, calculator.height, calculator.b])

  return (
    <section className={styles.CalculatorContainer}>
      <div className={styles.Content_Inputs}>
        <HeaderWithIcon headerType='typical'>Torty Prostokątne</HeaderWithIcon>

        <InputTextLabeled
          type='number'
          titleColor='pink'
          title='Podaj długość tortu'
          dataRequired
          value={calculator.a}
          placeholder='Podaj długość tortu'
          onChangeHandler={(val): void =>
            updateCalculatorData({ fieldName: 'a', fieldValue: val })
          }
        />

        <InputTextLabeled
          type='number'
          titleColor='green'
          title='Podaj szerokość tortu'
          dataRequired
          value={calculator.b}
          placeholder='Podaj szerokość tortu'
          onChangeHandler={(val): void =>
            updateCalculatorData({ fieldName: 'b', fieldValue: val })
          }
        />

        <InputTextLabeled
          type='number'
          titleColor='blue'
          title='Podaj wysokość tortu'
          dataRequired
          value={calculator.height}
          placeholder='Podaj wysokość tortu'
          onChangeHandler={(val): void =>
            updateCalculatorData({ fieldName: 'height', fieldValue: val })
          }
        />

        {!countAutomatic && (
          <ButtonByAdam btnType='premium' onClick={(): void => calculate()}>
            Oblicz
          </ButtonByAdam>
        )}

        <div className={styles.ResultContainer}>
          {calculator.errors.length > 0 && (
            <ErrorListMessage
              title='Błędne wartości w kalkulatorze'
              errorList={calculator.errors}
            />
          )}
          {calculator.result && (
            <SuccessMessage>
              {'Ten tort przewidujemy na: od '}
              <strong>{calculator.result.MIN}</strong>
              {' do '}
              <strong>{calculator.result.MAX}</strong>
              {' porcji.'}
            </SuccessMessage>
          )}
        </div>
      </div>

      <div className={styles.Content_Image}>{rectangleCakeImage}</div>
    </section>
  )
}
