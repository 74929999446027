import { useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import styles from './Navbar.module.scss'
import { Burger } from './Burger/Burger'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { UserDetails } from '../sidebar/UserDetails/UserDetails'
import { ProductsSection } from '../sidebar/ProductsSection/ProductsSection'
import { Overlay } from '../UI/Overlay/Overlay'
import { Logout } from '../sidebar/Logout/Logout'
import { CalculatorSection } from '../sidebar/CalculatorSection/CalculatorSection'

export const Navbar = (): JSX.Element => {
  const [open, setOpen] = useState(false)
  const node = useRef<HTMLInputElement>(null)
  const menuId = 'main-menu'

  useOnClickOutside(node, () => setOpen(false))

  return (
    <>
      {open && <Overlay type='dark' linearAnimation={open} zIndexCustom={5} />}
      <section ref={node} className={styles.NavBarContainer}>
        <div className={styles.NavBarWrapper}>
          <Link id='app-name-header-link' to='/'>
            <header className={styles.LogoContainer}>
              <h1>Słodka Szkoła</h1>
            </header>
          </Link>
          <div>
            <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
          </div>
        </div>
        <nav
          role='presentation'
          className={`${styles.NavigationContent} ${
            open && styles.NavigationOpen
          }`}
          onClick={(): void => setOpen(!open)}
        >
          <UserDetails />
          <ProductsSection />
          <CalculatorSection />
          <Logout />
        </nav>
      </section>
    </>
  )
}
