import { InputHTMLAttributes, useEffect, useState } from 'react'
import { Input } from 'semantic-ui-react'
import styles from '../SharedInputStyles.module.scss'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  dataRequired?: boolean
  value: string
  onChangeHandler?: (value: string) => void
  onBlurHandler?: (inputValue: string) => void
  autoComplete?: string
  name?: string
  id?: string
}

export const InputText = ({
  onChangeHandler,
  onBlurHandler,
  value,
  label,
  dataRequired,
  ...props
}: Props): JSX.Element => {
  const [inputValue, setInputValue] = useState<string>(value || '')

  useEffect((): void => {
    if (value !== inputValue) {
      setInputValue(value)
    }
  }, [value])

  return (
    <div className={styles.InputContainer}>
      <span
        className={styles.Label}
        style={{ visibility: label ? 'visible' : 'hidden' }}
      >
        {label}
        {dataRequired && <span style={{ color: 'red' }}>*</span>}
      </span>
      <Input
        value={inputValue}
        placeholder={props.placeholder}
        onChange={(e): void => {
          setInputValue(e.target.value)
          if (onChangeHandler) {
            onChangeHandler(e.target.value)
          }
        }}
        onBlur={(): void => {
          if (onBlurHandler) {
            onBlurHandler(inputValue)
          }
        }}
        type={props.type}
        autoComplete={props.autoComplete}
        name={props.name}
        id={props.id}
      />
    </div>
  )
}
