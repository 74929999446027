import { SectionWithTitle } from '../SectionWithTItle/SectionWithTItle'
import { DeliveryTimeRow } from './DeliveryTimeRow/DeliveryTimeRow'
import {
  DeliveryTimeInterface,
  UpdateDeliveryTime
} from '../CakePriceCalculatorContext/Reducer/interfaces'
import { DELIVERY_PRICE_DESCRIPTION } from '../../../../const/calculators/cakePricing'

interface Props {
  data: DeliveryTimeInterface
  fullPrice: number
  onChangeHandler: (data: UpdateDeliveryTime) => void
}

export const DeliveryTime = ({
  data,
  fullPrice,
  onChangeHandler
}: Props): JSX.Element => (
  <SectionWithTitle
    fullPrice={fullPrice}
    sectionName='Cena dostawy:'
    description={DELIVERY_PRICE_DESCRIPTION}
  >
    <DeliveryTimeRow
      data={data}
      onChangeHandler={onChangeHandler}
      fullPrice={fullPrice}
    />
  </SectionWithTitle>
)
