import { TokenData } from '../service/interface'

export const getQueryParamsFromLocationSearch = (
  search: string
): { [key: string]: string } =>
  search
    .slice(1)
    .split('&')
    .reduce((acc, s) => {
      const [k, v] = s.split('=')
      return Object.assign(acc, { [k]: v })
    }, {})

export function parseJwt(token: string): TokenData {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  )
  return JSON.parse(jsonPayload)
}
