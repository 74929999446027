import {
  CalculatorOperations,
  getIngredientsByFrosting,
  frostingRectangleCakeAmount
} from '../../../../helpers/cake'
import { rectangleCakeValidator } from './RectangleCakeValidator'

interface RectanglePortionsCalculatorActions {
  height: string
  a: string
  b: string
  errors: string[]
  chosenIcing: string
  ingredients: string[]
  result: number | null
  calculate: () => void
}

export const initialRectanglePortionsReducer: RectanglePortionsCalculatorActions =
  {
    height: '',
    a: '',
    b: '',
    errors: [],
    chosenIcing: '',
    ingredients: [],
    result: null,
    calculate: (): void => {
      // Initial value
    }
  }

const updateValidationErrors = (
  errors: string[],
  state: RectanglePortionsCalculatorActions
): RectanglePortionsCalculatorActions => ({
  ...state,
  errors,
  ingredients: [],
  result: null
})

const calculateResult = (
  state: RectanglePortionsCalculatorActions
): RectanglePortionsCalculatorActions => {
  const result = Math.round(
    frostingRectangleCakeAmount(
      parseFloat(state.height),
      parseFloat(state.a),
      parseFloat(state.b)
    )
  )
  return {
    ...state,
    result,
    ingredients: getIngredientsByFrosting(state.chosenIcing, result),
    errors: []
  }
}

export enum RectanglePortionsActionNames {
  CALCULATE_RESULT = 'CALCULATE_RESULT',
  UPDATE_RECTANGLE_PORTIONS_CALC_DATA = 'UPDATE_RECTANGLE_PORTIONS_CALC_DATA'
}

export type RectanglePortionsReducerActions =
  | {
      type: RectanglePortionsActionNames.CALCULATE_RESULT
    }
  | {
      type: RectanglePortionsActionNames.UPDATE_RECTANGLE_PORTIONS_CALC_DATA
      data: CalculatorOperations
    }

const calculate = (
  state: RectanglePortionsCalculatorActions
): RectanglePortionsCalculatorActions => {
  const validationErrors = rectangleCakeValidator(
    state.height,
    state.a,
    state.b
  )
  if (validationErrors.length > 0) {
    return updateValidationErrors(validationErrors, state)
  }
  return calculateResult(state)
}

const updateCalcData = (
  data: CalculatorOperations,
  state: RectanglePortionsCalculatorActions
): RectanglePortionsCalculatorActions => ({
  ...state,
  [data.fieldName]: data.fieldValue
})

export const rectangleCakeReducer = (
  state: RectanglePortionsCalculatorActions,
  action: RectanglePortionsReducerActions
): RectanglePortionsCalculatorActions => {
  switch (action.type) {
    case RectanglePortionsActionNames.CALCULATE_RESULT:
      return calculate(state)
    case RectanglePortionsActionNames.UPDATE_RECTANGLE_PORTIONS_CALC_DATA:
      return updateCalcData(action.data, state)
    default:
      return state
  }
}
