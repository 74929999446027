import { useContext, useEffect, useState } from 'react'
import styles from './RestorePassword.module.scss'
import { InputText } from '../../../components/UI/inputs/InputText/InputText'
import { DefaultHeader } from '../../../components/UI/headers/DefaultHeader/DefaultHeader'
import { ButtonByAdam } from '../../../components/UI/buttons/ButtonByAdam/ButtonByAdam'
import { GlobalNotificationContext } from '../../../Context/GlobalNotification/GlobalNotificationProvider'
import { GlobalLoaderContext } from '../../../Context/GlobalLoader/GlobalLoader'
import { setTimeoutHandler } from '../../../helpers/utils'
import { NotificationType } from '../../../Context/GlobalNotification/Types'
import { restorePassword } from '../../../service/User/api'
import { LoginPageType, LoginPageTypes } from '../LoginPageTypes'

interface Props {
  changeFormHandler: (formType: LoginPageTypes) => void
}

export const RestorePassword = ({ changeFormHandler }: Props): JSX.Element => {
  const [email, setEmail] = useState<string>('')

  useEffect(() => {
    const onClickEnterHandler = (event: KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        document.getElementById('remindPasswordButton')?.click()
      }
    }
    document.addEventListener('keydown', onClickEnterHandler)
    return () => {
      document.removeEventListener('keydown', onClickEnterHandler)
    }
  }, [])

  const showLoader = useContext(GlobalLoaderContext)
  const showNotification = useContext(GlobalNotificationContext)

  const restorePasswordHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    showLoader(true)
    restorePassword(email)
      .then((): void => {
        showNotification(
          NotificationType.INFO,
          'Mail został poprawnie wysłany. Sprawdź zakładkę SPAM'
        )
        setTimeout((): void => {
          showLoader(false)
          setTimeoutHandler(
            (): void => changeFormHandler(LoginPageType.LOGIN),
            2000
          )
        }, 2000)
      })
      .catch((): void => showLoader(false))
  }

  return (
    <section className={styles.FormContainer}>
      <DefaultHeader>Odzyskiwanie hasła</DefaultHeader>

      <form>
        <InputText
          label='Email Użytkownika'
          placeholder='Wpisz email użytkownika'
          onChangeHandler={(val: string): void => setEmail(val)}
          value={email}
          autoComplete='email'
          id='restore-password'
          name='restore-password'
        />

        <ButtonByAdam
          id='remindPasswordButton'
          className={styles.Test}
          type='submit'
          onClick={restorePasswordHandler}
        >
          Przypomnij mi hasło
        </ButtonByAdam>
      </form>

      <div className={styles.AdditionalInfoContainer}>
        <p>
          <span
            aria-hidden='true'
            tabIndex={0}
            role='button'
            onClick={(): void => changeFormHandler(LoginPageType.LOGIN)}
            className={styles.Link}
          >
            Kliknij
          </span>
          , żeby wrócić
        </p>
      </div>
    </section>
  )
}
