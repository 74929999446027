import { isStringNumeric } from '../../../../helpers/validators'
import { commasToDots } from '../../../../helpers/adapters/custom'

export const squareSpongeCakeValidator = (sideLength: string): string[] => {
  const errors: string[] = []
  const L = commasToDots(sideLength)

  if (!isStringNumeric(L)) {
    errors.push(
      'Wartość w polu "Długość boku formy" nie jest wartością liczbową'
    )
  }

  if (typeof parseFloat(L) === 'number' && parseFloat(L) <= 0) {
    errors.push('Wartość w polu "Długość boku formy" musi być większa od 0')
  }

  return errors
}
