import { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import styles from './UserDetails.module.scss'
import sidebarStyles from '../../../hoc/sidebar/Sidebar.module.css'
import { UserReducerActions } from '../../../Context/User/UserReducer'
import { UserContext } from '../../../Context/User/UserContext'
import { SidebarSection } from '../../../hoc/sidebarSection/SidebarSection'
import { SidebarItems } from '../../../hoc/SidebarItems/SidebarItems'
import { HeaderWithIcon } from '../../UI/headers/CustomHeader/CustomHeader'
import { FaUserIcon, FaTagsIcon } from '../../../Assets/ReactIcons'
import { getTextByCode, subscriptionTypes } from '../../../helpers/dictionaries'

export const UserDetails = (): JSX.Element => {
  const userContext: UserReducerActions = useContext(UserContext)
  const subscriptionClassname =
    userContext.userDetails.subscriptionType === 'free'
      ? sidebarStyles.SubscriptionAlert
      : sidebarStyles.Subscription
  return (
    <SidebarSection>
      <HeaderWithIcon reactIcon={FaUserIcon} className='nav-menu-header-item'>
        {`${userContext.userDetails.name} ${userContext.userDetails.surname}`}
      </HeaderWithIcon>

      <SidebarItems>
        <div>
          <NavLink
            id='user-settings-link'
            to='/user/settings'
            className={({ isActive }) =>
              `nav-menu-item  ${isActive ? 'nav-menu-item_active' : ''}`
            }
          >
            <span>Ustawienia konta i płatności</span>
          </NavLink>
        </div>
        {userContext.userDetails.subscriptionType !== 'premium' && (
          <div>
            <NavLink
              id='user-subscriptions-link'
              to='/subskrypcje'
              className={({ isActive }) =>
                `${subscriptionClassname}  ${
                  isActive ? sidebarStyles.SubscriptionAlert_Active : ''
                }`
              }
            >
              <HeaderWithIcon reactIcon={FaTagsIcon}>
                <span>
                  {userContext.userDetails.subscriptionType === 'free'
                    ? 'Wybierz subskrypcję'
                    : 'Subskrypcje'}
                </span>
              </HeaderWithIcon>
            </NavLink>
          </div>
        )}
        <div className={styles.SubscriptionInfo}>
          <p
            className={`
              nav-no-link-menu-item
              ${
                userContext.userDetails.subscriptionType === 'free'
                  ? 'free-background-color'
                  : ''
              }
              ${
                userContext.userDetails.subscriptionType === 'basic'
                  ? 'basic-background-color'
                  : ''
              }
              ${
                userContext.userDetails.subscriptionType === 'premium'
                  ? 'premium-background-color'
                  : ''
              }
              `}
          >
            {'Poziom konta: '}
            {getTextByCode(
              userContext.userDetails.subscriptionType,
              subscriptionTypes
            )}
          </p>
        </div>
      </SidebarItems>
    </SidebarSection>
  )
}
