import { useContext, useEffect, useState } from 'react'
import styles from './Signup.module.scss'
import { InputText } from '../../../components/UI/inputs/InputText/InputText'
import { DefaultHeader } from '../../../components/UI/headers/DefaultHeader/DefaultHeader'
import { ButtonByAdam } from '../../../components/UI/buttons/ButtonByAdam/ButtonByAdam'
import { GlobalNotificationContext } from '../../../Context/GlobalNotification/GlobalNotificationProvider'
import { ErrorListMessage } from '../../../components/UI/MessageComponents/ErrorListMessage/ErrorListMessage'
import { getSignupErrors } from './signup_validator'
import { GlobalLoaderContext } from '../../../Context/GlobalLoader/GlobalLoader'
import { NotificationType } from '../../../Context/GlobalNotification/Types'
import { USER_CREATED_SUCCESSFULLY } from '../../../const/consts'
import { userSignup } from '../../../service/User/api'
import { LoginPageType, LoginPageTypes } from '../LoginPageTypes'

interface Props {
  changeFormHandler: (formType: LoginPageTypes) => void
}

export const Signup = ({ changeFormHandler }: Props): JSX.Element => {
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordRepeated, setPasswordRepeated] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [surname, setSurname] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [triedToSignUp, setTriedToSignup] = useState<boolean>(false)

  useEffect(() => {
    const onClickEnterHandler = (event: KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        document.getElementById('signUpButton')?.click()
      }
    }
    document.addEventListener('keydown', onClickEnterHandler)
    return () => {
      document.removeEventListener('keydown', onClickEnterHandler)
    }
  }, [])

  const showNotification = useContext(GlobalNotificationContext)
  const showLoader = useContext(GlobalLoaderContext)

  const errors: string[] = getSignupErrors({
    username,
    password,
    passwordRepeated,
    name,
    surname,
    email
  })

  const formDisabled = triedToSignUp && errors.length > 0

  const signUpHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (!triedToSignUp) setTriedToSignup(true)
    if (errors.length === 0) {
      showLoader(true)
      userSignup(
        {
          username: username.toLocaleLowerCase(),
          password,
          name,
          surname,
          email: email.toLocaleLowerCase()
        },
        passwordRepeated
      )
        .then(response => {
          showLoader(false)
          if (response.status === 200 || response.status === 201) {
            setTimeout((): void => {
              showNotification(NotificationType.INFO, USER_CREATED_SUCCESSFULLY)
              changeFormHandler(LoginPageType.LOGIN)
            }, 1000)
          }
        })
        .finally(() => {
          showLoader(false)
        })
    }
  }

  return (
    <section className={styles.FormContainer}>
      <DefaultHeader>Rejestracja</DefaultHeader>
      <form>
        <InputText
          label='Nazwa użytkownika'
          placeholder='Login'
          onChangeHandler={(val: string): void => setUsername(val)}
          value={username}
          autoComplete='username'
          id='username'
          name='username'
        />
        <InputText
          type='password'
          label='Hasło'
          placeholder='Hasło'
          onChangeHandler={(val: string): void => setPassword(val)}
          value={password}
          autoComplete='new-password'
          id='new-password'
          name='new-password'
        />
        <InputText
          type='password'
          label='Powtórz Hasło'
          placeholder='Powtórz hasło'
          onChangeHandler={(val: string): void => setPasswordRepeated(val)}
          value={passwordRepeated}
          autoComplete='new-password'
          id='confirm-new-password'
          name='confirm-new-password'
        />
        <InputText
          label='Imię'
          placeholder='Imię'
          onChangeHandler={(val: string): void => setName(val)}
          value={name}
          autoComplete='given-name'
          id='given-name'
          name='given-name'
        />
        <InputText
          label='Nazwisko'
          placeholder='Nazwisko'
          onChangeHandler={(val: string): void => setSurname(val)}
          value={surname}
          autoComplete='family-name'
          id='family-name'
          name='family-name'
        />
        <InputText
          label='Email'
          placeholder='Email'
          onChangeHandler={(val: string): void => setEmail(val)}
          value={email}
          autoComplete='email'
          id='email'
          name='email'
        />

        {formDisabled && (
          <ErrorListMessage title='Popraw błędy' errorList={errors} />
        )}

        <ButtonByAdam
          id='signUpButton'
          type='submit'
          disabled={formDisabled}
          onClick={signUpHandler}
        >
          Zarejestruj się
        </ButtonByAdam>
      </form>

      <div className={styles.AdditionalInfoContainer}>
        <p>
          Jeżeli masz już konto,
          <span
            aria-hidden='true'
            tabIndex={0}
            role='button'
            onClick={(): void => changeFormHandler(LoginPageType.LOGIN)}
            className={styles.Link}
          >
            {' zaloguj się'}
          </span>
        </p>
      </div>
    </section>
  )
}
