import styles from './Calculators.module.scss'
import { CalculatorCard } from '../../components/UI/cards/CalculatorCard/CalculatorCard'
import { calculators } from '../../const/calculators/calculators'

export const Calculators = (): JSX.Element => (
  <section className={styles.CalculatorsContainer}>
    {calculators.map(
      (calculator): JSX.Element => (
        <CalculatorCard
          key={`calculator-${calculator.calculatorID}`}
          calculatorID={calculator.calculatorID}
          title={calculator.title}
          shortDescription={calculator.shortDescription}
          author={calculator.author}
          subscriptionType={calculator.subscriptionType}
          productViewPath={calculator.productViewPath}
          cardImagePath={calculator.cardImagePath}
        />
      )
    )}
  </section>
)
