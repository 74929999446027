import {useEffect, useState} from 'react'
import styles from './SubscriptionCard.module.scss'
import {ButtonByAdam, ButtonByAdamProps} from '../../UI/buttons/ButtonByAdam/ButtonByAdam'
import {SectionSeparator} from '../../UI/sectionSeparator/sectionSeparator'
import {FaCheckIcon} from '../../../Assets/ReactIcons'
import {PaymentType, Subscription} from '../../../Context/Dictionaries/api'
import {SubscriptionType, SubscriptionTypes} from '../../../service/Product/ProductService'
import {Ribbon} from './Ribbon/Ribbon'
import {PriceView} from './PriceView/PriceView'
import {TextSwitch} from "../../TextSwitch/TextSwitch";

interface Props {
  disabled?: boolean
  type: SubscriptionTypes
  subscriptions: Subscription[]
  goToPaymentPageHandler: (chosenSub: Subscription) => void
}

export const SubscriptionCard = ({
  subscriptions,
  type,
  goToPaymentPageHandler,
  disabled
}: Props): JSX.Element => {
  const [subscriptionToShow, setSubscriptionToShow] = useState<Subscription>(
    subscriptions[0]
  )
  const [chosenPaymentType, setChosenPaymentType] = useState<PaymentType>(
    subscriptions[0].paymentType
  )

  useEffect(() => {
    const getSubsByType =  subscriptions.filter((sub) => sub.paymentType === chosenPaymentType);
    if(getSubsByType.length) {
      setSubscriptionToShow(getSubsByType[0])
    }
  }, [subscriptions, chosenPaymentType, setSubscriptionToShow])

  const subscriptionOptions = subscriptions.filter(
    sub => sub.paymentType === PaymentType.SUBSCRIPTION
  )

  const onePaymentOptions = subscriptions.filter(
    sub => sub.paymentType === PaymentType.ONE_TIME_PAYMENT
  )

  const options = [{
    optionName: 'Subskrypcja',
    optionKey: PaymentType.SUBSCRIPTION,
  },
  {
    optionName: 'Płatność jednorazowa',
    optionKey: PaymentType.ONE_TIME_PAYMENT,
  }]

  // const options = [
  //   {
  //     label: 'Subskrypcja',
  //     value: PaymentType.SUBSCRIPTION,
  //     selectedBackgroundColor: '#0099ff'
  //   },
  //   {
  //     label: 'Płatność jednorazowa',
  //     value: PaymentType.ONE_TIME_PAYMENT,
  //     selectedBackgroundColor: '#0099ff'
  //   }
  // ]

  const getProperButtonStyling = (
    isBestOffer: boolean | undefined,
    type: SubscriptionType
  ): ButtonByAdamProps['btnType'] => {
    if (isBestOffer) return 'red-transparent'
    if (type === SubscriptionType.PREMIUM) return 'premium'
    if (type === SubscriptionType.BASIC) return 'blue'

    return 'blue'
  }

  const renderAvailablePayments = (paymentType: PaymentType): JSX.Element => {
    if (paymentType === PaymentType.SUBSCRIPTION) {
      return (
        <div>
          {subscriptionOptions.map(offer => (
            <ButtonByAdam
              key={offer.id}
              isActive={offer.id === subscriptionToShow.id}
              disabled={disabled}
              className={`${styles.Button_Option}`}
              btnType={getProperButtonStyling(
                offer.bestOffer?.isBestOffer,
                type
              )}
              onClick={(): void => setSubscriptionToShow(offer)}
            >
              {`${offer.duration} dni`}
            </ButtonByAdam>
          ))}
        </div>
      )
    }
    if (paymentType === PaymentType.ONE_TIME_PAYMENT) {
      return (
        <div className={styles.PaymentTypeRow}>
          {onePaymentOptions.map(offer => (
            <ButtonByAdam
              key={offer.id}
              isActive={offer.id === subscriptionToShow.id}
              disabled={disabled}
              className={`${styles.Button_Option}`}
              btnType={getProperButtonStyling(
                offer.bestOffer?.isBestOffer,
                type
              )}
              onClick={(): void => setSubscriptionToShow(offer)}
            >
              {`${offer.duration} dni`}
            </ButtonByAdam>
          ))}
        </div>
      )
    }
    return <div>brak dostępnych opcji</div>
  }

  return (
    <section
      className={`
    ${styles.Container}
    ${type === SubscriptionType.PREMIUM && styles.Premium}
    `}
    >
      {subscriptionToShow.bestOffer?.isBestOffer && (
        <Ribbon text={subscriptionToShow.bestOffer.message} />
      )}
      <div className={styles.TitlesRow}>
        <h2>{subscriptionToShow.title}</h2>
        <p>{subscriptionToShow.subTitle}</p>
      </div>

      <PriceView data={subscriptionToShow} />

      <SectionSeparator marginTop={40} marginBottom={40} />

      <div className={styles.Switcher}>
        <TextSwitch options={options} onChangeHandler={value => setChosenPaymentType(value as PaymentType)} />
        {/* <Switch */}
        {/*    className={styles.SwitchNew} */}
        {/*    onChange={value => setChosenPaymentType(value ? PaymentType.SUBSCRIPTION : PaymentType.ONE_TIME_PAYMENT)} */}
        {/*    checked={chosenPaymentType === PaymentType.SUBSCRIPTION} */}
        {/*    uncheckedIcon={ */}
        {/*      <div */}
        {/*          // style={{ */}
        {/*          //   display: "flex", */}
        {/*          //   justifyContent: "center", */}
        {/*          //   alignItems: "center", */}
        {/*          //   height: "100%", */}
        {/*          //   fontSize: 15, */}
        {/*          //   color: "orange", */}
        {/*          //   paddingRight: 2 */}
        {/*          // }} */}
        {/*      > */}
        {/*        Temp 1 xD */}
        {/*      </div> */}
        {/*    } */}
        {/*    checkedIcon={ */}
        {/*      <svg viewBox="0 0 10 10" height="100%" width="100%" fill="yellow"> */}
        {/*        <circle r={3} cx={5} cy={5} /> */}
        {/*      </svg> */}
        {/*    } */}
        {/*    uncheckedHandleIcon={ */}
        {/*      <div */}
        {/*          style={{ */}
        {/*            display: "flex", */}
        {/*            justifyContent: "center", */}
        {/*            alignItems: "center", */}
        {/*            height: "100%", */}
        {/*            fontSize: 20 */}
        {/*          }} */}
        {/*      > */}
        {/*        ☹ */}
        {/*      </div> */}
        {/*    } */}
        {/*    checkedHandleIcon={ */}
        {/*      <div */}
        {/*          style={{ */}
        {/*            display: "flex", */}
        {/*            justifyContent: "center", */}
        {/*            alignItems: "center", */}
        {/*            height: "100%", */}
        {/*            color: "red", */}
        {/*            fontSize: 18 */}
        {/*          }} */}
        {/*      > */}
        {/*        ♥ */}
        {/*      </div> */}
        {/*    } */}
        {/* /> */}

        {/* <SwitchSelector */}
        {/*  name={type} */}
        {/*  onChange={value => setChosenPaymentType(value as PaymentType)} */}
        {/*  options={options} */}
        {/*  backgroundColor='#353b48' */}
        {/*  fontColor='#fffff' */}
        {/* /> */}
      </div>
      <div className={styles.SubOptionsWrapper}>
        {renderAvailablePayments(chosenPaymentType)}
      </div>
      <SectionSeparator marginTop={20} marginBottom={20} />
      <ButtonByAdam
          disabled={disabled}
          className={styles.Button}
          btnType={
            type === SubscriptionType.PREMIUM ? 'premium-invert' : 'blue-invert'
          }
          onClick={(): void => goToPaymentPageHandler(subscriptionToShow)}
      >
        Przejdź do płatności
      </ButtonByAdam>
      <SectionSeparator marginTop={20} marginBottom={20} />
      <div>
        {subscriptionToShow.benefits.map(
          (bullet: string, index: number): JSX.Element => (
            <div key={`${index + 1}`} className={styles.SinglePoint}>
              <div className={styles.Icon}>{FaCheckIcon}</div>
              <div>{bullet}</div>
            </div>
          )
        )}
      </div>
      <SectionSeparator marginTop={40} marginBottom={40} />
      <div className={styles.ButtonContainer}>
        <ButtonByAdam
          disabled={disabled}
          className={styles.Button}
          btnType={
            type === SubscriptionType.PREMIUM ? 'premium-invert' : 'blue-invert'
          }
          onClick={(): void => goToPaymentPageHandler(subscriptionToShow)}
        >
          Przejdź do płatności
        </ButtonByAdam>
      </div>
    </section>
  )
}
