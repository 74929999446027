import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './Subscriptions.module.scss'
import { premiumSubscription, SubOffer } from '../../const/subscriptionOffers'
import { UserReducerActions } from '../../Context/User/UserReducer'
import { UserContext } from '../../Context/User/UserContext'
import { initiateProperPaymentProcess } from '../../service/Stripe/checkout'
import { DictionaryContext } from '../../Context/Dictionaries/DictionariesContext'
import { SubscriptionType } from '../../service/Product/ProductService'
import { SubscriptionCard } from '../../components/Subscriptions/SubscriptionCard/SubscriptionCard'
import { Subscription } from '../../Context/Dictionaries/api'
import { ReactComponent as Wave } from '../../Assets/images/wave.svg'
import {sortByDuration} from "./utils";
import {PLEASE_WAIT} from "../../const/consts";
import {GlobalLoaderContext} from "../../Context/GlobalLoader/GlobalLoader";
import {GlobalNotificationContext} from "../../Context/GlobalNotification/GlobalNotificationProvider";
import {NotificationType} from "../../Context/GlobalNotification/Types";

export const Subscriptions = (): JSX.Element => {
  const userContext: UserReducerActions = useContext(UserContext)
  const navigate = useNavigate()
  const { dictionaries } = useContext(DictionaryContext)
  const allSubscriptions = dictionaries.subscriptions
  const showLoader = useContext(GlobalLoaderContext)
  const showNotification = useContext(GlobalNotificationContext)

  const basicSubscribed = userContext.userDetails.subscriptionType === 'basic'

  const premiumSubscriptionData: SubOffer = premiumSubscription


  useEffect(() => {
    if (userContext.userDetails.subscriptionType === SubscriptionType.PREMIUM) {
      navigate('/user/settings')
    }
  }, [userContext])

  if (basicSubscribed) {
    premiumSubscriptionData.button.text = 'Aktualizuj'
    premiumSubscriptionData.price = '69,90'
  }

  const premiumSubscriptions = allSubscriptions.filter(
    sub => sub.subType === SubscriptionType.PREMIUM
  ).sort(sortByDuration)
  const basicSubscriptions = allSubscriptions.filter(
    sub => sub.subType === SubscriptionType.BASIC
  ).sort(sortByDuration)


  const paymentHandler = async (chosenSub: Subscription) => {
    try {
      showLoader(true, `${PLEASE_WAIT}`);
      await initiateProperPaymentProcess(
          chosenSub,
          userContext.userDetails.email
      )
    } catch(e) {
      showNotification(
          NotificationType.ERROR,
          'Płatność się nie powiodła. Spróbuj jeszcze raz lub skontaktuj się z agministratorem.'
      )
    }
    showLoader(false);
  }

  const renderSubscriptionCards = () => {
    if (userContext.userDetails.subscriptionType === SubscriptionType.FREE) {
      return (
        <>
          {basicSubscriptions.length && (
            <SubscriptionCard
              type={SubscriptionType.BASIC}
              subscriptions={basicSubscriptions}
              goToPaymentPageHandler={paymentHandler}
            />
          )}

          {premiumSubscriptions.length && (
            <SubscriptionCard
              type={SubscriptionType.PREMIUM}
              subscriptions={premiumSubscriptions}
              goToPaymentPageHandler={paymentHandler}
            />
          )}
        </>
      )
    }

    if (userContext.userDetails.subscriptionType === SubscriptionType.BASIC) {
      return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {premiumSubscriptions.length && (
            <SubscriptionCard
              type={SubscriptionType.PREMIUM}
              subscriptions={premiumSubscriptions}
              goToPaymentPageHandler={paymentHandler}
            />
          )}
        </>
      )
    }

    if (userContext.userDetails.subscriptionType === SubscriptionType.PREMIUM) {
      const navigate = useNavigate()
      navigate('/user/settings')
    }

    return null
  }

  return (
    <section className={styles.SubPanelContainer}>
      <div className={styles.SubPanelContainer_Background}>
        <Wave />
      </div>
      {userContext.userDetails.subscriptionType !== SubscriptionType.PREMIUM && renderSubscriptionCards()}
    </section>
  )
}
