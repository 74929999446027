import { useContext } from 'react'
import styles from './HelloView.module.scss'
import { UserReducerActions } from '../../Context/User/UserReducer'
import { UserContext } from '../../Context/User/UserContext'
import { SectionSeparator } from '../../components/UI/sectionSeparator/sectionSeparator'
import { ProductDataActions } from '../../Context/Product/ProductDataReducer'
import { ProductDataContext } from '../../Context/Product/ProductDataContext'
import { LinksContainer } from '../../components/Homepage/LinksContainer/LinksContainer'
import { HowToStart } from '../../components/Homepage/HowToStart/HowToStart'
import { AboutCourse } from '../../components/Homepage/AboutCourse/AboutCourse'
import { AboutQuestions } from '../../components/Homepage/AboutQuestions/AboutQuestions'
import { HeadSection } from '../../components/Homepage/HeadSection/HeadSection'

export const HelloView = (): JSX.Element => {
  const userContext: UserReducerActions = useContext(UserContext)
  const productsContext: ProductDataActions = useContext(ProductDataContext)

  return (
    <section className={styles.MainContainer}>
      <div className={styles.ContentSection}>
        <div>
          <HeadSection userInfo={userContext} />

          <LinksContainer />

          <div className={styles.Container_Waves}>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 319'>
              <path
                fill='#ea9bad'
                fillOpacity='1'
                d='M0,128L15,149.3C30,171,60,213,90,240C120,267,150,277,180,256C210,235,240,181,270,176C300,171,330,213,360,218.7C390,224,420,192,450,165.3C480,139,510,117,540,117.3C570,117,600,139,630,170.7C660,203,690,245,720,261.3C750,277,780,267,810,250.7C840,235,870,213,900,181.3C930,149,960,107,990,122.7C1020,139,1050,213,1080,224C1110,235,1140,181,1170,144C1200,107,1230,85,1260,112C1290,139,1320,213,1350,213.3C1380,213,1410,139,1425,101.3L1440,64L1440,0L1425,0C1410,0,1380,0,1350,0C1320,0,1290,0,1260,0C1230,0,1200,0,1170,0C1140,0,1110,0,1080,0C1050,0,1020,0,990,0C960,0,930,0,900,0C870,0,840,0,810,0C780,0,750,0,720,0C690,0,660,0,630,0C600,0,570,0,540,0C510,0,480,0,450,0C420,0,390,0,360,0C330,0,300,0,270,0C240,0,210,0,180,0C150,0,120,0,90,0C60,0,30,0,15,0L0,0Z'
              />
            </svg>
          </div>
        </div>

        <HowToStart />

        <SectionSeparator
          width={50}
          height={3}
          marginTop={60}
          marginBottom={60}
        />

        <AboutCourse
          newestProduct={productsContext.products[0]}
          certificatePath='/szkolamajcake/certyfikat/certyfikat.png'
        />

        <SectionSeparator
          width={30}
          height={3}
          marginTop={60}
          marginBottom={60}
        />

        <AboutQuestions />
      </div>
    </section>
  )
}
