import nextId from 'react-id-generator'
import styles from './SectionField.module.scss'
import { HeaderContentField } from './HeaderContentField/HeaderContentField'
import { ParagraphContentField } from './ParagraphContentField/ParagraphContentField'
import { ImageContentField } from './ImageContentField/ImageContentField'
import { ListDotContentField } from './ListDotContentField/ListDotContentField'
import {
  LessonTextField,
  LessonImageField,
  LessonDotListField,
  DotListData,
  LessonImageData
} from '../../../../service/Product/lessonTypes'
import { BsTrashIcon } from '../../../../Assets/ReactIcons'
import { UpAndDownIcon } from '../../../UI/UpAndDownIcon/UpAndDownIcon'

interface Props {
  updateFieldHandler: (
    fieldToUpdate: LessonTextField | LessonImageField | LessonDotListField
  ) => void
  removeFieldHandler: () => void
  swapSectionUp: () => void
  swapSectionDown: () => void
  fieldSection: LessonTextField | LessonImageField | LessonDotListField
  fieldNumber: number
}

export const FieldGenerator = ({
  updateFieldHandler,
  removeFieldHandler,
  fieldSection,
  fieldNumber,
  swapSectionUp,
  swapSectionDown
}: Props): JSX.Element => {
  const getFieldToShow = (
    fieldSection: LessonTextField | LessonImageField | LessonDotListField
  ): JSX.Element => {
    if (fieldSection.type === 'paragraph') {
      return (
        <ParagraphContentField
          fieldValue={fieldSection.text}
          updateFieldHandler={(paragraphField: LessonTextField): void =>
            updateFieldHandler({ ...paragraphField })
          }
        />
      )
    }

    if (fieldSection.type === 'header') {
      return (
        <HeaderContentField
          fieldValue={fieldSection.text}
          updateFieldHandler={(headerField: LessonTextField): void =>
            updateFieldHandler({ ...headerField })
          }
        />
      )
    }

    if (fieldSection.type === 'dotList') {
      return (
        <ListDotContentField
          productDottedList={fieldSection.list}
          updateFieldHandler={(listField: DotListData): void => {
            updateFieldHandler({
              type: fieldSection.type,
              list: { ...listField },
              uId: nextId('dotList')
            })
          }}
        />
      )
    }

    if (fieldSection.type === 'image') {
      return (
        <ImageContentField
          imgData={fieldSection.imgData}
          updateFieldHandler={(imgData: LessonImageData): void => {
            updateFieldHandler({
              type: fieldSection.type,
              imgData: { ...imgData },
              uId: nextId('image')
            })
          }}
        />
      )
    }

    throw new Error('fieldSection.type not found')
  }

  return (
    <section className={styles.FieldNumberTitle}>
      <div className={styles.FieldTitleContainer}>
        <p className={styles.FieldNumberTitle}>{`pozycja ${fieldNumber}`}</p>
        <div className={styles.IconsContainer}>
          <div
            role='presentation'
            onClick={(): void => removeFieldHandler()}
            className={styles.TrashIcon}
          >
            {BsTrashIcon}
          </div>

          <UpAndDownIcon
            upHandler={swapSectionUp}
            downHandler={swapSectionDown}
          />
        </div>
      </div>
      {getFieldToShow(fieldSection)}
    </section>
  )
}
