import styles from './UpAndDownIcon.module.scss'
import { FiChevronDownIcon, FiChevronUpIcon } from '../../../Assets/ReactIcons'

interface Props {
  upHandler: () => void
  downHandler: () => void
}

export const UpAndDownIcon = ({
  upHandler,
  downHandler
}: Props): JSX.Element => (
  <div>
    <div
      role='presentation'
      onClick={(): void => upHandler()}
      className={styles.ChevronIcon}
    >
      {FiChevronUpIcon}
    </div>
    <div
      role='presentation'
      onClick={(): void => downHandler()}
      className={styles.ChevronIcon}
    >
      {FiChevronDownIcon}
    </div>
  </div>
)
