// function setCookie(cname: string, cvalue: string, xdays: number) {
//   const d = new Date();
//   d.setTime(d.getTime() + (xdays * 24 * 60 * 60 * 1000));
//   const expires = `expires=${d.toUTCString()}`;
//   document.cookie = `${cname}=${cvalue};${expires};path=/`;
// }

function deleteCookie(name: string): void {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}

function setCookie(cname: string, cvalue: string, cexpiration: Date) {
  document.cookie = `${cname}=${cvalue};expires=${new Date(
    cexpiration
  ).toUTCString()};path=/`
}

function getCookie(cname: string) {
  const name = `${cname}=`
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export class CookieStorageService {
  private readonly storageName: string

  constructor(cookieName: string) {
    this.storageName = cookieName
  }

  public getItem(): string | undefined {
    return getCookie(this.storageName)
  }

  public setItem(storageValue: string, expiration: Date): void {
    setCookie(this.storageName, storageValue, expiration)
  }

  public removeItem(): void {
    deleteCookie(this.storageName)
  }
}
