import { LinkWithIconAndDescription } from './interfaces'

const PublicFbGroupURL = 'https://www.facebook.com/groups/tortyodadoz'
const PrivateFbGroupURL = 'https://www.facebook.com/groups/slodkaszkola/'

export interface Discount {
  // code: string;
  codeKey: string
  companyName: string
  shortBusinessDescription: string
  codeDescription: string
  siteUrl: string
  thumbnailUrl: string
}

export const fbGroups: LinkWithIconAndDescription[] = [
  {
    name: 'Torty od A do Z - porady i wsparcie',
    description:
      'Grupa dla wszystkich miłośników produkcji tortów oraz innych słodkości. Znajdziesz tu pomoc oraz' +
      ' zyskasz możliwość podzielenia się swoim doświadczeniem i słodkimi dziełami.',
    url: PublicFbGroupURL,
    iconName: 'facebook official'
  },
  {
    name: 'Słodka Szkoła - grupa wsparcia',
    description: `Grupa dostępna tylko dla subskrybentów Słodkiej Szkoły. Przewaga tej grupy nad innymi jest taka, że
    cały czas monitorują ją nasi instruktorzy gotowi odpowiedzieć na Twoje każde pytanie. 
    Aby dołączyć wystarczy podać login (nazwę użytkownika), którą logujesz się na Słodkiej Szkole.
    Zweryfikujemy Cię maksymalnie w ciągu 24h (zwykle dzieje się to do 10 minut).`,
    url: PrivateFbGroupURL,
    iconName: 'facebook official'
  }
]
