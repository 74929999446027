import { NavLink } from 'react-router-dom'
import styles from './HeaderSection.module.scss'
import { SidebarSection } from '../../../hoc/sidebarSection/SidebarSection'
import { HeaderWithIcon } from '../../UI/headers/CustomHeader/CustomHeader'

export const HeaderSection = (): JSX.Element => (
  <SidebarSection>
    <NavLink id='app-name-header-link' to='/' className='nav-menu-item'>
      <HeaderWithIcon className={styles.Header}>Słodka Szkoła</HeaderWithIcon>
    </NavLink>
  </SidebarSection>
)
