import { useContext, useReducer, useState } from 'react'
import styles from './SingleProductForm.module.css'
import { ProductDataContext } from '../../../Context/Product/ProductDataContext'
import {
  ProductDataActions,
  ProductOperationsActionType
} from '../../../Context/Product/ProductDataReducer'
import {
  productDtoToFrontAdapter,
  productFrontToDtoAdapter
} from '../../../helpers/adapters/products'
import {
  productFormDataReducer,
  initialProductFormState,
  SingleField,
  LessonReducer,
  productFormDataActionNames
} from './ProductFormReducer'
import {
  Product,
  INIT_PRODUCT,
  ProductDto
} from '../../../service/Product/ProductService'
import { ProductDetails } from './ProductDetails/ProductDetails'
import { LessonsGenerator } from './LessonsGenerator/LessonsGenerator'
import { ArrayUtilsByAdam } from '../../../helpers/array/MyArray'
import { ButtonByAdam } from '../../UI/buttons/ButtonByAdam/ButtonByAdam'
import { GlobalNotificationContext } from '../../../Context/GlobalNotification/GlobalNotificationProvider'
import { getProductErrors } from './ProductDetails/updateProduct_validator'
import { ErrorListMessage } from '../../UI/MessageComponents/ErrorListMessage/ErrorListMessage'
import { GlobalLoaderContext } from '../../../Context/GlobalLoader/GlobalLoader'
import { NotificationType } from '../../../Context/GlobalNotification/Types'
import { putProduct } from '../../../service/Product/api'

interface Props {
  product: Product
}

export const SingleProductForm = ({ product }: Props): JSX.Element => {
  const [productFormData, dispatch] = useReducer(
    productFormDataReducer,
    product
      ? {
          ...initialProductFormState,
          data: { ...product }
        }
      : {
          ...initialProductFormState,
          data: { ...INIT_PRODUCT }
        }
  )
  const [triedToSaveProduct, setTriedToSaveProduct] = useState<boolean>(false)

  const updateFormMainData = (data: SingleField): void =>
    dispatch({
      type: productFormDataActionNames.UPDATE_PRODUCT_FORM_MAIN_DATA,
      data
    })
  const updateProductFormData = (data: Product): void =>
    dispatch({
      type: productFormDataActionNames.UPDATE_PRODUCT_FORM_DATA,
      data
    })
  const updateProductFormLessons = (data: LessonReducer): void =>
    dispatch({
      type: productFormDataActionNames.UPDATE_PRODUCT_FORM_LESSONS,
      data
    })

  const productsContext: ProductDataActions = useContext(ProductDataContext)

  const showLoader = useContext(GlobalLoaderContext)
  const showNotification = useContext(GlobalNotificationContext)

  if (!(productFormData && productFormData.data)) {
    throw Error('Product form Data is empty')
  }

  const errors: string[] = getProductErrors(
    productFormData.data.type,
    productFormData.data.name,
    productFormData.data.author,
    productFormData.data.description,
    productFormData.data.difficulty,
    productFormData.data.subscription,
    productFormData.data.price,
    productFormData.data.courseDuration
  )

  const formDisabled = triedToSaveProduct && errors.length > 0

  return (
    <>
      <section className={styles.FormMainDetails}>
        <ProductDetails
          updateFormMainDataHandler={updateFormMainData}
          productFormData={productFormData.data}
        />
      </section>

      <section className={styles.FormMainDetails}>
        <LessonsGenerator
          updateProductFormDataHandler={(
            currentIndex: number,
            nextIndex: number
          ): void => {
            const productCopy = { ...productFormData.data }
            let lessonsCopy = productFormData.data.lessons.slice()
            const arrayUtils = new ArrayUtilsByAdam(lessonsCopy)
            lessonsCopy = arrayUtils.swapArrayItems(currentIndex, nextIndex)
            productCopy.lessons = lessonsCopy.slice()
            updateProductFormData(productCopy)
          }}
          updateProductFormLessons={updateProductFormLessons}
          productData={productFormData.data}
        />
      </section>

      {formDisabled && (
        <div style={{ fontSize: '16px', marginBottom: '20px' }}>
          <ErrorListMessage title='Popraw błędy' errorList={errors} />
        </div>
      )}

      <ButtonByAdam
        disabled={formDisabled}
        onClick={async () => {
          if (!triedToSaveProduct) setTriedToSaveProduct(true)
          const productData: ProductDto = productFrontToDtoAdapter(
            productFormData.data
          )
          if (errors.length === 0) {
            showLoader(true)
            await putProduct({ ...productData })
              .then((response: ProductDto): void => {
                productsContext.updateProduct({
                  actionType: ProductOperationsActionType.UPDATE,
                  product: productDtoToFrontAdapter(response),
                  productID: productFormData.data.id
                })
                showLoader(false)
                showNotification(
                  NotificationType.INFO,
                  'Produkt został poprawnie zaktualizowany. Udanej nauki!'
                )
              })
              .catch(() => {
                showLoader(false)
              })
          }
        }}
      >
        {productFormData.data.id !== '' ? 'Zapisz zmiany' : 'Dodaj Produkt'}
      </ButtonByAdam>
    </>
  )
}
