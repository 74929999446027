import { CakePriceCalcReducer, UpdateWorkingTime } from './interfaces'

export const updateDeliveryTime = (
  data: UpdateWorkingTime,
  state: CakePriceCalcReducer
): CakePriceCalcReducer => ({
  ...state,
  deliveryTime: {
    ...state.deliveryTime,
    [data.propName]: data.value
  }
})
