import { useState } from 'react'
import styles from './CakePortionsCounter.module.scss'
import classes from '../SharedCalculatorCss.module.scss'
import { HeaderWithIcon } from '../../UI/headers/CustomHeader/CustomHeader'
import { FaCalculatorIcon } from '../../../Assets/ReactIcons'
import { ParagraphDefault } from '../../UI/Paragraphs/ParagraphDefault/ParagraphDefault'
import { CheckboxSimple } from '../../UI/checkboxSimple/CheckboxSimple'
import { Calculator, calculators } from '../../../const/calculators/calculators'
import { DefaultHeader } from '../../UI/headers/DefaultHeader/DefaultHeader'
import { SectionSeparator } from '../../UI/sectionSeparator/sectionSeparator'
import { RoundedCake } from './RoundedCake/RoundedCake'
import { SquareCake } from './SquareCake/SquareCake'
import { RectangleCake } from './RectangleCake/RectangleCake'

const CALCULATOR_TYPE = 'portions'

export const CakePortionsCounter = (): JSX.Element => {
  const [countAutomatic, setCountAutomatic] = useState<boolean>(false)
  const calculatorData: Calculator = calculators.filter(
    (calc): boolean => calc.type === CALCULATOR_TYPE
  )[0]

  if (!calculatorData) {
    return (
      <DefaultHeader>
        Wystąpił błąd, skontaktuj się z Administratorem
      </DefaultHeader>
    )
  }

  return (
    <section className={styles.Container}>
      <div className={classes.HeaderContainer}>
        <HeaderWithIcon
          className={classes.Header}
          headerType='typical'
          reactIcon={FaCalculatorIcon}
        >
          {calculatorData.title}
        </HeaderWithIcon>
      </div>

      <div className={classes.ParagraphDescription}>
        <ParagraphDefault>{calculatorData.description}</ParagraphDefault>
      </div>

      <div className={styles.CalculateModeCheckboxContainer}>
        <CheckboxSimple
          label='Tryb przeliczania automatyczny'
          onChangeHandler={(): void => setCountAutomatic(!countAutomatic)}
          value={countAutomatic}
        />
      </div>
      <SectionSeparator marginTop={30} marginBottom={30} />
      <RoundedCake countAutomatic={countAutomatic} />
      <SectionSeparator marginTop={30} marginBottom={30} />
      <RectangleCake countAutomatic={countAutomatic} />
      <SectionSeparator marginTop={30} marginBottom={30} />
      <SquareCake countAutomatic={countAutomatic} />
    </section>
  )
}
