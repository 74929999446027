import { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './SpongeCakeCounter.module.scss'
import classes from '../SharedCalculatorCss.module.scss'
import { HeaderWithIcon } from '../../UI/headers/CustomHeader/CustomHeader'
import { FaCalculatorIcon } from '../../../Assets/ReactIcons'
import { ParagraphDefault } from '../../UI/Paragraphs/ParagraphDefault/ParagraphDefault'
import { CheckboxSimple } from '../../UI/checkboxSimple/CheckboxSimple'
import { Calculator, calculators } from '../../../const/calculators/calculators'
import { DefaultHeader } from '../../UI/headers/DefaultHeader/DefaultHeader'
import { UserReducerActions } from '../../../Context/User/UserReducer'
import { UserContext } from '../../../Context/User/UserContext'
import { SectionSeparator } from '../../UI/sectionSeparator/sectionSeparator'
import { RoundedSpongeCake } from './RoundedSpongeCake/RoundedSpongeCake'
import {
  checkIfUserSubscriptionTypeIsEnough,
  translateSubscriptionType
} from '../../../helpers/utils'
import { ErrorMessage } from '../../UI/MessageComponents/ErrorMessage/ErrorMessage'
import { RectangleSpongeCake } from './RectangleSpongeCake/RectangleSpongeCake'
import { SquareSpongeCake } from './SquareSpongeCake/SquareSpongeCake'

const CALCULATOR_TYPE = 'spongecake'

export const SpongeCakeCounter = (): JSX.Element => {
  const [countAutomatic, setCountAutomatic] = useState<boolean>(false)
  const userContext: UserReducerActions = useContext(UserContext)
  const calculatorData: Calculator = calculators.filter(
    (calc): boolean => calc.type === CALCULATOR_TYPE
  )[0]

  if (!calculatorData) {
    return (
      <DefaultHeader>
        Wystąpił błąd, skontaktuj się z Administratorem
      </DefaultHeader>
    )
  }

  const isAvailableForUser = checkIfUserSubscriptionTypeIsEnough(
    userContext.userDetails,
    calculatorData.subscriptionType
  )

  return (
    <section className={styles.Container}>
      <div className={classes.HeaderContainer}>
        <HeaderWithIcon
          className={classes.Header}
          headerType='typical'
          reactIcon={FaCalculatorIcon}
        >
          {calculatorData.title}
        </HeaderWithIcon>
      </div>

      <div className={classes.ParagraphDescription}>
        <ParagraphDefault>{calculatorData.description}</ParagraphDefault>
      </div>

      {!isAvailableForUser && (
        <div style={{ fontSize: '18px' }}>
          <ErrorMessage>
            Z kalkulatora mogą korzystać tylko użytkownicy o poziomie
            subskrypcji{' '}
            <strong>
              {translateSubscriptionType(calculatorData.subscriptionType)}
            </strong>{' '}
            lub wyższej.
            <strong>
              <Link to='/subskrypcje'>
                {' '}
                Zapoznaj się z dostępnymi możliwościami
              </Link>
            </strong>
          </ErrorMessage>
        </div>
      )}

      <div className={styles.CalculateModeCheckboxContainer}>
        <CheckboxSimple
          label='Tryb przeliczania automatyczny'
          onChangeHandler={(): void => setCountAutomatic(!countAutomatic)}
          value={countAutomatic}
        />
      </div>
      <SectionSeparator marginTop={30} marginBottom={30} />
      <RoundedSpongeCake
        countAutomatic={countAutomatic}
        isAvailableForUser={isAvailableForUser}
        subType={calculatorData.subscriptionType}
      />
      <SectionSeparator marginTop={30} marginBottom={30} />
      <RectangleSpongeCake
        countAutomatic={countAutomatic}
        isAvailableForUser={isAvailableForUser}
        subType={calculatorData.subscriptionType}
      />
      <SectionSeparator marginTop={30} marginBottom={30} />
      <SquareSpongeCake
        countAutomatic={countAutomatic}
        isAvailableForUser={isAvailableForUser}
        subType={calculatorData.subscriptionType}
      />
    </section>
  )
}
