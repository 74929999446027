interface Props {
  text: string
  fullScreen?: boolean
  show?: boolean
}

export const Loader = ({ text, show, fullScreen }: Props) => (
  <div
    className={`ui active dimmer ${fullScreen ? 'fullScreenOverlay' : ''} ${
      show ? '' : 'hide'
    }`}
  >
    <div className='ui massive text loader'>{text}</div>
  </div>
)
