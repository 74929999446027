import { useContext, useState } from 'react'
import styles from './PriceSummaryWindow.module.scss'
import { CakePriceCalculatorContext } from '../CakePricing/CakePriceCalculatorContext/CakePriceCalculatorContext'
import { FiChevronDownIcon, FiChevronUpIcon } from '../../../Assets/ReactIcons'
import { formatNumbers } from '../../../helpers/formatters'

export const PriceSummaryWindow = (): JSX.Element => {
  const [expanded, setExpanded] = useState(false)
  const cakePricingContext = useContext(CakePriceCalculatorContext)

  if (cakePricingContext == null) {
    return <div>Context not loaded</div>
  }

  return (
    <aside className={styles.ModalContainer}>
      <div
        role='presentation'
        onClick={(): void => setExpanded(!expanded)}
        className={`${styles.Row} ${styles.SummaryRow}`}
      >
        <p>
          Łączna wycena:
          <span className={styles.Money}>{`${formatNumbers(
            cakePricingContext.summary.all()
          )} pln`}</span>
        </p>
        <div className={styles.Icon}>
          {expanded ? FiChevronUpIcon : FiChevronDownIcon}
        </div>
      </div>
      {expanded && (
        <>
          <div className={`${styles.Row} ${styles.SimpleRow}`}>
            <p>
              Produkty spożywcze:
              <span className={styles.Money}>{`${formatNumbers(
                cakePricingContext.summary.groceryProducts
              )} pln`}</span>
            </p>
          </div>
          <div className={`${styles.Row} ${styles.SimpleRow}`}>
            <p>
              Dodatkowe wydatki:
              <span className={styles.Money}>{`${formatNumbers(
                cakePricingContext.summary.additionalSpendings
              )} pln`}</span>
            </p>
          </div>
          <div className={`${styles.Row} ${styles.SimpleRow}`}>
            <p>
              Czas pracy:
              <span className={styles.Money}>{`${formatNumbers(
                cakePricingContext.summary.workingTime
              )} pln`}</span>
            </p>
          </div>
          <div className={`${styles.Row} ${styles.SimpleRow}`}>
            <p>
              Koszt dostawy:
              <span className={styles.Money}>{`${formatNumbers(
                cakePricingContext.summary.deliveryTime
              )} pln`}</span>
            </p>
          </div>
          <div className={`${styles.Row} ${styles.SimpleRow}`}>
            <p>
              Koszty stałe:
              <span className={styles.Money}>{`${formatNumbers(
                cakePricingContext.summary.fixedCosts
              )} pln`}</span>
            </p>
          </div>
        </>
      )}
    </aside>
  )
}
