import { Select as SelectSemantic } from 'semantic-ui-react'
import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem'
import styles from '../SharedInputStyles.module.scss'

interface Props {
  label?: string
  options: DropdownItemProps[]
  placeholder: string
  onChange: (
    val: boolean | number | string | (boolean | number | string)[]
  ) => void
  value: string
  dataRequired?: boolean
  disabled?: boolean
}

export const Select = ({
  label,
  options,
  placeholder,
  onChange,
  value,
  dataRequired,
  disabled
}: Props): JSX.Element => (
  <div className={styles.InputContainer}>
    <span
      className={styles.Label}
      style={{ visibility: label ? 'visible' : 'hidden' }}
    >
      {label}
      {dataRequired && <span style={{ color: 'red' }}>*</span>}
    </span>
    <SelectSemantic
      value={value}
      placeholder={placeholder}
      options={options}
      onChange={(event, data): void => {
        if (data.value) {
          onChange(data.value)
        }
      }}
      disabled={disabled}
    />
  </div>
)
