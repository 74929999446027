import {Cloudinary, CloudinaryImage} from "@cloudinary/url-gen";

interface UseCloudinaryProps {
    getImageInstance: (imagePublicId: string) => CloudinaryImage
}
export function useCloudinary(): UseCloudinaryProps {
    const cloudinaryInstance = new Cloudinary({
        cloud: {
            cloudName: 'majcake'
        }
    });

    function getImageInstance(imagePublicId: string): CloudinaryImage {
        return cloudinaryInstance.image(imagePublicId);
    }

    return {
        getImageInstance
    };
}
