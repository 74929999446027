import { AxiosResponse } from 'axios'
import { SubscriptionTypes } from '../../service/Product/ProductService'
import { AxiosBackend } from '../../service/HTTP/AxiosInstance'

export interface VimeoIDS {
  sub: SubscriptionTypes
  vimeoId: string
  productId: string
}

export enum PaymentType {
  SUBSCRIPTION = 'subscription',
  ONE_TIME_PAYMENT = 'oneTimePayment'
}

interface BestOffer {
  isBestOffer: boolean
  message: string
}

export interface Subscription {
  id: string
  title: string
  subTitle: string
  bestOffer?: BestOffer
  subType: SubscriptionTypes
  paymentType: PaymentType
  benefits: string[]
  duration: number
  price: number
  discount: number
  fakePrice?: number
}

export interface Dictionaries {
  vimeoIds: VimeoIDS[]
  subscriptions: Subscription[]
}

export interface DictionariesContext {
  dictionaries: Dictionaries
}

export const getDictionariesReq = (): Promise<
  AxiosResponse<{ vimeoIds: VimeoIDS[] }>
> => AxiosBackend.get<{ vimeoIds: VimeoIDS[] }>('/dictionary/vimeo')

export const getSubscriptionsReq = (): Promise<AxiosResponse<Subscription[]>> =>
  AxiosBackend.get<Subscription[]>('/subscriptions')
