import { NavLink } from 'react-router-dom'
import { HeaderWithIcon } from '../../UI/headers/CustomHeader/CustomHeader'
import { FaCalculatorIcon, GiPresentIcon } from '../../../Assets/ReactIcons'
import { SidebarSection } from '../../../hoc/sidebarSection/SidebarSection'
import {
  ACTIVE_NAVLINK_CLASSNAME,
  NAVLINK_CLASSNAME
} from '../../../const/consts'


export const CalculatorSection = (): JSX.Element => (
  <SidebarSection>
    <NavLink
      to='/calculators'
      className={({ isActive }) =>
        `${NAVLINK_CLASSNAME}  ${isActive ? ACTIVE_NAVLINK_CLASSNAME : ''}`
      }
    >
      <HeaderWithIcon reactIcon={FaCalculatorIcon}>
        Kalkulatory cukiernicze
      </HeaderWithIcon>
    </NavLink>
    <NavLink
      to='/benefits'
      className={({ isActive }) =>
        `${NAVLINK_CLASSNAME}  ${isActive ? ACTIVE_NAVLINK_CLASSNAME : ''}`
      }
    >
      <HeaderWithIcon reactIcon={GiPresentIcon}>Benefity</HeaderWithIcon>
    </NavLink>
  </SidebarSection>
)
