import styles from './FixedCostsSection.module.scss'
import inputStyles from './FixedCostsSectionRow/StandardView/StandardView.module.scss'
import { SectionWithTitle } from '../SectionWithTItle/SectionWithTItle'
import {
  FixedCostRow,
  UpdateFixedCostsConfig
} from '../CakePriceCalculatorContext/Reducer/interfaces'
import { MemoizedFixedCostsSectionRow } from './FixedCostsSectionRow/FixedCostsSectionRow'
import { InputNumber } from '../../../UI/inputs/InputNumber/InputNumber'
import { CalculatorPriceInputNumberValidator } from '../utils'
import { FIXED_COSTS_DESCRIPTION } from '../../../../const/calculators/cakePricing'

interface Props {
  fullPrice: number
  costs: FixedCostRow[]
  sweetsCounter: number
  onSweetsCounterHandler: (val: number) => void
  onUpdateCostHandler: (data: UpdateFixedCostsConfig) => void
  addCost: () => void
  removeCost: (id: string) => void
}

export const FixedCostsSection = ({
  fullPrice,
  costs,
  sweetsCounter,
  onSweetsCounterHandler,
  onUpdateCostHandler,
  addCost,
  removeCost
}: Props): JSX.Element => (
  <SectionWithTitle
    fullPrice={fullPrice}
    sectionName='Koszty stałe za 1 tort:'
    description={FIXED_COSTS_DESCRIPTION}
  >
    <div className={styles.AddProductContainer}>
      <span role='presentation' onClick={() => addCost()}>
        Dodaj koszt
      </span>
    </div>
    <div>
      {costs.map((cost, index) => (
        <MemoizedFixedCostsSectionRow
          key={cost.id}
          cost={cost}
          costIndex={index}
          onCostHandler={onUpdateCostHandler}
          removeCost={removeCost}
        />
      ))}
    </div>

    <div
      className={`${inputStyles.SectionContainer} ${inputStyles.NameSection} ${styles.AddSeparator}`}
    >
      <div className={inputStyles.ProductName}>Ilość wypieków miesięcznie</div>
      <div className={inputStyles.InputContainer}>
        <InputNumber
          type='number'
          value={sweetsCounter}
          placeholder='Ilość wypieków miesięcznie'
          onChangeHandler={(value: number): void =>
            onSweetsCounterHandler(value)
          }
          validateFnc={CalculatorPriceInputNumberValidator}
          label='szt'
        />
      </div>
    </div>
  </SectionWithTitle>
)
