import { useEffect, useMemo, useReducer } from 'react'
import { Link } from 'react-router-dom'
import { AdvancedImage, placeholder, responsive } from '@cloudinary/react'
import styles from '../../SharedCalculatorCss.module.scss'
import { InputTextLabeled } from '../../../UI/inputs/InputTextLabeled/InputTextLabeled'
import { ButtonByAdam } from '../../../UI/buttons/ButtonByAdam/ButtonByAdam'
import { HeaderWithIcon } from '../../../UI/headers/CustomHeader/CustomHeader'
import { ErrorListMessage } from '../../../UI/MessageComponents/ErrorListMessage/ErrorListMessage'
import { SuccessMessage } from '../../../UI/MessageComponents/SuccessMessage/SuccessMessage'
import {
  initialSquareSpongeCakeReducer,
  squareSpongeCakeReducer,
  SquareSpongeCakeReducerActionName
} from './SquareSpongeCakeReducer'
import { CalculatorOperations } from '../../../../helpers/cake'
import { Select } from '../../../UI/inputs/Select/Select'
import {
  getTextByCode,
  spongeCakeIngredients
} from '../../../../helpers/dictionaries'
import { SuccessListMessage } from '../../../UI/MessageComponents/SuccessListMessage/SuccessListMessage'
import { SubscriptionType } from '../../../../service/Product/ProductService'
import { ErrorMessage } from '../../../UI/MessageComponents/ErrorMessage/ErrorMessage'
import { translateSubscriptionType } from '../../../../helpers/utils'
import { useCloudinary } from '../../../../hooks/useCloudinary'

interface Props {
  countAutomatic: boolean
  isAvailableForUser: boolean
  subType: SubscriptionType
}

export const SquareSpongeCake = ({
  countAutomatic,
  isAvailableForUser,
  subType
}: Props): JSX.Element => {
  const [calculator, dispatch] = useReducer(
    squareSpongeCakeReducer,
    initialSquareSpongeCakeReducer
  )

  const { getImageInstance } = useCloudinary()

  const squareSpongeCakeImage = useMemo(() => {
    const imageInstance = getImageInstance(
      'szkolamajcake/kalkulatory/forma_kwadrat'
    )

    return (
      <AdvancedImage
        cldImg={imageInstance}
        plugins={[responsive(), placeholder()]}
      />
    )
  }, [])

  const calculate = (): void =>
    dispatch({ type: SquareSpongeCakeReducerActionName.CALCULATE_RESULT })
  const updateCalculatorData = (data: CalculatorOperations): void =>
    dispatch({
      type: SquareSpongeCakeReducerActionName.UPDATE_SQUARE_SPONGE_CAKE_CALC_DATA,
      data
    })

  useEffect((): void => {
    if (countAutomatic && isAvailableForUser) {
      calculate()
    }
  }, [calculator.sideLength, calculator.chosenSpongeType])

  return (
    <section className={styles.CalculatorContainer}>
      <div className={styles.Content_Inputs}>
        <HeaderWithIcon headerType='typical'>Foremki kwadratowe</HeaderWithIcon>

        <InputTextLabeled
          type='number'
          titleColor='pink'
          title='Podaj długość foremki'
          dataRequired
          disabled={!isAvailableForUser}
          value={calculator.sideLength}
          placeholder='Podaj długość foremki'
          onChangeHandler={(val): void =>
            updateCalculatorData({ fieldName: 'sideLength', fieldValue: val })
          }
        />

        <Select
          label='Wybierz rodzaj biszkoptu'
          value={calculator.chosenSpongeType}
          placeholder='Wybierz rodzaj biszkoptu'
          disabled={!isAvailableForUser}
          options={spongeCakeIngredients}
          onChange={(val): void =>
            updateCalculatorData({
              fieldName: 'chosenSpongeType',
              fieldValue: val as string
            })
          }
        />

        {!isAvailableForUser && (
          <div className={styles.SubscriptionErrorMessage}>
            <ErrorMessage>
              Z kalkulatora mogą korzystać tylko użytkownicy o poziomie
              subskrypcji <strong>{translateSubscriptionType(subType)}</strong>{' '}
              lub wyższej.
              <strong>
                <Link to='/subskrypcje'>
                  {' '}
                  Zapoznaj się z dostępnymi możliwościami
                </Link>
              </strong>
            </ErrorMessage>
          </div>
        )}

        {!countAutomatic && (
          <ButtonByAdam
            disabled={!isAvailableForUser}
            btnType='premium'
            onClick={(): void => calculate()}
          >
            Oblicz
          </ButtonByAdam>
        )}

        <div className={styles.ResultContainer}>
          {calculator.errors.length > 0 && (
            <ErrorListMessage
              title='Błędne wartości w kalkulatorze'
              errorList={calculator.errors}
            />
          )}
          {calculator.result && calculator.ingredients.length > 0 && (
            <>
              <SuccessMessage>
                {'Typ biszkoptu: '}
                <strong>
                  {getTextByCode(
                    calculator.chosenSpongeType,
                    spongeCakeIngredients
                  )}
                </strong>
              </SuccessMessage>
              <SuccessListMessage
                title={`Na formę o wymiarach ${calculator.sideLength}x${calculator.sideLength} cm potrzebujemy: `}
                successList={calculator.ingredients}
              />
            </>
          )}
        </div>
      </div>

      <div className={styles.Content_ImageSmaller}>{squareSpongeCakeImage}</div>
    </section>
  )
}
