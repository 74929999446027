import { Markup } from 'interweave'
import styles from './ProductDotList.module.scss'
import { GoPrimitiveDotIcon } from '../../../../../Assets/ReactIcons'
import {
  DotListData,
  LessonDotListOption
} from '../../../../../service/Product/lessonTypes'

interface Props {
  listData: DotListData
}

export const ProductDotList = ({ listData }: Props): JSX.Element => (
  <section className={styles.ListContainer}>
    <h2>{listData.title}</h2>
    <ul>
      {listData.listItems.map(
        (listItem: LessonDotListOption): JSX.Element => (
          <li key={listItem.uId}>
            <div>{GoPrimitiveDotIcon}</div>
            {/* eslint-disable-next-line react/no-danger */}
            <p>
              <Markup content={listItem.textHtml} />
            </p>
          </li>
        )
      )}
    </ul>
  </section>
)
