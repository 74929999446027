import styles from './App.module.css'
import { ProductDataContextProvider } from './Context/Product/ProductDataContext'
import { WholeAppLayout } from './Layouts/WholeAppLayout/WholeAppLayout'

export const App = (): JSX.Element => (
  <main className={styles.App}>
    <ProductDataContextProvider>
      <WholeAppLayout />
    </ProductDataContextProvider>
  </main>
)
