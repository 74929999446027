import { CakePriceCalcReducer, UpdateWorkingTime } from './interfaces'

export const updateWorkingTime = (
  data: UpdateWorkingTime,
  state: CakePriceCalcReducer
): CakePriceCalcReducer => ({
  ...state,
  workingTime: {
    ...state.workingTime,
    [data.propName]: data.value
  }
})
