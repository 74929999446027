import { InputHTMLAttributes, useEffect, useState } from 'react'
import styles from './InputTextWithValidator.module.scss'
import { ErrorsData } from '../../../Calculators/CakePricing/utils'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  value: string
  label?: string
  containerStyles?: string
  onChangeHandler?: (value: string) => void
  onBlurHandler?: (inputValue: string) => void
  validateFnc: (value: string) => ErrorsData
}

export const InputTextWithValidator = ({
  onChangeHandler,
  onBlurHandler,
  value,
  label,
  containerStyles = '',
  validateFnc,
  ...props
}: Props): JSX.Element => {
  const [inputValue, setInputValue] = useState<string>(value)

  useEffect((): void => {
    if (value !== inputValue) {
      setInputValue(value)
    }
  }, [value])

  const errorData: ErrorsData = validateFnc(inputValue)

  return (
    <div className={`${styles.Container} ${containerStyles}`}>
      <input
        className={errorData.isValid ? '' : styles.Error}
        type={props.type}
        value={inputValue}
        placeholder={props.placeholder}
        onChange={(e): void => {
          setInputValue(e.target.value)
          if (onChangeHandler && validateFnc(e.target.value).isValid) {
            onChangeHandler(e.target.value)
          }
        }}
        onBlur={(): void => {
          if (onBlurHandler) {
            onBlurHandler(inputValue)
          }
        }}
        {...props}
      />
      {label && <div className={styles.Label}>{label}</div>}
      {!errorData.isValid && (
        <aside className={styles.ErrorContainer}>
          <p>{errorData.errorMessages[0]}</p>
        </aside>
      )}
    </div>
  )
}
