import Select from 'react-select'
import styles from './SelectLib.module.scss'

interface Props {
  options: { label: string; value: string }[]
  onChangeHandler: (data: unknown) => void
  value: string
}

export const SelectLib = ({
  options,
  onChangeHandler,
  value
}: Props): JSX.Element => (
  <Select
    className={styles.Select}
    options={options}
    onChange={data => onChangeHandler(data?.value)}
    value={options.filter(option => option.value === value)[0]}
  />
)
