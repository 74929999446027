import nextId from 'react-id-generator'
import { createInitialProduct } from '../../utils'
import { CakePriceCalcReducer, UpdateGroceryConfig } from './interfaces'

export const updateGroceryProduct = (
  config: UpdateGroceryConfig,
  state: CakePriceCalcReducer
): CakePriceCalcReducer => ({
  ...state,
  groceryProducts: state.groceryProducts.map(item => {
    if (item.id === config.id) {
      return {
        ...item,
        [config.inputName]: config.value
      }
    }
    return item
  })
})

export const addGroceryProduct = (
  state: CakePriceCalcReducer
): CakePriceCalcReducer => ({
  ...state,
  groceryProducts: [
    ...state.groceryProducts,
    createInitialProduct(nextId(`${state.groceryProducts.length + 1}`))
  ]
})

export const deleteGroceryProduct = (
  id: string,
  state: CakePriceCalcReducer
): CakePriceCalcReducer => ({
  ...state,
  groceryProducts: state.groceryProducts.filter(product => product.id !== id)
})
