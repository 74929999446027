import React, { useState } from 'react'
import styles from './Sidebar.module.css'
import { HideIcon } from '../../components/sidebar/HideIcon/HideIcon'

export const Sidebar = (props: { children: React.ReactNode }): JSX.Element => {
  const [isVisible, setIsVisible] = useState<boolean>(true)
  const { children: childComponents } = props
  const toggleClass = (id: string, className: string): void => {
    const elem = document.getElementById(id)
    if (elem) {
      elem.classList.toggle(className)
    }
  }

  return (
    <aside
      className={`${styles.SidebarContainer} ${!isVisible && styles.Hidden}`}
    >
      <HideIcon
        isVisible={isVisible}
        onClickHandler={(): void =>
          setIsVisible(prevVal => {
            toggleClass('whole-app-layout', 'sidebar-hidden')
            return !prevVal
          })
        }
      />
      <div
        className={`${styles.InnerSidebarContainer} ${
          isVisible && styles.HiddenInnerSidebar
        }`}
      >
        {childComponents}
      </div>
    </aside>
  )
}
