import {useState} from 'react';
import styles from './TextSwitch.module.scss';

interface SwitchOption {
    optionName: string;
    optionKey: string;
}

interface TextSwitchProps {
    options: SwitchOption[]
    onChangeHandler: (key: string) => void;
}

export const TextSwitch = ({options, onChangeHandler}: TextSwitchProps) => {
    const [activeOption, setActiveOption] = useState<SwitchOption>(options[0]);

    if (!options.length) {
        return null;
    }

    return (
        <div className={styles.TextSwitchContainer}>
            <div className={styles.SwitchBtnWrapper}>
                {
                    options.map((item) => (
                        <p
                            key={item.optionKey}
                            role="presentation"
                            className={`${activeOption.optionKey === item.optionKey ? styles.ActiveParam : styles.NormalParam}`}
                            onClick={() => {
                                onChangeHandler(item.optionKey)
                                setActiveOption(item);
                            }}>{item.optionName}</p>
                    ))
                }
            </div>
        </div>
    )
}
