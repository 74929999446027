import nextId from 'react-id-generator'
import { TextArea } from '../../../../UI/inputs/TextArea/TextArea'
import { LessonTextField } from '../../../../../service/Product/lessonTypes'

interface Props {
  updateFieldHandler: (data: LessonTextField) => void
  fieldValue: string
}

export const ParagraphContentField = ({
  updateFieldHandler,
  fieldValue
}: Props): JSX.Element => (
  <TextArea
    label='Paragraf'
    placeholder='Wpisz Paragraf'
    value={fieldValue}
    onBlurHandler={(value: string): void => {
      updateFieldHandler({
        type: 'paragraph',
        text: value,
        uId: nextId('paragraph')
      })
    }}
    dataRequired
  />
)
