import {
  CalculatorOperations,
  getIngredientsByFrosting,
  frostingSquareCakeAmount
} from '../../../../helpers/cake'
import { squareCakeValidator } from './SquareCakeValidator'

interface SquarePortionsCalculatorActions {
  height: string
  sideLength: string
  errors: string[]
  chosenIcing: string
  ingredients: string[]
  result: number | null
  calculate: () => void
}

export const initialSquarePortionsReducer: SquarePortionsCalculatorActions = {
  height: '',
  sideLength: '',
  errors: [],
  chosenIcing: '',
  ingredients: [],
  result: null,
  calculate: (): void => {
    // INITIAL VALUE
  }
}

const updateValidationErrors = (
  errors: string[],
  state: SquarePortionsCalculatorActions
): SquarePortionsCalculatorActions => ({
  ...state,
  errors,
  ingredients: [],
  result: null
})

const calculateResult = (
  state: SquarePortionsCalculatorActions
): SquarePortionsCalculatorActions => {
  const result = Math.round(
    frostingSquareCakeAmount(
      parseFloat(state.height),
      parseFloat(state.sideLength)
    )
  )
  return {
    ...state,
    result,
    ingredients: getIngredientsByFrosting(state.chosenIcing, result),
    errors: []
  }
}

export enum SquarePortionsReducerActionName {
  CALCULATE_RESULT = 'CALCULATE_RESULT',
  UPDATE_SQUARE_PORTIONS_CALC_DATA = 'UPDATE_SQUARE_PORTIONS_CALC_DATA'
}

export type SquarePortionsReducerActions =
  | {
      type: SquarePortionsReducerActionName.CALCULATE_RESULT
    }
  | {
      type: SquarePortionsReducerActionName.UPDATE_SQUARE_PORTIONS_CALC_DATA
      data: CalculatorOperations
    }

const calculate = (
  state: SquarePortionsCalculatorActions
): SquarePortionsCalculatorActions => {
  const validationErrors = squareCakeValidator(state.height, state.sideLength)
  if (validationErrors.length > 0) {
    return updateValidationErrors(validationErrors, state)
  }
  return calculateResult(state)
}

const updateCalcData = (
  data: CalculatorOperations,
  state: SquarePortionsCalculatorActions
): SquarePortionsCalculatorActions => ({
  ...state,
  [data.fieldName]: data.fieldValue
})

export const roundedCakeReducer = (
  state: SquarePortionsCalculatorActions,
  action: SquarePortionsReducerActions
): SquarePortionsCalculatorActions => {
  switch (action.type) {
    case SquarePortionsReducerActionName.CALCULATE_RESULT:
      return calculate(state)
    case SquarePortionsReducerActionName.UPDATE_SQUARE_PORTIONS_CALC_DATA:
      return updateCalcData(action.data, state)
    default:
      return state
  }
}
