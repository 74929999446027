import { isStringNumeric } from '../../../../helpers/validators'
import { commasToDots } from '../../../../helpers/adapters/custom'

export const rectangleCakeValidator = (
  height: string,
  a: string,
  b: string
): string[] => {
  const errors: string[] = []
  const H = commasToDots(height)
  const A = commasToDots(a)
  const B = commasToDots(b)

  if (!isStringNumeric(H)) {
    errors.push('Wartość w polu "wysokość tortu" nie jest wartością liczbową')
  }

  if (!isStringNumeric(A)) {
    errors.push('Wartość w polu "Długość tortu" nie jest wartością liczbową')
  }

  if (!isStringNumeric(B)) {
    errors.push('Wartość w polu "Szerokość tortu" nie jest wartością liczbową')
  }

  if (typeof parseFloat(H) === 'number' && parseFloat(H) <= 0) {
    errors.push('Wartość w polu "wysokość tortu" musi być większa od 0')
  }

  if (typeof parseFloat(A) === 'number' && parseFloat(A) <= 0) {
    errors.push('Wartość w polu "Długość tortu" musi być większa od 0')
  }

  if (typeof parseFloat(B) === 'number' && parseFloat(A) <= 0) {
    errors.push('Wartość w polu "Szerokość tortu" musi być większa od 0')
  }

  return errors
}
