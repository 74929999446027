import styles from './Burger.module.scss'

interface Props {
  open: boolean
  setOpen: (val: boolean) => void
}

export const Burger = ({ open, setOpen, ...props }: Props): JSX.Element => (
  <button
    type='button'
    className={`
        ${styles.Burger}
        ${open ? styles.BurgerOpen : styles.BurgerClosed}
    `}
    onClick={(): void => setOpen(!open)}
    {...props}
  >
    <span />
    <span />
    <span />
  </button>
)
