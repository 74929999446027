import { useContext, useEffect, useState } from 'react'
import styles from './ResetPasswordModal.module.scss'
import { ModalFancy } from '../UI/Modals/ModalFancy/ModalFancy'
import { InputText } from '../UI/inputs/InputText/InputText'
import { ButtonByAdam } from '../UI/buttons/ButtonByAdam/ButtonByAdam'
import { DefaultHeader } from '../UI/headers/DefaultHeader/DefaultHeader'
import { GlobalNotificationContext } from '../../Context/GlobalNotification/GlobalNotificationProvider'
import { CookieStorageService } from '../../service/CookieStorageService'
import { GlobalLoaderContext } from '../../Context/GlobalLoader/GlobalLoader'
import { NotificationType } from '../../Context/GlobalNotification/Types'
import { confirmNewPassword } from '../../service/User/api'

interface Props {
  token: string
}

export const ResetPasswordModal = ({ token }: Props): JSX.Element => {
  const [password, setPassword] = useState<string>('')
  const [confirmationPassword, setConfirmationPassword] = useState<string>('')

  useEffect(() => {
    const onClickEnterHandler = (event: KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        document.getElementById('resetPasswordButton')?.click()
      }
    }
    document.addEventListener('keydown', onClickEnterHandler)
    return () => {
      document.removeEventListener('keydown', onClickEnterHandler)
    }
  }, [])

  const showLoader = useContext(GlobalLoaderContext)
  const showNotification = useContext(GlobalNotificationContext)

  const cookieService = new CookieStorageService('token')

  const resetPasswordHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    showLoader(true)
    confirmNewPassword(token, password)
      .then((): void => {
        showNotification(
          NotificationType.INFO,
          'Hasło zostało zmienione poprawnie. Za moment zostaniesz wylogowany...'
        )
        showLoader(false)
        cookieService.removeItem()
        setTimeout((): void => {
          window.location.replace('/')
        }, 4000)
      })
      .catch((): void => showLoader(false))
  }

  return (
    <div className={styles.ModalContainer}>
      <ModalFancy>
        <>
          <DefaultHeader>Wpisz swoje nowe hasło</DefaultHeader>

          <form>
            <InputText
              label='Podaj nowe hasło'
              placeholder='Wpisz nowe hasło'
              type='password'
              onChangeHandler={(val: string): void => setPassword(val)}
              value={password}
              autoComplete='new-password'
              id='reset-password'
              name='reset-password'
            />

            <InputText
              label='Powtórz hasło'
              placeholder='Potwierdź hasło'
              type='password'
              onChangeHandler={(val: string): void =>
                setConfirmationPassword(val)
              }
              value={confirmationPassword}
              autoComplete='new-password'
              id='reset-password-confirm'
              name='reset-password-confirm'
            />

            {password.length > 0 &&
              confirmationPassword.length > 0 &&
              password !== confirmationPassword && (
                <div className='ui red message'>
                  Hasło nie zostało powtórzone poprawnie
                </div>
              )}

            <ButtonByAdam
              id='resetPasswordButton'
              type='submit'
              disabled={
                password.length === 0 ||
                confirmationPassword.length === 0 ||
                password !== confirmationPassword
              }
              onClick={resetPasswordHandler}
            >
              Potwierdź zmianę hasła
            </ButtonByAdam>
          </form>
        </>
      </ModalFancy>
    </div>
  )
}
