import { useState } from 'react'
import styles from './AdditionalOptions.module.scss'
import { ConfirmationModal } from '../../UI/Modals/ConfirmationModal/ConfirmationModal'
import { HeaderWithIcon } from '../../UI/headers/CustomHeader/CustomHeader'
import { ExpandedStatus } from '../../UI/ExpandedStatus/ExpandedStatus'
import { ButtonByAdam } from '../../UI/buttons/ButtonByAdam/ButtonByAdam'
import { getUserSubscriptionData } from '../../../service/Stripe/customer'
import { ParagraphDefault } from '../../UI/Paragraphs/ParagraphDefault/ParagraphDefault'
import { CONTACT_EMAIL } from '../../../const/consts'

interface Props {
  onUserRemoveHandler: () => void
  stripeCustomerId?: string
}

export const AdditionalOptions = ({
  onUserRemoveHandler,
  stripeCustomerId
}: Props): JSX.Element => {
  const [showSettings, setShowSettings] = useState<boolean>(false)
  const [showRemoveUserModal, setShowRemoveUserModal] = useState<boolean>(false)

  return (
    <section className={styles.SectionContainer}>
      <div
        aria-hidden='true'
        tabIndex={0}
        role='button'
        className={styles.MainRow}
        onClick={(): void => setShowSettings(!showSettings)}
      >
        <HeaderWithIcon headerType='typical'>Dodatkowe opcje</HeaderWithIcon>
        <ExpandedStatus isExpanded={showSettings} />
      </div>

      {showSettings && (
        <div className={styles.ContentWrapper}>
          <div className={styles.ButtonContainer}>
            <ParagraphDefault>
              Usuwając konto - usuwasz wszystkie Twoje dane w naszym systemie.
            </ParagraphDefault>
            <ButtonByAdam
              btnType='red'
              onClick={(): void => setShowRemoveUserModal(!showRemoveUserModal)}
            >
              Usuń konto
            </ButtonByAdam>
          </div>

          {stripeCustomerId && (
            <div className={styles.ButtonContainer}>
              <ParagraphDefault>
                Gdyby wystąpiły jakieś nieprawidłowości z działaniem aplikacji,
                prosimy kliknąć poniższy przycisk. Gdyby to nie pomogło, odezwij
                się do nas na
                <a href={`mailto:${CONTACT_EMAIL}`}> {CONTACT_EMAIL}</a>
              </ParagraphDefault>
              <ButtonByAdam
                btnType='blue-invert'
                onClick={(): void => {
                  getUserSubscriptionData(stripeCustomerId).finally(null)
                }}
              >
                Synchronizuj dane subskrypcji
              </ButtonByAdam>
            </div>
          )}
        </div>
      )}

      {/* MODAL */}
      {showRemoveUserModal && (
        <ConfirmationModal
          headerLabel='Usuń użytkownika'
          headerColor='normal'
          onClose={(): void => setShowRemoveUserModal(false)}
          onDeclineHandler={(): void => {
            onUserRemoveHandler()
            setShowRemoveUserModal(false)
          }}
          declineBtnText='Usuń konto'
          onConfirmHandler={(): void => setShowRemoveUserModal(false)}
          confirmBtnText='Nie usuwaj konta'
        >
          <p>
            Jeżeli usuniesz użytkownika, stracisz wszystkie dane dotyczące tego
            konta a ich odzyskanie stanie się niemożliwe. Wykupione subskrypcje
            zostaną automatycznie anulowane.
          </p>
        </ConfirmationModal>
      )}
    </section>
  )
}
