import {
  DeliveryTimeInterface,
  UpdateDeliveryTime
} from '../../CakePriceCalculatorContext/Reducer/interfaces'
import styles from './DeliveryTime.module.scss'
import { InputNumber } from '../../../../UI/inputs/InputNumber/InputNumber'
import { CalculatorPriceInputNumberValidator } from '../../utils'

interface Props {
  data: DeliveryTimeInterface
  onChangeHandler: (data: UpdateDeliveryTime) => void
  fullPrice: number
}

export const DeliveryTimeRow = ({
  data,
  onChangeHandler,
  fullPrice
}: Props): JSX.Element => (
  <div className={styles.Container}>
    <div className={styles.InputContainer}>
      <p className={styles.Paragraph}>Cena za kilometr</p>
      <InputNumber
        type='number'
        value={data.pricePerKilometer}
        placeholder='Cena za kilometr'
        containerStyles={styles.MarginRight}
        onChangeHandler={(value: number): void =>
          onChangeHandler({
            id: data.id,
            propName: 'pricePerKilometer',
            value
          })
        }
        validateFnc={CalculatorPriceInputNumberValidator}
        label='zł'
      />
    </div>

    <div className={styles.InputContainer}>
      <p className={styles.Paragraph}>Dystans trasy</p>
      <InputNumber
        type='number'
        value={data.distance}
        placeholder='Dystans...'
        containerStyles={styles.MarginRight}
        onChangeHandler={(value: number): void =>
          onChangeHandler({
            id: data.id,
            propName: 'distance',
            value
          })
        }
        validateFnc={CalculatorPriceInputNumberValidator}
        label='km'
      />
    </div>

    <p className={styles.Price}>{`${fullPrice} pln`}</p>
  </div>
)
