import styles from './HeadSection.module.scss'
import { HeaderWithIcon } from '../../UI/headers/CustomHeader/CustomHeader'
import { ParagraphDefault } from '../../UI/Paragraphs/ParagraphDefault/ParagraphDefault'
import { UserReducerActions } from '../../../Context/User/UserReducer'

interface Props {
  userInfo: UserReducerActions
}

export const HeadSection = ({ userInfo }: Props): JSX.Element => (
  <div className={styles.Head_Container}>
    <HeaderWithIcon className={styles.Head_Header}>
      Witaj w Słodkiej Szkole,{' '}
      {`${userInfo.userDetails.name} ${userInfo.userDetails.surname}`}
    </HeaderWithIcon>

    <ParagraphDefault size='bigger'>
      Fajnie, że jesteś z nami! Pozwól, że poprowadzę Cię po platformie.
    </ParagraphDefault>
  </div>
)
