import { useState, useEffect, useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import styles from './Products.module.css'
import { Product } from '../../service/Product/ProductService'
import { PaginationCompact } from '../../components/UI/PaginationCompact/PaginationCompact'
import { PRODUCTS_PER_PAGE } from '../../const/consts'
import { ProductCard } from '../../components/UI/cards/ProductCard/ProductCard'
import { ProductFilters } from '../../components/product/ProductFilters/ProductFilters'
import { SubscriptionOperationData } from '../../components/product/ProductFilters/ProductFiltersReducer'
import { ButtonByAdam } from '../../components/UI/buttons/ButtonByAdam/ButtonByAdam'
import { UserReducerActions } from '../../Context/User/UserReducer'
import { UserContext } from '../../Context/User/UserContext'
import { SearchBar } from '../../components/UI/SearchBar/SearchBar'
import { useProductsFilters } from './useProductsFilters'
import {
  ModalPaymentProps,
  ProductPaymentModal
} from '../../components/UI/Modals/ProductPaymentModal/ProductPaymentModal'
import { UserRole } from '../../service/User/UserService'

export const Products = (): JSX.Element => {
  const { productCategory } = useParams()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [showInvisibleProducts, setShowInvisibleProducts] =
    useState<boolean>(false)
  const [paymentModalData, setPaymentModalData] = useState<ModalPaymentProps>({
    isModalVisible: false,
    product: null
  })

  const userContext: UserReducerActions = useContext(UserContext)
  const products = useProductsFilters(
    searchQuery,
    showInvisibleProducts,
    productCategory || ''
  )

  useEffect((): void => {
    setCurrentPage(1)
  }, [products.productFilters])

  const allPages = Math.ceil(
    products.avaliableProducts.length / PRODUCTS_PER_PAGE
  )
  const productsToShow: Product[] = products.avaliableProducts.slice(
    (currentPage - 1) * PRODUCTS_PER_PAGE,
    currentPage * PRODUCTS_PER_PAGE
  )

  const onModalCloseHandler = () =>
    setPaymentModalData({ isModalVisible: false, product: null })

  return (
    <>
      {/* Payment modal */}
      {paymentModalData.isModalVisible && (
        <ProductPaymentModal
          onModalClose={onModalCloseHandler}
          paymentData={paymentModalData}
        />
      )}

      {userContext.userDetails.role === UserRole.ADMIN && (
        <div className={styles.ContainerAdminButtons}>
          <Link id='courses-add-product' to='/products/create'>
            <ButtonByAdam>Dodaj produkt</ButtonByAdam>
          </Link>

          <ButtonByAdam
            btnType='warning'
            onClick={(): void =>
              setShowInvisibleProducts(!showInvisibleProducts)
            }
          >
            {showInvisibleProducts ? 'Pokaż wszytkie' : 'Pokaz niewidoczne'}
          </ButtonByAdam>
        </div>
      )}

      <ProductFilters
        filtersActions={products.productFilters}
        updateChosenSubType={(data: SubscriptionOperationData): void =>
          products.updateChosenSubType(data)
        }
        updateTagFilters={(data: string[]): void => products.updateTags(data)}
      />

      <SearchBar
        query={searchQuery}
        onChange={(value: string): void => {
          setSearchQuery(value)
        }}
        placeholder='Wyszukaj produkt...'
      />

      {/* Pagination */}
      <div className={styles.PaginationContainer}>
        <div>
          <PaginationCompact
            activePage={currentPage}
            onChangeHandler={(activePage: number): void =>
              setCurrentPage(activePage)
            }
            totalPages={allPages}
          />
        </div>
      </div>

      {/* Product cards */}
      {productsToShow.length > 0 ? (
        <section className={styles.ProductsContainer}>
          {productsToShow.map(
            (singleProduct: Product): JSX.Element => (
              <ProductCard
                key={`single-course-card-${singleProduct.id}`}
                productID={singleProduct.id}
                title={singleProduct.name}
                description={singleProduct.description}
                author={singleProduct.author}
                subscriptionType={singleProduct.subscription}
                productViewPath={`/products/${singleProduct.type}/${singleProduct.id}`}
                courseDuration={singleProduct.courseDuration}
                cardImagePath={singleProduct.cardImagePath}
                invisibleProduct={singleProduct.invisibleProduct}
                productPrice={singleProduct.price}
                getPaymentModalData={setPaymentModalData}
              />
            )
          )}
        </section>
      ) : (
        <h1>
          Brak pasujących produktów. Zmień ustawienia filtrów lub szukaną frazę
        </h1>
      )}

      {/* Pagination */}
      <div className={styles.PaginationContainer}>
        <div>
          <PaginationCompact
            activePage={currentPage}
            onChangeHandler={(activePage: number): void =>
              setCurrentPage(activePage)
            }
            totalPages={allPages}
          />
        </div>
      </div>
    </>
  )
}
