import { useState } from 'react'
import styles from './SingleFieldWrapper.module.scss'
import { InputText } from '../../../UI/inputs/InputText/InputText'
import {
  BiEditIcon,
  FaCheckIcon,
  FaTimesIcon
} from '../../../../Assets/ReactIcons'

interface Props {
  value: string
  onBlurHandler: (val: string) => void
  label: string
  placeholder: string
}

export const SingleFieldWrapper = ({
  value,
  onBlurHandler,
  placeholder,
  label
}: Props): JSX.Element => {
  const [editMode, setEditMode] = useState(false)
  const [fieldVal, setFieldVal] = useState<string>(value)

  return (
    <div className={styles.SingleFieldWrapperContainer}>
      {editMode ? (
        <div className={styles.ViewMode}>
          <InputText
            placeholder={placeholder}
            onChangeHandler={(val: string): void => setFieldVal(val)}
            value={value}
          />
        </div>
      ) : (
        <p className={styles.Value}>
          <span>{label}</span>
          {`: ${value}`}
        </p>
      )}

      <div className={styles.IconContainer}>
        {editMode ? (
          <div
            aria-hidden='true'
            tabIndex={0}
            role='button'
            className={styles.TwoIconContainer}
            onClick={(): void => setEditMode(false)}
          >
            <div
              aria-hidden='true'
              tabIndex={0}
              role='button'
              className={styles.CheckIcon}
              onClick={(): void => onBlurHandler(fieldVal)}
            >
              {FaCheckIcon}
            </div>
            <div
              aria-hidden='true'
              tabIndex={0}
              role='button'
              className={styles.TimesIcon}
              onClick={(): void => {
                onBlurHandler(value)
              }}
            >
              {FaTimesIcon}
            </div>
          </div>
        ) : (
          <div
            aria-hidden='true'
            tabIndex={0}
            role='button'
            onClick={(): void => setEditMode(true)}
          >
            <div className={styles.EditIcon}>{BiEditIcon}</div>
          </div>
        )}
      </div>
    </div>
  )
}
