import { SectionWithTitle } from '../SectionWithTItle/SectionWithTItle'
import { WorkingTimeRow } from './WorkingTimeRow/WorkingTimeRow'
import {
  UpdateWorkingTime,
  WorkingTimeInterface
} from '../CakePriceCalculatorContext/Reducer/interfaces'
import { WORKING_TIME_DESCRIPTION } from '../../../../const/calculators/cakePricing'

interface Props {
  data: WorkingTimeInterface
  fullPrice: number
  onChangeHandler: (data: UpdateWorkingTime) => void
}

export const WorkingTime = ({
  data,
  fullPrice,
  onChangeHandler
}: Props): JSX.Element => (
  <SectionWithTitle
    fullPrice={fullPrice}
    sectionName='Czas pracy:'
    description={WORKING_TIME_DESCRIPTION}
  >
    <WorkingTimeRow
      data={data}
      onChangeHandler={onChangeHandler}
      fullPrice={fullPrice}
    />
  </SectionWithTitle>
)
