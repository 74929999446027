import React, { useState } from 'react'
import {
  FixedCostRow,
  UpdateFixedCostsConfig
} from '../../CakePriceCalculatorContext/Reducer/interfaces'
import { StandardView } from './StandardView/StandardView'
import { ConfigView } from './ConfigView/ConfigView'

interface Props {
  cost: FixedCostRow
  costIndex: number
  onCostHandler: (data: UpdateFixedCostsConfig) => void
  removeCost: (id: string) => void
}

export const FixedCostsSectionRow = ({
  cost,
  costIndex,
  onCostHandler,
  removeCost
}: Props): JSX.Element => {
  const [showConfig, setShowConfig] = useState<boolean>(false)

  return (
    <div>
      {showConfig ? (
        <ConfigView
          cost={cost}
          costIndex={costIndex}
          onCostHandler={onCostHandler}
          removeCost={removeCost}
          changeView={(): void => setShowConfig(!showConfig)}
        />
      ) : (
        <StandardView
          cost={cost}
          costIndex={costIndex}
          onCostHandler={onCostHandler}
          removeCost={removeCost}
          changeView={(): void => setShowConfig(!showConfig)}
        />
      )}
    </div>
  )
}

function rowPropsAreEqual(): boolean {
  return false
}

export const MemoizedFixedCostsSectionRow = React.memo(
  FixedCostsSectionRow,
  rowPropsAreEqual
)
