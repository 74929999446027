import React from 'react'
import styles from './CustomHeader.module.scss'

interface Props extends React.ButtonHTMLAttributes<HTMLHeadingElement> {
  reactIcon?: JSX.Element
  headerType?: 'typical'
}

export const HeaderWithIcon = ({
  reactIcon,
  headerType,
  ...props
}: Props): JSX.Element => (
  <div
    {...props}
    className={`${styles.CustomHeaderContainer} ${
      headerType === 'typical' && styles.TypicalH2
    } ${props.className}`}
  >
    {reactIcon && <div className={styles.IconContainer}>{reactIcon}</div>}
    <h2>{props.children}</h2>
  </div>
)
