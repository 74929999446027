import { Dispatch, SetStateAction, useContext } from 'react'
import { Link } from 'react-router-dom'
import {fill} from "@cloudinary/url-gen/actions/resize";
import {AdvancedImage} from "@cloudinary/react";
import styles from './ProductCard.module.scss'
import { ParagraphDefault } from '../../Paragraphs/ParagraphDefault/ParagraphDefault'
import { DefaultHeader } from '../../headers/DefaultHeader/DefaultHeader'
import { AuthorRow } from './AuthorRow/AuthorRow'
import { Overlay } from '../../Overlay/Overlay'
import {
  subscriptionTypes,
  getTextByCode
} from '../../../../helpers/dictionaries'
import { LabelTag } from '../../Pills/LabelTag/LabelTag'
import {
  Product,
  SubscriptionTypes
} from '../../../../service/Product/ProductService'
import { UserReducerActions } from '../../../../Context/User/UserReducer'
import { UserContext } from '../../../../Context/User/UserContext'
import { ProductDataActions } from '../../../../Context/Product/ProductDataReducer'
import { ProductDataContext } from '../../../../Context/Product/ProductDataContext'
import { BuyProductCardRow } from '../../../product/BuyProductCardRow/BuyProductCardRow'
import { ModalPaymentProps } from '../../Modals/ProductPaymentModal/ProductPaymentModal'
import {useCloudinary} from "../../../../hooks/useCloudinary";


interface Props {
  productID: string
  title: string
  description: string
  author: string
  subscriptionType: SubscriptionTypes
  productViewPath: string
  cardImagePath: string
  courseDuration: string
  invisibleProduct: boolean
  productPrice: number
  getPaymentModalData: Dispatch<SetStateAction<ModalPaymentProps>>
}

export const ProductCard = ({
  productID,
  title,
  description,
  author,
  subscriptionType,
  productViewPath,
  cardImagePath,
  courseDuration,
  invisibleProduct,
  productPrice,
  getPaymentModalData
}: Props): JSX.Element => {
  const productsContext: ProductDataActions = useContext(ProductDataContext)
  const userContext: UserReducerActions = useContext(UserContext)

  const {getImageInstance} = useCloudinary();
  const cardImage = getImageInstance(cardImagePath || 'szkolamajcake/logo/zajawka.jpg')
      .resize(fill()
          .width(630))
      .quality('auto')

  const searchedProduct = productsContext.products.filter(
    (singleProduct: Product) => singleProduct.id === productID
  )
  const isCourseBought = userContext.checkIfAvailableToWatch(searchedProduct[0])

  return (
    <article className={styles.ProductCardContainer}>
      <div className={invisibleProduct ? styles.ProductCard_Invisible : ''}>
        {' '}
      </div>
      <div className={styles.ContentPositioning}>
        <Link id={`product-card-${productID}`} to={productViewPath}>
          <div className={invisibleProduct ? styles.ProductCard_Invisible : ''}>
            {' '}
          </div>
          <div>
            <div className={styles.ImageSection}>
              <div className={styles.LabelTagContainer}>
                <LabelTag
                  text={getTextByCode(subscriptionType, subscriptionTypes)}
                  pillType={subscriptionType}
                  size='big'
                />
              </div>

              <AdvancedImage cldImg={cardImage} />

              {courseDuration && (
                <div className={styles.CourseDurationLabel}>
                  <ParagraphDefault className={styles.CourseDurationLabel_Text}>
                    {courseDuration}
                  </ParagraphDefault>
                </div>
              )}
              <Overlay />
            </div>
          </div>
        </Link>

        <BuyProductCardRow
          productPrice={productPrice}
          productID={productID}
          isBought={isCourseBought}
          getPaymentModalData={getPaymentModalData}
        />

        <Link
          id={`product-card-${productID}`}
          to={productViewPath}
          className={styles.ContentPositioning}
        >
          <header className={styles.HeaderSection}>
            <DefaultHeader>{title}</DefaultHeader>
            <ParagraphDefault>{description}</ParagraphDefault>
          </header>
          <AuthorRow author={author} />
        </Link>
      </div>
    </article>
  )
}
