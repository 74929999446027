import styles from './AdditionalSpendings.module.scss'
import { SectionWithTitle } from '../SectionWithTItle/SectionWithTItle'
import { MemoizedAdditionalSpendingsRow } from './AdditionalSpendingsRow/AdditionalSpendingsRow'
import {
  AdditionalSpendingsInterface,
  UpdateAdditionalSpendingsConfig
} from '../CakePriceCalculatorContext/Reducer/interfaces'
import { ADDITIONAL_SPENDINGS_DESCRIPTION } from '../../../../const/calculators/cakePricing'

interface Props {
  products: AdditionalSpendingsInterface[]
  fullPrice: number
  onChangeHandler: (data: UpdateAdditionalSpendingsConfig) => void
  addProductHandler: () => void
  removeRowHandler: (id: string) => void
}

export const AdditionalSpendings = ({
  products,
  addProductHandler,
  fullPrice,
  onChangeHandler,
  removeRowHandler
}: Props): JSX.Element => (
  <SectionWithTitle
    fullPrice={fullPrice}
    sectionName='Dodatkowe wydatki:'
    description={ADDITIONAL_SPENDINGS_DESCRIPTION}
  >
    <div className={styles.AddProductContainer}>
      <span role='presentation' onClick={addProductHandler}>
        Dodaj wydatek
      </span>
    </div>
    {products.map(
      (product, index): JSX.Element => (
        <MemoizedAdditionalSpendingsRow
          key={product.id}
          product={product}
          index={index}
          onChangeHandler={onChangeHandler}
          removeRowHandler={removeRowHandler}
        />
      )
    )}
  </SectionWithTitle>
)
