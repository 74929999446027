import nextId from 'react-id-generator'
import { ButtonByAdam } from '../../../UI/buttons/ButtonByAdam/ButtonByAdam'
import {
  INIT_LESSON,
  Product
} from '../../../../service/Product/ProductService'
import { LessonReducer } from '../ProductFormReducer'
import { SectionSeparator } from '../../../UI/sectionSeparator/sectionSeparator'
import { Lesson } from '../../../../service/Product/lessonTypes'
import { ProductLessonForm } from '../../ProductLesson/ProductLessonForm'

interface Props {
  updateProductFormLessons(data: LessonReducer): void

  productData: Product
  updateProductFormDataHandler: (
    currentIndex: number,
    nextIndex: number
  ) => void
}

export const LessonsGenerator = ({
  updateProductFormLessons,
  productData,
  updateProductFormDataHandler
}: Props): JSX.Element => (
  <>
    <ButtonByAdam
      onClick={(): void =>
        updateProductFormLessons({
          lesson: { ...INIT_LESSON, uId: nextId() },
          operationType: 'ADD'
        })
      }
    >
      Dodaj lekcję
    </ButtonByAdam>

    <SectionSeparator />
    {productData.lessons.map(
      (lesson: Lesson, index: number): JSX.Element => (
        <section key={lesson.uId}>
          <ProductLessonForm
            updateProductFormDataHandler={updateProductFormDataHandler}
            lessonNumber={index + 1}
            lessonsAmount={productData.lessons.length}
            lesson={productData.lessons[index]}
            updateLessonHandler={(lessonToUpdate: Lesson): void => {
              updateProductFormLessons({
                lesson: lessonToUpdate,
                operationType: 'UPDATE',
                lessonIndex: index
              })
            }}
            removeLessonHandler={(): void => {
              updateProductFormLessons({
                lesson: INIT_LESSON,
                operationType: 'REMOVE',
                lessonIndex: index
              })
            }}
          />
        </section>
      )
    )}
  </>
)
