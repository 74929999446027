import styles from './Overlay.module.scss'

interface Props {
  type?: 'dark'
  linearAnimation?: boolean
  zIndexCustom?: number
}

export const Overlay = ({
  type,
  linearAnimation,
  zIndexCustom
}: Props): JSX.Element => (
  <div
    style={{ zIndex: zIndexCustom || 3 }}
    className={`
        ${styles.Overlay}
        ${type === 'dark' && styles.Dark}
        ${linearAnimation && styles.OverlayOpen}
        `}
  />
)
