import nextId from 'react-id-generator'
import { createInitialProduct } from '../../utils'
import { CakePriceCalcReducer } from './interfaces'

export type UpdateAdditionalSpendingsConfig = {
  id: string
  inputName: string
  value: string | number
}

export const updateAdditionalSpendings = (
  config: UpdateAdditionalSpendingsConfig,
  state: CakePriceCalcReducer
): CakePriceCalcReducer => ({
  ...state,
  additionalSpending: state.additionalSpending.map(item => {
    if (item.id === config.id) {
      return {
        ...item,
        [config.inputName]: config.value
      }
    }
    return item
  })
})

export const addAdditionalSpendings = (
  state: CakePriceCalcReducer
): CakePriceCalcReducer => ({
  ...state,
  additionalSpending: [
    ...state.additionalSpending,
    createInitialProduct(nextId(`${state.additionalSpending.length + 1}`))
  ]
})

export const deleteAdditionalSpendings = (
  id: string,
  state: CakePriceCalcReducer
): CakePriceCalcReducer => ({
  ...state,
  additionalSpending: state.additionalSpending.filter(
    product => product.id !== id
  )
})
