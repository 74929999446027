import { AxiosResponse } from 'axios'
import {
  LoginData,
  PatchUser,
  SignupData,
  TokenResponse,
  UserDto
} from './UserService'
import {AxiosBackend} from "../HTTP/AxiosInstance";

export const checkTokenReq = async () => {
  const { data } = await AxiosBackend.get<TokenResponse>('/user/checktoken', {
    withCredentials: true
  })

  if (data) {
    return Promise.resolve(data)
  }
  return Promise.reject(new Error('Check token failed'))
}

export const userAuthenticate = async (credentials: LoginData) =>
    AxiosBackend.post<UserDto>('/user/authenticate', {
    username: credentials.username,
    password: credentials.password
  }).then(response => {
    if (response.status === 200) {
      return Promise.resolve(response.data)
    }
    return Promise.reject(new Error('Req Error'))
  })

export const resetPassword = (): Promise<AxiosResponse<void>> =>
    AxiosBackend.get('/user/reset-password')

export const restorePassword = async (email: string) =>
    AxiosBackend.post('/visitor/recovery-password', { email }).then(response => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve()
    }
    return Promise.reject()
  })

export const confirmNewPassword = (
  token: string,
  password: string
): Promise<void> =>
    AxiosBackend.post('/user/new-password', {
    token,
    password
  }).then(response => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve()
    }
    return Promise.reject()
  })

export const confirmNewPasswordVisitor = (
  token: string,
  password: string
): Promise<void> =>
    AxiosBackend.post('/visitor/new-password', {
    token,
    password
  }).then(response => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve()
    }
    return Promise.reject()
  })

export const userSignup = (
  user: SignupData,
  confirmPassword: string
): Promise<AxiosResponse<UserDto>> =>
    AxiosBackend.post('/user', { ...user, confirmPassword }).then(response => {
    if (response) {
      return Promise.resolve(response)
    }
    return Promise.reject()
  })

export const userRemove = (): Promise<void> =>
    AxiosBackend.delete('/user').then(response => {
    if (response.status === 200) {
      return Promise.resolve()
    }
    return Promise.reject()
  })

export const updateUserData = (
  user: PatchUser
): Promise<AxiosResponse<TokenResponse>> => AxiosBackend.patch('/user', { ...user })
