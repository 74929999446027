import { Lesson, INIT_LESSON } from '../../../service/Product/lessonTypes'

export enum ProductLessonActionNames {
  UPDATE_PRODUCT_LESSON = 'UPDATE_PRODUCT_LESSON',
  UPDATE_PRODUCT_LESSON_MAIN_DATA = 'UPDATE_PRODUCT_LESSON_MAIN_DATA'
}

export interface ProductLessonDataActions {
  data: Lesson
  updateProductLesson: () => void
  updateProductLessonMainData: () => void
}

export interface SingleLessonField {
  data: Lesson
  fieldName: string
  fieldValue: string
}

type ProductLessonReducerActions =
  | {
      type: ProductLessonActionNames.UPDATE_PRODUCT_LESSON
      data: Lesson
    }
  | {
      type: ProductLessonActionNames.UPDATE_PRODUCT_LESSON_MAIN_DATA
      data: SingleLessonField
    }

export const initialLesson: ProductLessonDataActions = {
  data: INIT_LESSON,
  updateProductLesson: (): void => {
    // Initial value
  },
  updateProductLessonMainData: (): void => {
    // Initial value
  }
}

const updateProductLesson = (
  data: Lesson,
  state: ProductLessonDataActions
): ProductLessonDataActions => ({
  ...state,
  data
})

const updateProductLessonMainData = (
  data: SingleLessonField,
  state: ProductLessonDataActions
): ProductLessonDataActions => {
  if (data.fieldName === 'sectionName') {
    return {
      ...state,
      data: {
        ...state.data,
        [data.fieldName]: data.fieldValue
      }
    }
  }
  if (
    data.fieldName === 'movieId' ||
    data.fieldName === 'placeholderImage' ||
    data.fieldName === 'sourceType'
  ) {
    return {
      ...state,
      data: {
        ...state.data,
        video: {
          ...state.data.video,
          [data.fieldName]: data.fieldValue
        }
      }
    }
  }
  return { ...state }
}

export const productLessonReducer = (
  state: ProductLessonDataActions,
  action: ProductLessonReducerActions
): ProductLessonDataActions => {
  switch (action.type) {
    case ProductLessonActionNames.UPDATE_PRODUCT_LESSON:
      return updateProductLesson(action.data, state)
    case ProductLessonActionNames.UPDATE_PRODUCT_LESSON_MAIN_DATA:
      return updateProductLessonMainData(action.data, state)
    default:
      return state
  }
}
