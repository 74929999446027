import styles from './FeaturesList.module.scss'
import { PLATFORM_BENEFITS } from '../../../const/consts'

export const FeaturesList = (): JSX.Element => (
  <div className={styles.FeatureContainer}>
    <h2>Czym jest Słodka Szkoła?</h2>
    <ul>
      {PLATFORM_BENEFITS.map((benefit, index) => (
        <li key={`${index + 1}`}>{benefit}</li>
      ))}
    </ul>
  </div>
)
