import { useState } from 'react'
import styles from './ProductFilters.module.scss'
import { FilterTypeBtn } from '../../UI/buttons/FilterTypeBtn/FilterTypeBtn'
import {
  ProductFilterActions,
  SubscriptionOperationData
} from './ProductFiltersReducer'
import { HeaderWithIcon } from '../../UI/headers/CustomHeader/CustomHeader'
import { ExpandedStatus } from '../../UI/ExpandedStatus/ExpandedStatus'
import { DropdownExampleMultipleSearchSelection } from '../../UI/DropdownMultipleSearchSelection/DropdownMultipleSearchSelection'
import { tagsForSelect } from '../../../const/Dictionaries/product'
import { SubscriptionType } from '../../../service/Product/ProductService'

interface Props {
  filtersActions: ProductFilterActions
  updateChosenSubType: (data: SubscriptionOperationData) => void
  updateTagFilters: (data: string[]) => void
}

export const ProductFilters = ({
  filtersActions,
  updateChosenSubType,
  updateTagFilters
}: Props): JSX.Element => {
  const [showFilters, setShowFilters] = useState<boolean>(true)

  const isPremiumActive = filtersActions.filters.subscriptionType.includes(
    SubscriptionType.PREMIUM
  )
  const isBasicActive = filtersActions.filters.subscriptionType.includes(
    SubscriptionType.BASIC
  )
  const isFreeActive = filtersActions.filters.subscriptionType.includes(
    SubscriptionType.FREE
  )

  return (
    <section className={styles.FiltersContainer}>
      <div
        role='presentation'
        className={styles.FiltersMainRow}
        onClick={(): void => setShowFilters(!showFilters)}
      >
        <HeaderWithIcon className={styles.FiltersMainRowHeader}>
          Filtry
        </HeaderWithIcon>
        <ExpandedStatus
          isExpanded={showFilters}
          onClickHandler={(): void => setShowFilters(!showFilters)}
        />
      </div>

      {showFilters && (
        <>
          <div className={styles.FilterRowWrapper}>
            <p>Poziom Subskrypcji</p>
            <FilterTypeBtn
              btnType='premium'
              id='premium_small_button'
              isActive={isPremiumActive}
              onClick={(): void =>
                updateChosenSubType({
                  type: isPremiumActive ? 'REMOVE' : 'ADD',
                  value: SubscriptionType.PREMIUM
                })
              }
            >
              Premium
            </FilterTypeBtn>
            <FilterTypeBtn
              btnType='basic'
              id='basic_small_button'
              isActive={isBasicActive}
              onClick={(): void => {
                updateChosenSubType({
                  type: isBasicActive ? 'REMOVE' : 'ADD',
                  value: SubscriptionType.BASIC
                })
              }}
            >
              Podstawowa
            </FilterTypeBtn>
            <FilterTypeBtn
              btnType='free'
              id='free_small_button'
              isActive={isFreeActive}
              onClick={(): void =>
                updateChosenSubType({
                  type: isFreeActive ? 'REMOVE' : 'ADD',
                  value: SubscriptionType.FREE
                })
              }
            >
              Darmowa
            </FilterTypeBtn>
          </div>
          <div className={styles.FilterRowWrapper}>
            <DropdownExampleMultipleSearchSelection
              options={tagsForSelect}
              placeholder='Jaka tematyka Cie interesuje?'
              defaultValue={filtersActions.filters.tags}
              onBlurHandler={(val): void => {
                updateTagFilters(val as string[])
              }}
            />
          </div>
        </>
      )}
    </section>
  )
}
