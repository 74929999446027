import React from 'react'
import styles from './DefaultHeader.module.scss'

interface Props extends React.HTMLAttributes<HTMLHeadingElement> {
  isLink?: boolean
}

export const DefaultHeader = ({ isLink, ...props }: Props): JSX.Element => (
  <p
    className={props.className || (isLink ? styles.HeaderLink : styles.Header)}
    {...props}
  >
    {props.children}
  </p>
)
