import { InputHTMLAttributes, useEffect, useState } from 'react'
import styles from './InputNumber.module.scss'
import { ErrorsData } from '../../../Calculators/CakePricing/utils'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  value: number
  label?: string
  dataRequired?: boolean
  containerStyles?: string
  onChangeHandler?: (value: number) => void
  onBlurHandler?: (inputValue: number) => void
  validateFnc: (value: number) => ErrorsData
}

export const InputNumber = ({
  onChangeHandler,
  onBlurHandler,
  value,
  label,
  containerStyles = '',
  validateFnc,
  ...props
}: Props): JSX.Element => {
  const [inputValue, setInputValue] = useState<number>(value || 0)

  useEffect((): void => {
    if (value !== inputValue) {
      setInputValue(value)
    }
  }, [value])

  const errorData: ErrorsData = validateFnc(inputValue)

  return (
    <div className={`${styles.Container} ${containerStyles}`}>
      <input
        className={errorData.isValid ? '' : styles.Error}
        type={props.type}
        value={inputValue}
        placeholder={props.placeholder}
        onChange={(e): void => {
          const val = parseFloat(e.target.value)
          if (e.target.value === '') {
            setInputValue(e.target.value as unknown as number)
          } else if (!Number.isNaN(val)) {
            setInputValue(val)
            if (onChangeHandler && validateFnc(val).isValid) {
              onChangeHandler(val)
            }
          }
        }}
        onBlur={(): void => {
          if (onBlurHandler) {
            onBlurHandler(inputValue)
          }
        }}
        {...props}
      />
      <div className={styles.Label}>{label}</div>
      {!errorData.isValid && (
        <aside className={styles.ErrorContainer}>
          <p>{errorData.errorMessages[0]}</p>
        </aside>
      )}
    </div>
  )
}
