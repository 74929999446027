import React from 'react'
import styles from './ButtonByAdam.module.scss'

export interface ButtonByAdamProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  btnType?:
    | 'green'
    | 'red'
    | 'red-transparent'
    | 'blue'
    | 'blue-invert'
    | 'premium-invert'
    | 'premium'
    | 'warning'
  isActive?: boolean
}

export const ButtonByAdam = ({
  btnType,
  isActive = false,
  ...props
}: ButtonByAdamProps): JSX.Element => (
  <button
    type='button'
    {...props}
    className={`${styles.Button} ${btnType ? styles[btnType] : ''}  ${
      isActive && styles.active
    } ${props.disabled ? styles.Disabled : ''} ${props.className || ''}`}
    id={props.id}
    onClick={props.onClick}
  >
    {props.children}
  </button>
)
