import { INIT_USER, User } from '../../../service/User/UserService'
import { UserOperations } from '../../../Context/User/UserReducer'

export enum UserSettingsFormReducerActions {
  UPDATE_USER_FORM_VALUE = 'UPDATE_USER_FORM_VALUE'
}

export type UserSettingsFormAction = {
  type: UserSettingsFormReducerActions.UPDATE_USER_FORM_VALUE
  data: UserOperations
}

export interface UserSettingsDataActions {
  userData: User
  updateUserFormValue: () => void
}

export const initialUserSettingsReducer: UserSettingsDataActions = {
  userData: { ...INIT_USER },
  updateUserFormValue: (): void => {
    // Initial function
  }
}

const updateUserFormValue = (
  data: UserOperations,
  state: UserSettingsDataActions
): UserSettingsDataActions => ({
  ...state,
  userData: {
    ...state.userData,
    [data.fieldName]: data.fieldValue
  }
})

export const userSettingsFormReducer = (
  state: UserSettingsDataActions,
  action: UserSettingsFormAction
): UserSettingsDataActions => {
  switch (action.type) {
    case UserSettingsFormReducerActions.UPDATE_USER_FORM_VALUE:
      return updateUserFormValue(action.data, state)
    default:
      return state
  }
}
