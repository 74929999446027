import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem'
import { formatNumbers } from '../../../helpers/formatters'
import {
  CakePriceCalcReducer,
  DeliveryTimeInterface,
  FixedCostRow,
  FixedCostsInterface,
  GroceryProductInterface,
  KitchenMeasure,
  WorkingTimeInterface
} from './CakePriceCalculatorContext/Reducer/interfaces'
import { PriceMainInfo } from './CakePriceCalculatorContext/interfaces'
import {
  SubscriptionType,
  SubscriptionTypes
} from '../../../service/Product/ProductService'
import { SAVED_PRICINGS_COUNT } from '../../../const/calculators/cakePricing'

export const calculateProductPrice = (
  product: GroceryProductInterface
): string =>
  formatNumbers((product.quantityUsed / product.quantityBought) * product.price)

export const calculateAllProductsPrice = (
  products: GroceryProductInterface[]
): number =>
  products.reduce(
    (prevProduct, nextProduct): number =>
      prevProduct +
      (nextProduct.quantityUsed / nextProduct.quantityBought) *
        nextProduct.price,
    0
  )

export const calculateWorkingTime = (workData: WorkingTimeInterface): number =>
  workData.price * workData.quantity
export const calculateDeliveryTime = (
  deliveryTime: DeliveryTimeInterface
): number => deliveryTime.distance * deliveryTime.pricePerKilometer

export const calculateFixedPrice = (fixedCosts: FixedCostsInterface): number =>
  fixedCosts.costs.reduce(
    (prevCost, nextCost): number => prevCost + nextCost.price,
    0
  ) / fixedCosts.sweetsCount

export const createInitialProduct = (id: string): GroceryProductInterface => ({
  id,
  measure: KitchenMeasure.KG,
  name: 'Nazwa produktu',
  price: 30,
  quantityBought: 2,
  quantityUsed: 1
})

export const createInitialCost = (id: string): FixedCostRow => ({
  id,
  name: 'Usługa',
  price: 100
})

export const pricingsToDropdownData = (
  pricings: CakePriceCalcReducer[]
): DropdownItemProps[] =>
  pricings.map(pricing => ({
    key: pricing.id,
    value: pricing.id,
    text: pricing.pricingName
  }))

export const mainInfoToDropdownProps = (
  data: PriceMainInfo[]
): DropdownItemProps[] =>
  data.map(
    (pricing): DropdownItemProps => ({
      key: pricing.id,
      value: pricing.id,
      text: pricing.pricingName
    })
  )

export const pricingBackToFrontAdapter = (
  data: CakePriceCalcReducer
): CakePriceCalcReducer => ({
  ...data,
  fixedCosts: data.fixedCosts
    ? data.fixedCosts
    : {
        costs: [{ id: 'fixedCost-1', price: 10, name: 'Prąd' }],
        sweetsCount: 10
      }
})

export interface ErrorsData {
  isValid: boolean
  errorMessages: string[]
}

export const CalculatorPriceInputNumberValidator = (
  num: number,
  maxVal = 20000,
  minVal = 0
): ErrorsData => {
  const errorMessages: string[] = []

  if (num < minVal) {
    errorMessages.push(`Wartość musi być większa niż ${minVal}.`)
  }
  if (num > maxVal) {
    errorMessages.push(`Wartość musi być mniejsza niż ${maxVal}.`)
  }
  if (Number.isNaN(num)) {
    errorMessages.push('Wartość musi być liczbą.')
  }

  return {
    isValid: errorMessages.length === 0,
    errorMessages
  }
}

export const pricingsLimitByUserSubType = (
  subType: SubscriptionTypes
): number => {
  switch (subType) {
    case SubscriptionType.PREMIUM:
      return SAVED_PRICINGS_COUNT.PREMIUM
    case SubscriptionType.BASIC:
      return SAVED_PRICINGS_COUNT.BASIC
    case SubscriptionType.FREE:
      return SAVED_PRICINGS_COUNT.FREE
    default:
      return 0
  }
}
