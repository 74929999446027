import React from 'react'
import styles from './SectionSeparator.module.css'

interface Props extends React.ButtonHTMLAttributes<HTMLDivElement> {
  marginTop?: number
  marginBottom?: number
  width?: number
  height?: number
  separatorType?: 'blue' | 'pink'
}

export const SectionSeparator = ({
  separatorType,
  marginTop = 16,
  marginBottom = 16,
  height = 1,
  width = 90
}: Props): JSX.Element => (
  <div
    style={{
      margin: `${marginTop}px auto ${marginBottom}px`,
      width: `${width}%`,
      height: `${height}px`
    }}
    className={`${styles.Separator} ${
      separatorType ? styles[separatorType] : ''
    }`}
  />
)
