import { useEffect, useState } from 'react'
import styles from './Benefits.module.scss'
import { HeaderWithIcon } from '../../components/UI/headers/CustomHeader/CustomHeader'
import { FbGroups } from '../../components/Benefits/FbGroups/FbGroups'
import { ProtectedCodeList } from '../../components/Benefits/ProtectedCodeList/ProtectedCodeList'
import { Discount } from '../../const/benefits'
import { SectionSeparator } from '../../components/UI/sectionSeparator/sectionSeparator'
import { getDiscounts } from './api'

export const Benefits = (): JSX.Element => {
  const [discounts, setDiscounts] = useState<Discount[]>([])

  useEffect((): void => {
    getDiscounts().then(data => {
      if (data) {
        setDiscounts(data.data)
      }
    })
  }, [])

  return (
    <section className={styles.BenefitsContainer}>
      <HeaderWithIcon headerType='typical'>Lista benefitów</HeaderWithIcon>

      <SectionSeparator marginTop={20} marginBottom={20} />

      <FbGroups />

      <SectionSeparator marginTop={20} marginBottom={20} />

      <ProtectedCodeList discounts={discounts} />
    </section>
  )
}
