export function emailIsValid(email: string): boolean {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export const validateEmail = (email: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email.toLowerCase())
}

export const containsNumbersDotComma = (str: string): boolean =>
  /^[0-9,.]*$/.test(str)

export function isStringNumeric(str: string): boolean {
  return (
    !Number.isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !Number.isNaN(parseFloat(str))
  ) // ...and ensure strings of whitespace fail
}

export const noSpaceAllowed = (charCode: number): boolean => charCode !== 13

export const checkIfStringHasWhitespaces = (str: string): boolean =>
  /\s/.test(str)
