import { useState } from 'react'
import { scale} from "@cloudinary/url-gen/actions/resize";
import {AdvancedImage} from "@cloudinary/react";
import styles from './AboutCourse.module.scss'
import { HeaderWithIcon } from '../../UI/headers/CustomHeader/CustomHeader'
import { FcApprovalIcon } from '../../../Assets/ReactIcons'
import { ProductCard } from '../../UI/cards/ProductCard/ProductCard'
import { ParagraphDefault } from '../../UI/Paragraphs/ParagraphDefault/ParagraphDefault'
import { Product } from '../../../service/Product/ProductService'
import { getLimitedTextCharacters } from '../../../helpers/utils'
import {
  ModalPaymentProps,
  ProductPaymentModal
} from '../../UI/Modals/ProductPaymentModal/ProductPaymentModal'
import {useCloudinary} from "../../../hooks/useCloudinary";

interface Props {
  newestProduct: Product
  certificatePath: string
}

export const AboutCourse = ({
  newestProduct,
  certificatePath
}: Props): JSX.Element => {
  const [paymentModalData, setPaymentModalData] = useState<ModalPaymentProps>({
    isModalVisible: false,
    product: null
  })

  const onModalCloseHandler = () =>
    setPaymentModalData({ isModalVisible: false, product: null })

  const {getImageInstance} = useCloudinary();
  const aboutPhoto = getImageInstance(certificatePath)
      .resize(scale().height(300))
      .quality('auto')

  return (
    <div className={styles.AboutCourse_Container}>
      {/* Payment modal */}
      {paymentModalData.isModalVisible && (
        <ProductPaymentModal
          onModalClose={onModalCloseHandler}
          paymentData={paymentModalData}
        />
      )}

      <div>
        <HeaderWithIcon className={styles.Header}>
          Uzyskaj certyfikat po wykonanym kursie!
        </HeaderWithIcon>

        <div className={styles.Certificate_Image}>
          <div className={styles.Certificate_Image__CheckIcon}>
            {FcApprovalIcon}
          </div>
          <AdvancedImage cldImg={aboutPhoto} />
        </div>

        <ParagraphDefault>
          Aby otrzymać certyfikat napisz do nas na maila:{' '}
          <a href='mailto:kontakt@slodkaszkola.pl'>kontakt@slodkaszkola.pl</a>
        </ParagraphDefault>

        <div className={styles.Certificate_Image__PriceContainer}>
          <ParagraphDefault className={styles.Certificate_Image__Price}>
            PLN 30 / szt.
          </ParagraphDefault>

          <ParagraphDefault>
            Dla użytkowników z subskrypcją PREMIUM.
          </ParagraphDefault>
        </div>
        <div className={styles.Certificate_Image__PriceContainer}>
          <ParagraphDefault className={styles.Certificate_Image__Price}>
            PLN 50 / szt.
          </ParagraphDefault>
          <ParagraphDefault>
            Dla użytkowników z dostępem do pojedynczego kursu.
          </ParagraphDefault>
        </div>
      </div>

      <div className={styles.AboutCourse_Course}>
        <HeaderWithIcon className={styles.Header} headerType='typical'>
          Sprawdź nasz najnowszy kurs!
        </HeaderWithIcon>

        {newestProduct && (
          <div className={styles.Course_Card}>
            <div className={styles.Course_Card__Background} />
            <ProductCard
              key={`single-course-card-${newestProduct.id}`}
              productID={newestProduct.id}
              title={newestProduct.name}
              description={getLimitedTextCharacters(newestProduct.description)}
              author={newestProduct.author}
              subscriptionType={newestProduct.subscription}
              productViewPath={`/products/${newestProduct.type}/${newestProduct.id}`}
              courseDuration={newestProduct.courseDuration}
              cardImagePath={newestProduct.cardImagePath}
              invisibleProduct={newestProduct.invisibleProduct}
              productPrice={newestProduct.price}
              getPaymentModalData={setPaymentModalData}
            />
          </div>
        )}
      </div>
    </div>
  )
}
