import { useContext } from 'react'
import styles from './Logout.module.scss'
import { GlobalLoaderContext } from '../../../Context/GlobalLoader/GlobalLoader'
import { CookieStorageService } from '../../../service/CookieStorageService'
import {AxiosBackend} from "../../../service/HTTP/AxiosInstance";

const CookieService = new CookieStorageService('token')

export const Logout = (): JSX.Element => {
  const showLoader = useContext(GlobalLoaderContext)

  return (
    <div style={{ marginTop: 'auto' }}>
      <p
        role='presentation'
        className={`nav-menu-item ${styles.Logout}`}
        onClick={(): void => {
          showLoader(true, 'Wylogowuję Cie z aplikacji...')
            AxiosBackend.get('/user/removetoken').finally(() => {
            CookieService.removeItem()
            setTimeout((): void => {
              window.location.replace('/')
              showLoader(false)
            }, 2000)
          })
        }}
      >
        Wyloguj się
      </p>
    </div>
  )
}
