import React, { useContext } from 'react'

import { useNavigate } from 'react-router-dom'
import { UserReducerActions } from '../../../Context/User/UserReducer'
import { UserContext } from '../../../Context/User/UserContext'
import { ButtonByAdam } from '../../UI/buttons/ButtonByAdam/ButtonByAdam'
import styles from './BuyProductCardRow.module.scss'
import { ModalPaymentProps } from '../../UI/Modals/ProductPaymentModal/ProductPaymentModal'

interface Props {
  productPrice: number
  productID: string
  isBought: boolean
  getPaymentModalData: React.Dispatch<React.SetStateAction<ModalPaymentProps>>
}

export const BuyProductCardRow = ({
  productID,
  productPrice,
  isBought,
  getPaymentModalData
}: Props): JSX.Element => {
  const userContext: UserReducerActions = useContext(UserContext)
  const navigate = useNavigate()

  return (
    <section className={styles.ProductPaymentContainer}>
      {isBought ? (
        <div className={styles.InformationSection}>
          <h2>Masz dostęp do tego kursu</h2>
        </div>
      ) : (
        <div className={styles.RedirectsSection}>
          <ButtonByAdam
            btnType='blue'
            onClick={(): void => {
              getPaymentModalData({
                isModalVisible: true,
                product: { productID, userEmail: userContext.userDetails.email }
              })
            }}
          >
            Kup kurs za <strong>{`${productPrice}`} PLN</strong>
          </ButtonByAdam>

          <ButtonByAdam
            btnType='premium'
            onClick={(): void => navigate('/subskrypcje')}
          >
            Kup Subskrypcję
          </ButtonByAdam>
        </div>
      )}
    </section>
  )
}
