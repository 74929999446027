import { CakePriceCalcReducer } from './interfaces'

export const updateCalculatorData = (
  config: CakePriceCalcReducer
): CakePriceCalcReducer => ({ ...config })
export const updateCalculatorProductName = (
  productName: string,
  state: CakePriceCalcReducer
): CakePriceCalcReducer => ({
  ...state,
  pricingName: productName
})
