import { createContext, ReactNode, useCallback, useState } from 'react'
import { Loader } from '../../components/UI/Loader/Loader'
import { PLEASE_WAIT } from '../../const/consts'
import { ShowLoader } from '../../service/interface'

export const GlobalLoaderContext = createContext<ShowLoader>((): void => {
  // initial method for context
})

export const GlobalLoaderProvider = (props: {
  children: ReactNode
}): JSX.Element => {
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [text, setText] = useState<string>('')
  const { children: childComponents } = props

  const show = useCallback(
    (visibility: boolean, loaderText: string = PLEASE_WAIT): void => {
      if (loaderText) {
        setText(loaderText)
      }
      setIsVisible(visibility)
    },
    []
  )

  return (
    <GlobalLoaderContext.Provider value={show}>
      <Loader text={text} fullScreen show={isVisible} />
      {childComponents}
    </GlobalLoaderContext.Provider>
  )
}
