import React from 'react'
import styles from './ModalFancy.module.scss'

interface Props {
  children: React.ReactElement
}

export const ModalFancy = ({ children }: Props): JSX.Element => (
  <section className={styles.ModalContainer}>{children}</section>
)
