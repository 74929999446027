import { useContext, useReducer, useState } from 'react'
import styles from './ProductLesson.module.scss'
import { InputText } from '../../UI/inputs/InputText/InputText'
import { Select } from '../../UI/inputs/Select/Select'
import { getInitFieldValueByFieldType } from '../../../helpers/utils'
import { FieldGenerator } from './LessonFormFields/FieldGenerator'
import { fieldTypeLabels } from '../../../helpers/dictionaries'
import {
  initialLesson,
  ProductLessonActionNames,
  productLessonReducer,
  SingleLessonField
} from './ProductLessonReducer'
import { SourceTypeOptions } from '../../../const/Dictionaries/product'
import { ArrayUtilsByAdam } from '../../../helpers/array/MyArray'
import {
  LessonTextField,
  LessonImageField,
  LessonDotListField,
  Lesson
} from '../../../service/Product/lessonTypes'
import { HeaderWithIcon } from '../../UI/headers/CustomHeader/CustomHeader'
import {
  IoLogoYoutubeIcon,
  BsTrashIcon,
  FiChevronDownIcon,
  FiChevronUpIcon
} from '../../../Assets/ReactIcons'
import { UpAndDownIcon } from '../../UI/UpAndDownIcon/UpAndDownIcon'
import { SectionSeparator } from '../../UI/sectionSeparator/sectionSeparator'
import { ButtonByAdam } from '../../UI/buttons/ButtonByAdam/ButtonByAdam'
import { GlobalNotificationContext } from '../../../Context/GlobalNotification/GlobalNotificationProvider'
import { Loader } from '../../UI/Loader/Loader'
import { getLessonErrors } from './updateLesson_validator'
import { ErrorListMessage } from '../../UI/MessageComponents/ErrorListMessage/ErrorListMessage'
import { NotificationType } from '../../../Context/GlobalNotification/Types'

interface Props {
  updateLessonHandler: (lessonToUpdate: Lesson) => void
  removeLessonHandler: () => void
  lesson: Lesson
  lessonNumber: number
  lessonsAmount: number
  updateProductFormDataHandler: (
    currentIndex: number,
    nextIndex: number
  ) => void
}

export const ProductLessonForm = ({
  lesson,
  updateLessonHandler,
  removeLessonHandler,
  lessonNumber,
  updateProductFormDataHandler,
  lessonsAmount
}: Props): JSX.Element => {
  const [productLesson, dispatch] = useReducer(productLessonReducer, {
    ...initialLesson,
    data: { ...lesson }
  })
  const [sectionFields, setSectionFields] = useState<
    (LessonTextField | LessonImageField | LessonDotListField)[]
  >([...lesson.sectionFields])
  const [fieldTypeToAdd, setFieldTypeToAdd] = useState<
    'header' | 'paragraph' | 'image' | 'dotList'
  >('header')
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const updateProductLessonMainData = (data: SingleLessonField): void =>
    dispatch({
      type: ProductLessonActionNames.UPDATE_PRODUCT_LESSON_MAIN_DATA,
      data
    })
  const [triedSave, setTriedToSave] = useState<boolean>(false)
  const showNotification = useContext(GlobalNotificationContext)

  const arrayUtils = new ArrayUtilsByAdam(sectionFields.slice())
  const currentLessonIndex = lessonNumber - 1

  const errors: string[] = getLessonErrors(
    productLesson.data.sectionName,
    productLesson.data.video,
    sectionFields
  )

  const formDisabled = triedSave && errors.length > 0
  return (
    <section className={styles.ProductLessonContainer}>
      {showLoader && (
        <div className={styles.LoaderContainer}>
          <Loader text='Lekcja się aktualizuje...' />
        </div>
      )}
      <div className={styles.TitleContainer}>
        <HeaderWithIcon reactIcon={IoLogoYoutubeIcon} className={styles.Header}>
          {`Lekcja ${lessonNumber} - ${lesson.sectionName}`}
        </HeaderWithIcon>
        <div className={styles.IconsContainer}>
          <div
            role='presentation'
            onClick={(): void => removeLessonHandler()}
            className={styles.TrashIcon}
          >
            {BsTrashIcon}
          </div>

          <UpAndDownIcon
            upHandler={(): void => {
              if (currentLessonIndex > 0) {
                updateProductFormDataHandler(
                  currentLessonIndex,
                  currentLessonIndex - 1
                )
              }
            }}
            downHandler={(): void => {
              if (currentLessonIndex < lessonsAmount - 1)
                updateProductFormDataHandler(
                  currentLessonIndex,
                  currentLessonIndex + 1
                )
            }}
          />
        </div>
        <div
          role='presentation'
          style={{ cursor: 'pointer', marginLeft: 'auto' }}
          onClick={(): void => setIsVisible(!isVisible)}
        >
          {isVisible ? FiChevronUpIcon : FiChevronDownIcon}
        </div>
      </div>

      {isVisible && (
        <section className={styles.LessonAllFieldSection}>
          <InputText
            label='Nazwa Lekcji'
            placeholder='Uzupełnij nazwę lekcji'
            value={productLesson.data.sectionName}
            onBlurHandler={(value: string): void =>
              updateProductLessonMainData({
                data: { ...productLesson.data },
                fieldName: 'sectionName',
                fieldValue: value
              })
            }
          />
          <section>
            <InputText
              label='ID filmu - vimeo'
              placeholder='Wpisz ID Filmu'
              value={productLesson.data.video.movieId}
              onBlurHandler={(value: string): void =>
                updateProductLessonMainData({
                  data: { ...productLesson.data },
                  fieldName: 'movieId',
                  fieldValue: value
                })
              }
            />

            <InputText
              label='Zajawka lekcji'
              placeholder='Wpisz ścieżkę do zajawki dla lekcji'
              value={productLesson.data.video.placeholderImage}
              onBlurHandler={(value: string): void =>
                updateProductLessonMainData({
                  data: { ...productLesson.data },
                  fieldName: 'placeholderImage',
                  fieldValue: value
                })
              }
              dataRequired
            />

            <Select
              label='Hosting video'
              options={SourceTypeOptions}
              placeholder='Wybierz hosting filmu'
              onChange={(value): void =>
                updateProductLessonMainData({
                  data: { ...productLesson.data },
                  fieldName: 'sourceType',
                  fieldValue: value as string
                })
              }
              value={productLesson.data.video.sourceType}
            />

            <div className={styles.LessonFieldSettings}>
              <div style={{ width: '150px', alignSelf: 'center' }}>
                <ButtonByAdam
                  onClick={(): void => {
                    const sectionFieldsCopy = sectionFields.slice()
                    sectionFieldsCopy.push(
                      getInitFieldValueByFieldType(fieldTypeToAdd)
                    )
                    setSectionFields(sectionFieldsCopy)
                  }}
                >
                  Dodaj pole
                </ButtonByAdam>
              </div>

              <div style={{ alignSelf: 'center' }}>
                <Select
                  label='Rodzaj pola'
                  value={fieldTypeToAdd}
                  placeholder='Wybierz rodzaj pola'
                  options={fieldTypeLabels}
                  onChange={(val): void =>
                    setFieldTypeToAdd(
                      val as 'header' | 'paragraph' | 'image' | 'dotList'
                    )
                  }
                />
              </div>
            </div>

            <section>
              {sectionFields.map(
                (
                  fieldItem:
                    | LessonTextField
                    | LessonImageField
                    | LessonDotListField,
                  fieldIndex: number
                ): JSX.Element => (
                  <section key={`${lessonNumber}-${fieldIndex + 1}`}>
                    <FieldGenerator
                      fieldNumber={fieldIndex + 1}
                      fieldSection={sectionFields[fieldIndex]}
                      updateFieldHandler={(
                        fieldToUpdate:
                          | LessonTextField
                          | LessonImageField
                          | LessonDotListField
                      ): void => {
                        const sectionFieldsCopy = sectionFields.slice()
                        sectionFieldsCopy[fieldIndex] = { ...fieldToUpdate }
                        setSectionFields(sectionFieldsCopy)
                      }}
                      removeFieldHandler={(): void => {
                        setSectionFields(
                          sectionFields.filter(
                            (
                              lessonToFilter:
                                | LessonTextField
                                | LessonImageField
                                | LessonDotListField,
                              idx: number
                            ): boolean => idx !== fieldIndex
                          )
                        )
                      }}
                      swapSectionUp={(): void => {
                        if (fieldIndex > 0) {
                          const fieldsOrderUpdated = arrayUtils.swapArrayItems(
                            fieldIndex,
                            fieldIndex - 1
                          )
                          setSectionFields(fieldsOrderUpdated)
                        }
                      }}
                      swapSectionDown={(): void => {
                        if (fieldIndex < sectionFields.length - 1) {
                          const fieldsOrderUpdated = arrayUtils.swapArrayItems(
                            fieldIndex,
                            fieldIndex + 1
                          )
                          setSectionFields(fieldsOrderUpdated)
                        }
                      }}
                    />
                  </section>
                )
              )}
            </section>

            {formDisabled && (
              <div style={{ fontSize: '16px', marginBottom: '20px' }}>
                <ErrorListMessage title='Popraw błędy' errorList={errors} />
              </div>
            )}

            <div style={{ width: '150px', alignSelf: 'center' }}>
              <ButtonByAdam
                disabled={formDisabled}
                btnType='green'
                onClick={(): void => {
                  if (!triedSave) setTriedToSave(true)
                  if (!showLoader && errors.length === 0) {
                    setShowLoader(true)
                    showNotification(
                      NotificationType.INFO,
                      'Lekcja została zaktualizowana. Pamiętaj zapisać Produkt!'
                    )
                    updateLessonHandler({
                      ...productLesson.data,
                      sectionFields: sectionFields.slice()
                    })
                    setTimeout((): void => {
                      setShowLoader(false)
                    }, 1000)
                  }
                }}
              >
                Zapisz lekcję
              </ButtonByAdam>
            </div>
          </section>
        </section>
      )}

      <SectionSeparator />
    </section>
  )
}
