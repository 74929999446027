import { Link } from 'react-router-dom'
import styles from './HowToStart.module.scss'
import { HeaderWithIcon } from '../../UI/headers/CustomHeader/CustomHeader'
import { ParagraphDefault } from '../../UI/Paragraphs/ParagraphDefault/ParagraphDefault'

export const HowToStart = (): JSX.Element => (
  <div className={styles.HowToStart_Container}>
    <HeaderWithIcon className={styles.Header}>Jak zacząć?</HeaderWithIcon>

    <ParagraphDefault size='bigger' style={{ marginBottom: '10px' }}>
      Dostęp do kursu lub innego produktu możesz uzyskać na 2 sposoby:
    </ParagraphDefault>

    <div className={styles.HowToStart_Container__Links}>
      <Link id='hello-view-subscriptions' to='/subskrypcje'>
        <ParagraphDefault size='big'>
          1. Wybór odpowiedniej subskrypcji.
        </ParagraphDefault>
      </Link>
      <Link id='hello-view-products' to='/products'>
        <ParagraphDefault size='big'>
          2. Zakup pojedynczego kursu
        </ParagraphDefault>
      </Link>
    </div>

    <ParagraphDefault size='bigger'>
      Aby dokonać zakupu wystarczy przejść do zakładki{' '}
      <Link id='hello-view-products' to='/products'>
        {' '}
        Produkty
      </Link>
      . Wybierz kurs, który Cię interesuje. Jeżeli nie masz jeszcze dostępu do
      kursu, nad filmikiem wyświetli się okienko z możliwością wyboru
      odpowiedniej metody płatności. Po dokonaniu płatności zyskasz
      natychmiastowy dostęp do danego produktu.
    </ParagraphDefault>
  </div>
)
