import { useContext, useEffect, useState } from 'react'
import styles from './Authenticate.module.scss'
import { InputText } from '../../../components/UI/inputs/InputText/InputText'
import { DefaultHeader } from '../../../components/UI/headers/DefaultHeader/DefaultHeader'
import { ButtonByAdam } from '../../../components/UI/buttons/ButtonByAdam/ButtonByAdam'
import { GlobalNotificationContext } from '../../../Context/GlobalNotification/GlobalNotificationProvider'
import { GlobalLoaderContext } from '../../../Context/GlobalLoader/GlobalLoader'
import { NotificationType } from '../../../Context/GlobalNotification/Types'
import { userAuthenticate } from '../../../service/User/api'
import { LoginPageType, LoginPageTypes } from '../LoginPageTypes'
import { getAuthenticateErrors } from './authenticate_validator'
import { ErrorListMessage } from '../../../components/UI/MessageComponents/ErrorListMessage/ErrorListMessage'

interface Props {
  changeFormHandler: (formType: LoginPageTypes) => void
}

export const Authenticate = ({ changeFormHandler }: Props): JSX.Element => {
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [triedToSignUp, setTriedToSignup] = useState(false)

  useEffect(() => {
    const onClickEnterHandler = (event: KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        document.getElementById('loginButton')?.click()
      }
    }
    document.addEventListener('keydown', onClickEnterHandler)
    return () => {
      document.removeEventListener('keydown', onClickEnterHandler)
    }
  }, [])

  const showNotification = useContext(GlobalNotificationContext)
  const showLoader = useContext(GlobalLoaderContext)

  const errors: string[] = getAuthenticateErrors({
    username,
    password
  })

  const formDisabled = triedToSignUp && errors.length > 0

  const signInHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (!triedToSignUp) setTriedToSignup(true)
    if (errors.length === 0) {
      showLoader(true)
      userAuthenticate({
        username: username.toLocaleLowerCase(),
        password
      })
        .then((): void => {
          showNotification(
            NotificationType.INFO,
            'Logowanie przebiegło pomyślnie'
          )
          showLoader(false)
        })
        .finally(() => {
          showLoader(false)
        })
    }
  }

  return (
    <section className={styles.FormContainer}>
      <DefaultHeader>Logowanie</DefaultHeader>

      <form>
        <InputText
          label='Nazwa użytkownika lub email'
          placeholder='Nazwa użytkownika lub email'
          onChangeHandler={(val: string): void => setUsername(val)}
          value={username}
          autoComplete='username'
          name='username'
          id='username'
        />

        <InputText
          label='Hasło'
          placeholder='Hasło'
          type='password'
          onChangeHandler={(val: string): void => setPassword(val)}
          value={password}
          autoComplete='current-password'
          name='password'
          id='password'
        />

        {formDisabled && (
          <ErrorListMessage title='Popraw błędy' errorList={errors} />
        )}

        <ButtonByAdam
          id='loginButton'
          type='submit'
          className={styles.FormContainerButton}
          disabled={formDisabled}
          onClick={signInHandler}
        >
          Zaloguj
        </ButtonByAdam>
      </form>

      <div className={styles.AdditionalInfoContainer}>
        <p>
          {'Jeżeli nie masz jeszcze konta, '}
          <span
            aria-hidden='true'
            tabIndex={0}
            role='button'
            onClick={(): void => changeFormHandler(LoginPageType.SIGNUP)}
            className={styles.Link}
          >
            Zarejestruj się
          </span>
        </p>
        <p>
          Nie pamiętasz hasła do swojego konta?
          <span
            aria-hidden='true'
            tabIndex={0}
            role='button'
            onClick={(): void => changeFormHandler(LoginPageType.RESTORE)}
            className={styles.Link}
          >
            {' Kliknij tutaj'}
          </span>
        </p>
      </div>
    </section>
  )
}
