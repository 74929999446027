import { useContext } from 'react'
import styles from '../SubscriptionSection.module.scss'
import { ButtonByAdam } from '../../../UI/buttons/ButtonByAdam/ButtonByAdam'
import {
  cancelCurrentSubscription,
  renewCurrentSubscription,
  deleteCurrentSubscription,
  getUserSubscriptionData
} from '../../../../service/Stripe/customer'
import { SectionSeparator } from '../../../UI/sectionSeparator/sectionSeparator'
import { SubscriptionData, User } from '../../../../service/User/UserService'
import {
  convertMongoDateToISO,
  getFormattedDate
} from '../../../../helpers/date'
import { GlobalNotificationContext } from '../../../../Context/GlobalNotification/GlobalNotificationProvider'
import { GlobalLoaderContext } from '../../../../Context/GlobalLoader/GlobalLoader'
import { ParagraphDefault } from '../../../UI/Paragraphs/ParagraphDefault/ParagraphDefault'
import { NotificationType } from '../../../../Context/GlobalNotification/Types'

interface Props {
  user: User
  subscriptionData: SubscriptionData
}

export const SingleSubscriptionPanel = ({
  user,
  subscriptionData
}: Props): JSX.Element => {
  const showNotification = useContext(GlobalNotificationContext)
  const showLoader = useContext(GlobalLoaderContext)

  const isSubscriptionStartDate =
    subscriptionData && subscriptionData.currentPeriodStart
      ? getFormattedDate(
          convertMongoDateToISO(new Date(subscriptionData.currentPeriodStart))
        )
      : 'Brak'
  const isSubscriptionExpirationDate =
    subscriptionData && subscriptionData.currentPeriodEnd
      ? getFormattedDate(
          convertMongoDateToISO(new Date(subscriptionData.currentPeriodEnd))
        )
      : 'Brak'

  return (
    <div className={styles.ContentWrapper}>
      <div className={styles.AlertNotificationRow}>
        {subscriptionData.canceledAt && (
          <p>
            {' '}
            Twoja subskrypcja nie będzie przedłużona. Masz jeszcze szansę to
            zmienić!
          </p>
        )}
      </div>
      <p className={styles.SingleRow}>
        <span style={{ color: 'red' }}>
          {subscriptionData.status === 'incomplete'
            ? `Subskrypcja nieaktywna ( nie pobieramy opłaty ).
              Prawdopodobnie nie została opłacona poprzez problem z kartą.
              Możesz spróbować opłacić ją ponownie klikając w przycisk poniżej.`
            : ''}
        </span>
      </p>
      <p className={styles.SingleRow}>
          <span>Subskrypcja cykliczna</span>
      </p>
        <p className={styles.SingleRow}>
            <span>Poziom: </span>
            <span
                className={`
                  ${styles.SecondarySubscriptionType}
                  ${subscriptionData.subType === 'free' ? 'free-color' : ''}
                  ${subscriptionData.subType === 'basic' ? 'basic-color' : ''}
                  ${subscriptionData.subType === 'premium' ? 'premium-color' : ''}
                  `}
            > {subscriptionData.subType}</span>
        </p>

      <p className={styles.SingleRow}>
        <span>Rozpoczęcie subskrypcji</span>

        {`: ${isSubscriptionStartDate}`}
      </p>

      <p className={styles.SingleRow}>
        <span>
          {subscriptionData.canceledAt
            ? 'Subskrypcja wygasa'
            : 'Subskrypcja odnawia się'}
        </span>
        <span
          className={subscriptionData.canceledAt ? 'negative' : 'positive'}
        >{`: ${isSubscriptionExpirationDate}`}</span>
      </p>
      <div className={styles.ButtonContainer}>
        {subscriptionData.status === 'active' && subscriptionData.canceledAt ? (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <ParagraphDefault>
              Twoja subskrypcja niedługo wygaśnie. Możesz to zmienić klikając w
              poniższy przycisk. Opłata zostanie automatycznie pobrana dopiero
              po zakończeniu czasu trwania aktualnej subskrypcji. Miłego
              korzystania ze Słodkiej Szkoły!
            </ParagraphDefault>
            <ButtonByAdam
              btnType='green'
              onClick={(): void => {
                if (user.subscriptionData && subscriptionData.subscriptionId) {
                  showLoader(true)
                  renewCurrentSubscription(subscriptionData.subscriptionId)
                    .then(status => {
                      if (status === 200) {
                        showNotification(
                          NotificationType.INFO,
                          'Twoja subskrypcja została przywrócona'
                        )
                        getUserSubscriptionData(user.stripeCustomerId)
                          .then((): void => {
                            showLoader(false)
                          })
                          .catch(() => showLoader(false))
                      }
                      showLoader(false)
                    })
                    .catch(() => {
                      showLoader(false)
                    })
                }
              }}
            >
              Kontynuuj subskrypcję
            </ButtonByAdam>
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <ParagraphDefault>
              Kliknij jeżeli chcesz zrezygnować z subskrybowania Słodkiej
              Szkoły. Kolejne opłaty nie zostaną pobrane. Dostęp do dodatkowych
              kursów i narzędzi stracisz po zakończeniu okresu aktualnej
              subskrypcji.
            </ParagraphDefault>
            <ButtonByAdam
              btnType='red'
              onClick={(): void => {
                if (user.subscriptionData && subscriptionData.subscriptionId) {
                  showLoader(true)
                  cancelCurrentSubscription(subscriptionData.subscriptionId)
                    .then(status => {
                      if (status === 200) {
                        showNotification(
                          NotificationType.INFO,
                          'Subskrypcja została wstrzymana pomyślnie'
                        )
                        getUserSubscriptionData(user.stripeCustomerId)
                          .then((): void => {
                            showLoader(false)
                          })
                          .catch(() => showLoader(false))
                      } else {
                        showLoader(false)
                      }
                    })
                    .catch(() => {
                      showLoader(false)
                    })
                }
              }}
            >
              Wstrzymaj subskrypcję
            </ButtonByAdam>
          </div>
        )}

        {subscriptionData.status !== 'active' && (
          <ButtonByAdam
            btnType='green'
            onClick={(): void => {
              if (user.subscriptionData && subscriptionData.subscriptionId) {
                showLoader(true)
                deleteCurrentSubscription(subscriptionData.subscriptionId)
                  .then(status => {
                    if (status === 200) {
                      showNotification(
                        NotificationType.INFO,
                        'Twoja subskrypcja została zakończona pomyślnie'
                      )
                      getUserSubscriptionData(user.stripeCustomerId)
                        .then((): void => {
                          showLoader(false)
                        })
                        .catch(() => showLoader(false))
                    }
                    showLoader(false)
                  })
                  .catch(() => {
                    showLoader(false)
                  })
              }
            }}
          >
            Zakończ subskrypcję
          </ButtonByAdam>
        )}
      </div>

      <SectionSeparator />
    </div>
  )
}
