import { updateDeliveryTime } from './Reducer/deliveryTimeActions'
import {
  addGroceryProduct,
  deleteGroceryProduct,
  updateGroceryProduct
} from './Reducer/groceryProductsActions'
import {
  Action,
  ActionNames,
  CakePriceCalcReducer,
  UpdateFixedCostsConfig,
  UpdateGroceryConfig
} from './Reducer/interfaces'
import { updateWorkingTime } from './Reducer/workingTimeActions'
import {
  addAdditionalSpendings,
  deleteAdditionalSpendings,
  updateAdditionalSpendings,
  UpdateAdditionalSpendingsConfig
} from './Reducer/additionalSpendingActions'
import {
  updateCalculatorData,
  updateCalculatorProductName
} from './Reducer/othersActions'
import {
  addCost,
  deleteCost,
  updateCost,
  updateFixedSweetCounter
} from './Reducer/fixedCostsActions'

export const cakePriceReducer = (
  state: CakePriceCalcReducer,
  action: Action
): CakePriceCalcReducer => {
  switch (action.type) {
    case ActionNames.UPDATE_GROCERY_PRODUCT:
      return updateGroceryProduct(action.data as UpdateGroceryConfig, state)
    case ActionNames.ADD_GROCERY_PRODUCT:
      return addGroceryProduct(state)
    case ActionNames.REMOVE_GROCERY_PRODUCT:
      return deleteGroceryProduct(action.id as string, state)

    case ActionNames.UPDATE_ADDITIONAL_SPENDINGS:
      return updateAdditionalSpendings(
        action.data as UpdateAdditionalSpendingsConfig,
        state
      )
    case ActionNames.ADD_ADDITIONAL_SPENDINGS:
      return addAdditionalSpendings(state)
    case ActionNames.REMOVE_ADDITIONAL_SPENDINGS:
      return deleteAdditionalSpendings(action.id as string, state)

    case ActionNames.UPDATE_FIXED_COST:
      return updateCost(action.data as UpdateFixedCostsConfig, state)
    case ActionNames.ADD_FIXED_COST:
      return addCost(state)
    case ActionNames.REMOVE_FIXED_COST:
      return deleteCost(action.id as string, state)
    case ActionNames.UPDATE_FIXED_SWEET_COUNTER:
      return updateFixedSweetCounter(action.value as number, state)

    case ActionNames.UPDATE_WORKING_TIME:
      return updateWorkingTime(action.data, state)

    case ActionNames.UPDATE_DELIVERY_TIME:
      return updateDeliveryTime(action.data, state)

    case ActionNames.UPDATE_CALCULATOR_DATA:
      return updateCalculatorData(action.data)

    case ActionNames.UPDATE_CALCULATOR_PRODUCT_NAME:
      return updateCalculatorProductName(action.data, state)
    default:
      return state
  }
}
