import { Item } from 'semantic-ui-react'
import styles from './ProtectedCodeList.module.scss'
import { ProtectedCodeListItem } from './ProtectedCodeListItem/ProtectedCodeListItem'
import { Discount } from '../../../const/benefits'

interface Props {
  discounts: Discount[]
}

export const ProtectedCodeList = ({ discounts }: Props): JSX.Element => (
  <section className={styles.Container}>
    <Item.Group>
      {discounts.map(
        (discount): JSX.Element => (
          <ProtectedCodeListItem
            key={discount.companyName}
            discountData={discount}
          />
        )
      )}
    </Item.Group>
  </section>
)
