import { Dispatch } from 'react'
import { Product } from '../../service/Product/ProductService'
import {
  ProductDataReducerActions,
  ProductOperations,
  ProductReducerActions
} from './ProductDataReducer'
import { UserReducerActions } from '../User/UserReducer'

export const updateProductsData = (
  data: Product[],
  dispatch: Dispatch<ProductDataReducerActions>
): void =>
  dispatch({
    type: ProductReducerActions.UPDATE_PRODUCTS_DATA,
    data
  })
export const updateProduct = (
  data: ProductOperations,
  dispatch: Dispatch<ProductDataReducerActions>
): void => dispatch({ type: ProductReducerActions.UPDATE_PRODUCT, data })
export const getProductsByType = (
  type: string,
  products: Product[]
): Product[] =>
  products.filter(
    (singleProduct: Product): boolean => singleProduct.type === type
  )
export const getProductsFilteredByCategory = (
  category: string,
  userContext: UserReducerActions,
  products: Product[]
): Product[] => {
  if (category === 'bought') {
    return products.filter((singleProduct: Product): boolean =>
      userContext.checkIfAvailableToWatch(singleProduct)
    )
  }
  return products.filter(
    (singleProduct: Product): boolean => singleProduct.type === category
  )
}
