import React from 'react'
import styles from './ParagraphDefault.module.scss'

export interface Props extends React.HTMLAttributes<HTMLParagraphElement> {
  size?: 'big' | 'bigger' | 'small' | 'smaller'
}

export const ParagraphDefault = ({ size, ...props }: Props): JSX.Element => (
  <p
    className={`${props.className || styles.DefaultParagraph} ${
      size ? styles[size] : ''
    }`}
    {...props}
  >
    {props.children}
  </p>
)
