import { AxiosResponse } from 'axios'
import {
  CakePriceCalcReducer,
  KitchenMeasure
} from '../CakePriceCalculatorContext/Reducer/interfaces'
import { PriceMainInfo } from '../CakePriceCalculatorContext/interfaces'
import {AxiosBackend} from "../../../../service/HTTP/AxiosInstance";

export const getAllPricings = (): Promise<AxiosResponse<PriceMainInfo[]>> =>
    AxiosBackend.get('/pricings')
export const getPricing = (
  pricingId: string
): Promise<AxiosResponse<CakePriceCalcReducer>> =>
    AxiosBackend.get(`/pricing?pricingId=${pricingId}`)
export const storeNewPricing = (
  pricing: CakePriceCalcReducer
): Promise<AxiosResponse<CakePriceCalcReducer>> =>
    AxiosBackend.post('/pricing/add', pricing)
export const updatePricing = (
  pricing: CakePriceCalcReducer
): Promise<AxiosResponse<CakePriceCalcReducer>> =>
    AxiosBackend.post('/pricing/update', pricing)
export const RemovePricing = (
  pricingId: string
): Promise<AxiosResponse<void>> =>
    AxiosBackend.delete(`/pricing?pricingID=${pricingId}`)

export const kitchenMeasureOptions = [
  { value: KitchenMeasure.KG, label: 'Kilogram' },
  { value: KitchenMeasure.G, label: 'Gramy' },
  { value: KitchenMeasure.SZT, label: 'Sztuki' }
]

export const cakePriceReducerSuperInitial: CakePriceCalcReducer = {
  id: '',
  pricingName: 'Nowa wycena',
  quantity: 1,
  groceryProducts: [
    {
      id: 'groceryProducts-1',
      quantityBought: 1,
      measure: KitchenMeasure.KG,
      name: 'Cukier',
      price: 5,
      quantityUsed: 2
    }
  ],
  additionalSpending: [
    {
      id: 'additionalSpending-1',
      quantityBought: 1,
      measure: KitchenMeasure.SZT,
      name: 'Patera',
      price: 20,
      quantityUsed: 2
    }
  ],
  fixedCosts: {
    costs: [{ id: 'fixedCost-1', price: 10, name: 'Prąd' }],
    sweetsCount: 10
  },
  workingTime: {
    id: 'working-time-1',
    quantity: 1,
    price: 20
  },
  deliveryTime: {
    id: 'delivery-time-1',
    pricePerKilometer: 1,
    distance: 20
  }
}

export const cakePriceReducerInitial: CakePriceCalcReducer = Object.freeze({
  id: '',
  pricingName: 'Nowa Wycena',
  quantity: 1,
  groceryProducts: [
    {
      id: 'groceryProducts-1',
      quantityBought: 1,
      measure: KitchenMeasure.KG,
      name: 'Cukier',
      price: 10,
      quantityUsed: 2
    }
  ],
  additionalSpending: [
    {
      id: 'additionalSpending-1',
      quantityBought: 1,
      measure: KitchenMeasure.SZT,
      name: 'Patera',
      price: 10,
      quantityUsed: 2
    }
  ],
  fixedCosts: {
    costs: [{ id: 'fixedCosts-1', price: 10, name: 'Prąd' }],
    sweetsCount: 10
  },
  workingTime: {
    id: 'working-time-1',
    quantity: 1,
    price: 20
  },
  deliveryTime: {
    id: 'delivery-time-1',
    pricePerKilometer: 1,
    distance: 20
  }
})
