import styles from './StandardView.module.scss'
import costsRowStyles from '../FixedCostsSectionRow.module.scss'
import {
  FixedCostRow,
  UpdateFixedCostsConfig
} from '../../../CakePriceCalculatorContext/Reducer/interfaces'
import { InputNumber } from '../../../../../UI/inputs/InputNumber/InputNumber'
import { CalculatorPriceInputNumberValidator } from '../../../utils'

interface Props {
  cost: FixedCostRow
  costIndex: number
  onCostHandler: (data: UpdateFixedCostsConfig) => void
  removeCost: (id: string) => void
  changeView: () => void
}

export const StandardView = ({
  cost,
  costIndex,
  onCostHandler,
  removeCost,
  changeView
}: Props): JSX.Element => (
  <div className={styles.StandardViewContainer}>
    <div className={`${styles.SectionContainer} ${styles.NameSection}`}>
      <p className={costsRowStyles.IndexStandard}>{`${costIndex + 1}.`}</p>
      <div className={styles.ProductName}>{cost.name}</div>
      <div className={styles.InputContainer}>
        <InputNumber
          type='number'
          value={cost.price}
          placeholder='Cena usługi'
          onChangeHandler={(value: number): void =>
            onCostHandler({
              id: cost.id,
              inputName: 'price',
              value
            })
          }
          validateFnc={CalculatorPriceInputNumberValidator}
          label='zł'
        />
      </div>
    </div>
    <div className={styles.SectionContainer}>
      <div className={costsRowStyles.Price}>{`${cost.price} zł`}</div>
      <div
        role='presentation'
        onClick={changeView}
        className={costsRowStyles.EditText}
      >
        edytuj
      </div>
      <div
        role='presentation'
        onClick={(): void => removeCost(cost.id)}
        className={costsRowStyles.RemoveText}
      >
        usuń
      </div>
    </div>
  </div>
)
