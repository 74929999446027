export interface CalculatorOperations {
  fieldName: string
  fieldValue: string
}

const PI = 3.15
const K = 0.55 // ilość kremu na 1 cm2

export const getSquareCakePortionsMin = (h: number, a: number): number =>
  (a * a * h) / 240
export const getSquareCakePortionsMax = (h: number, a: number): number =>
  (a * a * h) / 200

export const getRectangleCakePortionsMin = (
  h: number,
  a: number,
  b: number
): number => (a * b * h) / 240
export const getRectangleCakePortionsMax = (
  h: number,
  a: number,
  b: number
): number => (a * b * h) / 200

export const getRoundedCakePortionsMin = (h: number, d: number): number =>
  (PI * (d * 0.5) ** 2 * h) / 240
export const getRoundedCakePortionsMax = (h: number, d: number): number =>
  (PI * (d * 0.5) ** 2 * h) / 200

export const frostingRoundedCakeAmount = (h: number, d: number): number =>
  (PI * (d * 0.5) ** 2 + 2 * PI * (0.5 * d) * h) * K
export const frostingRectangleCakeAmount = (
  h: number,
  a: number,
  b: number
): number => (a * h * 2 + b * h * 2 + a * b) * K
export const frostingSquareCakeAmount = (h: number, a: number): number =>
  (a * h * 2 * 2 + a ** 2) * K

export const getIngredientsByFrosting = (
  frosting: string,
  frostingAmount: number
): string[] => {
  if (frosting === 'Classic butter cream') {
    return [
      `Masło - ${Math.round(frostingAmount * 0.625)}g`,
      `Cukier puder - ${Math.round(frostingAmount * 0.375)}g`
    ]
  }
  if (frosting === 'Cream with condensed milk') {
    return [
      `Masło - ${Math.round(frostingAmount * 0.5)}g`,
      `Mleko skondensowane - ${Math.round(frostingAmount * 0.5)}g`
    ]
  }
  if (frosting === 'Cream with powdered milk') {
    return [
      `Masło - ${Math.round(frostingAmount * 0.88)}g`,
      `Mleko w proszku - ${Math.round(frostingAmount * 0.22)}g`,
      `Mleko płynne - ${Math.round(frostingAmount * 0.22)}g`,
      `Cukier - ${Math.round(frostingAmount * 0.12)}g`
    ]
  }
  return []
}

export const roundedSpongeCakeField = (diameter: number): number =>
  2 * PI * (diameter * 0.5) ** 2
export const rectangleSpongeCakeField = (
  length: number,
  width: number
): number => length * width
export const squareSpongeCakeField = (sideLength: number): number =>
  sideLength * sideLength

export const getIngredientsBySpongeCakeType = (
  spongeCakeType: string,
  spongeCakeField: number
): string[] => {
  if (spongeCakeType === 'Light sponge cake') {
    return [
      `Jajka - ${Math.round(spongeCakeField * 0.01)} szt.`,
      `Cukier - ${Math.round(spongeCakeField * 0.28)}g`,
      `Mąka pszenna tortowa - ${Math.round(spongeCakeField * 0.34)}g`,
      `Skrobia ziemniaczana - ${Math.round(spongeCakeField * 0.11)}g`
    ]
  }

  if (spongeCakeType === 'Cocoa sponge cage') {
    return [
      `Jajka - ${Math.round(spongeCakeField * 0.01)} szt.`,
      `Cukier - ${Math.round(spongeCakeField * 0.34)}g`,
      `Mąka pszenna tortowa - ${Math.round(spongeCakeField * 0.28)}g`,
      `Kakao - ${Math.round(spongeCakeField * 0.11)}g`
    ]
  }

  if (spongeCakeType === 'Brownie sponge cake') {
    return [
      `Jajka - ${Math.round(spongeCakeField * 0.01)} szt.`,
      `Cukier - ${Math.round(spongeCakeField * 0.31)}g`,
      `Mąka pszenna tortowa - ${Math.round(spongeCakeField * 0.23)}g`,
      `Kakao - ${Math.round(spongeCakeField * 0.11)}g`,
      `Proszek do pieczenia - ${(spongeCakeField * 0.00198).toFixed(
        1
      )} łyżeczek`,
      `Olej - ${Math.round(spongeCakeField * 0.28)}g`
    ]
  }
  return []
}
