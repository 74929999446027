import { Lesson } from './lessonTypes'
import { LessonDto } from './lessonTypesDto'

export enum DifficultyType {
  EASY = 'easy',
  MEDIUM = 'medium',
  HARD = 'hard'
}

export type Difficulty =
  | DifficultyType.EASY
  | DifficultyType.MEDIUM
  | DifficultyType.HARD

export enum SubscriptionType {
  FREE = 'free',
  BASIC = 'basic',
  PREMIUM = 'premium'
}

export type SubscriptionTypes =
  | SubscriptionType.FREE
  | SubscriptionType.BASIC
  | SubscriptionType.PREMIUM

export interface Product {
  id: string
  type: string
  price: number
  cardImagePath: string
  difficulty: Difficulty
  tags: string[]
  subscription: SubscriptionTypes
  name: string
  author: string
  lastUpdate: string
  courseDuration: string
  description: string
  invisibleProduct: boolean
  lessons: Lesson[]
}

export interface ProductDto {
  _id: string
  type: string
  price: number
  cardImagePath: string
  difficulty: Difficulty
  tags: string[]
  subscription: SubscriptionTypes
  name: string
  author: string
  lastUpdate: string
  courseDuration: string
  description: string
  invisibleProduct: boolean
  lessons: LessonDto[]
}

export const INIT_LESSON: Lesson = {
  uId: 'lesson-init-uId',
  sectionName: '',
  video: {
    movieId: '',
    placeholderImage: '',
    sourceType: 'vimeo'
  },
  sectionFields: []
}

export const INIT_PRODUCT: Product = {
  id: '',
  type: '',
  price: 0,
  cardImagePath: '',
  difficulty: DifficultyType.EASY,
  tags: [],
  subscription: SubscriptionType.FREE,
  name: '',
  author: '',
  lastUpdate: '',
  courseDuration: '',
  description: '',
  invisibleProduct: false,
  lessons: []
}
