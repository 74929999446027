import { UserDto, User } from '../../service/User/UserService'

export const UserDtoToFrontAdapter = (user: UserDto): User => ({
  ...user,
  // eslint-disable-next-line no-underscore-dangle
  id: user._id
})

export const UserFrontToDtoAdapter = (user: User): UserDto => ({
  ...user,
  _id: user.id
})
