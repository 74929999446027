import { useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { ProductDataActions } from '../../Context/Product/ProductDataReducer'
import { ProductDataContext } from '../../Context/Product/ProductDataContext'
import { Product } from '../../service/Product/ProductService'
import { SwitchViewMode } from '../switchViewMode/SwitchViewMode'
import { SingleProductForm } from './SingleProductForm/SingleProductForm'
import { SingleProductView } from './SingleProductView/SingleProductView'
import { UserReducerActions } from '../../Context/User/UserReducer'
import { UserContext } from '../../Context/User/UserContext'
import { UserRole } from '../../service/User/UserService'

export const ProductChooseView = (): JSX.Element => {
  const [currentMode, setCurrentMode] = useState<'view' | 'edit'>('view')
  const { productID } = useParams()
  const productsContext: ProductDataActions = useContext(ProductDataContext)
  const userContext: UserReducerActions = useContext(UserContext)
  const searchedProduct = productsContext.products.filter(
    (singleProduct: Product) => singleProduct.id === productID
  )
  if (searchedProduct.length <= 0)
    return (
      <>
        Nie znaleziono produktu lub jest jakiś błąd. Zgłoś to administratorowi
      </>
    )

  const isCourseBought = userContext.checkIfAvailableToWatch(searchedProduct[0])

  if (!isCourseBought && userContext.userDetails.role !== 'admin') {
    // eslint-disable-next-line prefer-destructuring
    searchedProduct[0].lessons.splice(1)
  }

  return (
    <section>
      {userContext.userDetails.role === UserRole.ADMIN && (
        <SwitchViewMode
          primaryHandler={(): void => setCurrentMode('edit')}
          secondaryHandler={(): void => setCurrentMode('view')}
        />
      )}
      {currentMode === 'view' && (
        <SingleProductView
          product={searchedProduct[0]}
          isBought={isCourseBought}
        />
      )}
      {currentMode === 'edit' && (
        <SingleProductForm product={searchedProduct[0]} />
      )}
    </section>
  )
}
