import { Dispatch, memo, SetStateAction, useContext, useState } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem'
import styles from './ControlPricingSection.module.scss'
import { PricingPills } from './PricingPills/PricingPills'
import { mainInfoToDropdownProps } from '../utils'
import {
  cakePriceReducerInitial,
  RemovePricing,
  storeNewPricing,
  updatePricing
} from '../API/mock'
import { CakePriceCalculatorContext } from '../CakePriceCalculatorContext/CakePriceCalculatorContext'
import { BiEditIcon } from '../../../../Assets/ReactIcons'
import { InputTextWithValidator } from '../../../UI/inputs/InputTextWithValidator/InputTextWithValidator'
import { CalculatorPriceStringValidator } from '../validators'
import { CakePriceCalcReducer } from '../CakePriceCalculatorContext/Reducer/interfaces'
import { Loader } from '../../../UI/Loader/Loader'

interface Props {
  pricingsLimit: number
  setCurrentPricingHandler: (data: CakePriceCalcReducer) => void
  updateCalculateProductName: (data: string) => void
  avaliablePricings: DropdownItemProps[]
  setAvaliablePricings: Dispatch<SetStateAction<DropdownItemProps[]>>
}

const ControlPricingSection = ({
  pricingsLimit,
  setCurrentPricingHandler,
  updateCalculateProductName,
  avaliablePricings,
  setAvaliablePricings
}: Props): JSX.Element => {
  const [editTitleMode, setEditTitleMode] = useState(false)
  const cakePricingContext = useContext(CakePriceCalculatorContext)
  const [showLoader, setShowLoader] = useState<boolean>(false)

  if (cakePricingContext == null) {
    return <>Pricing context is null</>
  }

  const addNewPricingHandler = (pricingData: CakePriceCalcReducer) => {
    setShowLoader(true)
    storeNewPricing({
      ...pricingData,
      pricingName: `Nowa Wycena_${avaliablePricings.length}`
    })
      .then(response => {
        if (response.status === 201 && response.data) {
          const clonePricingData = cloneDeep(avaliablePricings)
          clonePricingData.push(mainInfoToDropdownProps([response.data])[0])
          setAvaliablePricings(clonePricingData)
        }
      })
      .finally(() => setShowLoader(false))
  }

  return (
    <section className={styles.Container}>
      <Loader text='Dodawanie wyceny..' show={showLoader} fullScreen />
      <section className={styles.CalculatorTitleContainer}>
        <section className={styles.PricingNameSection}>
          {editTitleMode ? (
            <div className={styles.InputProductName}>
              <InputTextWithValidator
                type='text'
                value={cakePricingContext.pricingName}
                onChangeHandler={(value: string): void => {
                  updateCalculateProductName(value)
                }}
                validateFnc={CalculatorPriceStringValidator}
              />
            </div>
          ) : (
            <h2
              role='presentation'
              onClick={(): void => setEditTitleMode(!editTitleMode)}
              className={styles.CalculatorTitle}
            >
              {cakePricingContext.pricingName}
            </h2>
          )}
          <p
            role='presentation'
            onClick={() => setEditTitleMode(!editTitleMode)}
            className={styles.EditIcon}
          >
            {BiEditIcon}
          </p>
        </section>

        <section className={styles.CalculatorContainerActions}>
          {pricingsLimit !== 0 ? (
            <p
              role='presentation'
              className={styles.StorePricingText}
              onClick={(): void => {
                const pricingPayload: CakePriceCalcReducer = {
                  id: cakePricingContext.id,
                  pricingName: cakePricingContext.pricingName,
                  quantity: cakePricingContext.quantity,
                  groceryProducts: cakePricingContext.groceryProducts.products,
                  additionalSpending:
                    cakePricingContext?.additionalSpending.products,
                  fixedCosts: cakePricingContext.fixedCosts.data,
                  workingTime: cakePricingContext.workingTime.data,
                  deliveryTime: cakePricingContext.deliveryTime.data
                }

                if (cakePricingContext.id === '') {
                  storeNewPricing(pricingPayload)
                    .then(response => {
                      if (response.status === 201 && response.data) {
                        const clonePricingData = cloneDeep(avaliablePricings)
                        clonePricingData.push(
                          mainInfoToDropdownProps([response.data])[0]
                        )
                        setAvaliablePricings(clonePricingData)
                      }
                    })
                    .finally(null)
                } else {
                  updatePricing(pricingPayload)
                    .then(response => {
                      if (response.status === 200 && response.data) {
                        setCurrentPricingHandler(response.data)
                        setAvaliablePricings(
                          avaliablePricings.map(pricing => {
                            if (pricing.value === response.data.id) {
                              return {
                                ...pricing,
                                text: response.data.pricingName
                              }
                            }
                            return pricing
                          })
                        )
                      }
                    })
                    .finally(null)
                }
              }}
            >
              Zapisz wycenę
            </p>
          ) : (
            <p className={styles.StorePricingTextWarning}>
              Kup subskrypcję, aby zapisać
            </p>
          )}
          {avaliablePricings.length < pricingsLimit ? (
            <p
              role='presentation'
              className={styles.AddNewPricingText}
              onClick={() => addNewPricingHandler(cakePriceReducerInitial)}
            >
              Dodaj nową wycenę
            </p>
          ) : (
            <span className={styles.LimitForNewPricingText}>
              Wyczerpano limit wycen
            </span>
          )}
        </section>
      </section>
      <div className={styles.SearchContainer}>
        <PricingPills
          activePillId={cakePricingContext.id}
          pricingPillsData={avaliablePricings}
          setCurrentPricing={setCurrentPricingHandler}
          setAvaliablePricings={(pricingID: string) => {
            // eslint-disable-next-line no-alert
            const confirmation = window.confirm(
              'Czy napewno chcesz usunać wycenę?'
            )
            if (confirmation) {
              RemovePricing(pricingID).then(response => {
                if (response.status === 204) {
                  const filteredPricing = avaliablePricings.filter(
                    pricing => pricing.value !== pricingID
                  )
                  setAvaliablePricings(filteredPricing)
                  setCurrentPricingHandler(cloneDeep(cakePriceReducerInitial))
                }
              })
            }
          }}
          copyPricingHandler={() => {
            addNewPricingHandler({
              id: cakePricingContext.id,
              pricingName: cakePricingContext.pricingName,
              quantity: cakePricingContext.quantity,
              groceryProducts: cakePricingContext.groceryProducts.products,
              additionalSpending:
                cakePricingContext?.additionalSpending.products,
              fixedCosts: cakePricingContext.fixedCosts.data,
              workingTime: cakePricingContext.workingTime.data,
              deliveryTime: cakePricingContext.deliveryTime.data
            })
          }}
        />
      </div>
    </section>
  )
}

export default memo(ControlPricingSection)
