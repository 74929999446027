import {ReactElement, useContext} from 'react'
import styles from './ProductPaymentModal.module.scss'
import { FaTimesIcon } from '../../../../Assets/ReactIcons'
import { SectionSeparator } from '../../sectionSeparator/sectionSeparator'
import { ButtonByAdam } from '../../buttons/ButtonByAdam/ButtonByAdam'
import { CheckoutProductPrzelewy24 } from '../../../../service/Stripe/checkout'
import {GlobalLoaderContext} from "../../../../Context/GlobalLoader/GlobalLoader";
import {GlobalNotificationContext} from "../../../../Context/GlobalNotification/GlobalNotificationProvider";
import {PLEASE_WAIT} from "../../../../const/consts";
import {NotificationType} from "../../../../Context/GlobalNotification/Types";

export interface ModalProductProps {
  productID: string
  userEmail: string
}

export interface ModalPaymentProps {
  isModalVisible: boolean
  product: ModalProductProps | null
}

interface Props {
  children?: ReactElement
  headerLabel?: string
  headerColor?: 'warning' | 'positive' | 'information' | 'normal'
  confirmBtnText?: string
  declineBtnText?: string
  paymentData: any
  onModalClose: () => void
}

export const ProductPaymentModal = ({
  children,
  headerLabel,
  headerColor,
  confirmBtnText,
  declineBtnText,
  paymentData,
  onModalClose
}: Props): JSX.Element => {
  const showLoader = useContext(GlobalLoaderContext)
  const showNotification = useContext(GlobalNotificationContext)
  const paymentHandler = async () => {
    try {
      showLoader(true, `${PLEASE_WAIT}`);
      const data = {
        productId: paymentData.product.productID,
        email: paymentData.product.userEmail
      }
      await CheckoutProductPrzelewy24(data)
      onModalClose()
    } catch (e) {
      showNotification(
          NotificationType.ERROR,
          'Płatność się nie powiodła. Spróbuj jeszcze raz lub skontaktuj się z agministratorem.'
      )
    }
    showLoader(false);
  }

  return (
      <div className={`${styles.ModalMask}`}>
        <div>
          <div
              className={`${styles.HeaderContainer} ${
                  styles['positive' || headerColor]
              }`}
          >
            <h2>{'Przeczytaj' || headerLabel}</h2>
            <div
                aria-hidden='true'
                tabIndex={0}
                role='button'
                onClick={(): void => onModalClose()}
            >
              {FaTimesIcon}
            </div>
          </div>

          <div className={styles.ModalWrapper}>
            <div className={styles.ContentContainer}>
              <p>
                {'Za moment zostaniesz przekierowany do strony na której będzie można dokonać płatności za kurs. Po zaakceptowaniu płatności powinieneś mieć dostęp do wybranego materiału w ciągu 5 minut. Jeżeli napotkasz jakieś problemy, służymy pomocą!' ||
                    children}
              </p>
            </div>

            <SectionSeparator width={100} marginTop={30} marginBottom={10} />

            <div className={styles.InputsContainer}>
              <ButtonByAdam
                  btnType='green'
                  onClick={paymentHandler}
              >
                {'Zatwierdź' || confirmBtnText}
              </ButtonByAdam>
              <ButtonByAdam btnType='red' onClick={(): void => onModalClose()}>
                {'Wróć' || declineBtnText}
              </ButtonByAdam>
            </div>
          </div>
        </div>
      </div>
  )
}
