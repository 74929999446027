import { Lesson } from '../service/Product/lessonTypes'

export interface DictionaryItem {
  key: string
  value: string
  text: string
}

export interface Dictionary {
  fieldTypeLabels: DictionaryItem[]
  fieldTypePlaceholders: DictionaryItem[]
  difficultyTypes: DictionaryItem[]
  subscriptionTypes: DictionaryItem[]
}

export const getTextByCode = (
  code: string,
  dictionary: DictionaryItem[]
): string => {
  const searchedObject = dictionary.filter(
    (singleDictionary: DictionaryItem): boolean => singleDictionary.key === code
  )
  if (searchedObject.length > 0) {
    return searchedObject[0].text
  }
  return ''
}

export const getValueByCode = (
  code: string | undefined,
  dictionary: DictionaryItem[]
): string => {
  const searchedObject = dictionary.filter(
    (singleDictionary: DictionaryItem): boolean => singleDictionary.key === code
  )
  if (searchedObject.length > 0) {
    return searchedObject[0].value
  }
  return ''
}

export const getCodeByValue = (
  value: string,
  dictionary: DictionaryItem[]
): string => {
  const searchedObject = dictionary.filter(
    (singleDictionary: DictionaryItem): boolean =>
      singleDictionary.text === value
  )
  if (searchedObject.length > 0) {
    return searchedObject[0].value
  }
  return ''
}

export const createDictionaryFromLessons = (
  lessons: Lesson[]
): DictionaryItem[] =>
  lessons.map(
    (lesson: Lesson, index: number): DictionaryItem => ({
      key: lesson.sectionName,
      value: lesson.sectionName,
      text: `Lekcja ${index + 1}: ${lesson.sectionName}`
    })
  )

export const productCategoryToType: DictionaryItem[] = [
  { key: 'courses', value: 'course', text: 'kurs' },
  { key: 'lives', value: 'live', text: 'live' },
  { key: 'bought', value: 'bought', text: 'Twoje kursy' }
]

export const fieldTypeLabels: DictionaryItem[] = [
  { key: 'header', value: 'header', text: 'nagłówek' },
  { key: 'paragraph', value: 'paragraph', text: 'paragraf' },
  { key: 'dotList', value: 'dotList', text: 'Lista-Kropki' },
  { key: 'image', value: 'image', text: 'image' }
]

export const fieldTypePlaceholders: DictionaryItem[] = [
  { key: 'header', value: 'header', text: 'header' },
  { key: 'paragraph', value: 'paragraph', text: 'paragraph' },
  // { key: 'simpleList', value: 'simpleList', text: 'simpleList' },
  { key: 'image', value: 'image', text: 'image' }
]

export const difficultyTypes: DictionaryItem[] = [
  { key: 'easy', value: 'easy', text: 'łatwy' },
  { key: 'medium', value: 'medium', text: 'średni' },
  { key: 'hard', value: 'hard', text: 'trudny' }
]

export const subscriptionTypes: DictionaryItem[] = [
  { key: 'free', value: 'free', text: 'darmowy' },
  { key: 'basic', value: 'basic', text: 'podstawowy' },
  { key: 'premium', value: 'premium', text: 'premium' }
]

export const frostingIngredients: DictionaryItem[] = [
  {
    key: 'Classic butter cream',
    value: 'Classic butter cream',
    text: 'Krem klasyczny maślany'
  },
  {
    key: 'Cream with condensed milk',
    value: 'Cream with condensed milk',
    text: 'Krem z mlekiem skondensowanym'
  },
  {
    key: 'Cream with powdered milk',
    value: 'Cream with powdered milk',
    text: 'Krem z mlekiem w proszku'
  }
]

export const spongeCakeIngredients: DictionaryItem[] = [
  {
    key: 'Light sponge cake',
    value: 'Light sponge cake',
    text: 'Biszkopt jasny'
  },
  {
    key: 'Cocoa sponge cage',
    value: 'Cocoa sponge cage',
    text: 'Biszkopt kakaowy'
  },
  {
    key: 'Brownie sponge cake',
    value: 'Brownie sponge cake',
    text: 'Biszkopt brownie'
  }
]

export const dictionaries: Dictionary = {
  fieldTypeLabels,
  fieldTypePlaceholders,
  difficultyTypes,
  subscriptionTypes
}
