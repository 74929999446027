import { List } from 'semantic-ui-react'
import { LinkWithIconAndDescription } from '../../../../const/interfaces'

interface Props {
  links: LinkWithIconAndDescription[]
}

export const LinkList = ({ links }: Props): JSX.Element => (
  <List>
    {links.map(
      (linkData): JSX.Element => (
        <List.Item key={linkData.name}>
          <List.Icon size='large' name={linkData.iconName} />
          <List.Content>
            <List.Header
              style={{ marginBottom: '10px' }}
              onClick={(): void => {
                window.open(linkData.url, '_blank')
              }}
              as='a'
            >
              {linkData.name}
            </List.Header>
            <List.Description>
              <span className='default-paragraph'>{linkData.description}</span>
            </List.Description>
          </List.Content>
        </List.Item>
      )
    )}
  </List>
)
