import { SyntheticEvent } from 'react'
import { Dropdown, DropdownProps, DropdownItemProps } from 'semantic-ui-react'
import styles from './SearchableSelect.module.css'

interface Props {
  onChange: (value: string) => void
  options: DropdownItemProps[]
  id?: string
  className?: string
  label?: string
  placeholder?: string
  noResultsMessage?: string
  value: string
  dataRequired?: boolean
  noMargin?: boolean
  search?: boolean
}

export const SearchableSelect = ({
  id,
  className = '',
  label,
  placeholder,
  noResultsMessage = 'Nie znaleziono',
  options,
  value,
  onChange,
  dataRequired,
  noMargin = false,
  search = false
}: Props): JSX.Element => (
  <div className={`${styles.InputContainer} ${noMargin && styles.NoMargin}`}>
    {label && (
      <span
        className={`${styles.Label}`}
        style={{ visibility: label ? 'visible' : 'hidden' }}
      >
        {label}
        {dataRequired && <span style={{ color: 'red' }}>*</span>}
      </span>
    )}
    <Dropdown
      id={id}
      className={className}
      data-required={dataRequired}
      data-filled={!!value}
      data-question-label={label}
      noResultsMessage={noResultsMessage}
      fluid
      selection
      search={search}
      // search
      options={options}
      value={value || placeholder}
      onChange={(
        _: SyntheticEvent<HTMLElement, Event>,
        props: DropdownProps
      ): void => onChange(props.value as string)}
    />
  </div>
)
