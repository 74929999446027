// ** START FIELDS DEFINITIONS ** //

// --- Image Field ---- //
export interface LessonImageData {
  path: string
  title: string
}

export interface LessonImageField {
  uId: string
  type: 'image'
  imgData: LessonImageData
}

// --- Lesson Dot Field---- //
export interface LessonDotListOption {
  uId: string
  textHtml: string
}

export interface DotListData {
  title: string
  listItems: LessonDotListOption[]
}

export interface LessonDotListField {
  uId: string
  type: 'dotList'
  list: DotListData
}

// --- Lesson Text Field ---- //
export interface LessonTextField {
  uId: string
  type: 'header' | 'paragraph'
  text: string
}

// ** END FIELDS DEFINITIONS ** //

export interface VimeoVideo {
  movieId: string
  placeholderImage: string
  sourceType: 'vimeo' | 'youtube'
}

export interface Lesson {
  uId: string
  sectionName: string
  video: VimeoVideo
  sectionFields: (LessonTextField | LessonDotListField | LessonImageField)[]
}

export const INIT_LESSON: Lesson = {
  uId: '',
  sectionName: '',
  video: {
    movieId: '',
    placeholderImage: '',
    sourceType: 'vimeo'
  },
  sectionFields: []
}
