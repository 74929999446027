import nextId from 'react-id-generator'
import { DictionaryItem } from './dictionaries'
import {
  getImageFieldInitByType,
  getListDottedInitByType,
  getTextFieldInitByType
} from './initValues/productFields'
import {
  LessonDotListField,
  LessonImageField,
  LessonTextField
} from '../service/Product/lessonTypes'
import { User, UserRole } from '../service/User/UserService'
import {
  SubscriptionType,
  SubscriptionTypes
} from '../service/Product/ProductService'
import {Subscription} from "../Context/Dictionaries/api";

export interface FilterValue {
  code: string
  status: boolean
}

export interface Filter {
  buttonText: string
  filterType: 'difficulty' | 'subscription'
  values: FilterValue[]
  dictionary: DictionaryItem[]
}

export const getInitFieldValueByFieldType = (
  fieldType: 'header' | 'paragraph' | 'image' | 'dotList'
): LessonTextField | LessonImageField | LessonDotListField => {
  if (fieldType === 'header' || fieldType === 'paragraph') {
    return getTextFieldInitByType(fieldType)
  }
  if (fieldType === 'dotList') {
    return getListDottedInitByType(fieldType)
  }
  if (fieldType === 'image') {
    return getImageFieldInitByType(fieldType)
  }
  throw new Error('fieldType is not matching any condition')
}

export const addUIDProp = <T>(obj: T, uIdPrefix = ''): T & { uId: string } => ({
  ...obj,
  uId: nextId(uIdPrefix)
})

export const applyFilters = <T>(
  data: T[],
  filters: ((data: T) => boolean)[]
): T[] => filters.reduce((d, f) => d.filter(f), data)

export const createCloudinaryUrlForVideoPlaceholder = (path: string): string =>
  `https://res.cloudinary.com/majcake/image/upload/c_fill,w_900/v1607968525/${path}`

export const setTimeoutHandler = (
  callback: () => void,
  timeoutMS: number
): void => {
  setTimeout((): void => {
    callback()
  }, timeoutMS)
}

export const checkIfUserSubscriptionTypeIsEnough = (
  userDetails: User,
  productSubType: SubscriptionTypes
): boolean => {
  if (userDetails.role === UserRole.ADMIN) return true
  if (userDetails.subscriptionType === 'premium') return true
  if (productSubType === 'free') return true
  if (userDetails.subscriptionType === 'free') return false
  return userDetails.subscriptionType === productSubType
}

export const getLimitedTextCharacters = (text: string) =>
  text.length > 100 ? text.slice(0, 80).concat('...') : text

export const translateSubscriptionType = (
  subType: SubscriptionTypes
): string => {
  if (subType === SubscriptionType.BASIC) return 'podstawowy'
  if (subType === SubscriptionType.PREMIUM) return 'premium'
  return 'darmowy'
}
