import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useReducer
} from 'react'
import { Product, ProductDto } from '../../service/Product/ProductService'
import {
  ProductDataActions,
  initialProductState,
  productDataReducer,
  ProductOperations
} from './ProductDataReducer'
import {
  productsDtoToFrontAdapter,
  createProductHelpers
} from '../../helpers/adapters/products'
import { UserReducerActions } from '../User/UserReducer'
import { UserContext } from '../User/UserContext'
import {
  getProductsByType,
  getProductsFilteredByCategory,
  updateProduct,
  updateProductsData
} from './actions'
import { getProducts } from '../../service/Product/api'

export const ProductDataContext =
  createContext<ProductDataActions>(initialProductState)

export const ProductDataContextProvider = (props: {
  children: ReactNode
}): JSX.Element => {
  const [productsData, dispatch] = useReducer(
    productDataReducer,
    initialProductState
  )
  const userContext: UserReducerActions = useContext(UserContext)
  const { children: childComponents } = props

  useEffect((): void => {
    getProducts().then((products: ProductDto[]) =>
      updateProductsData(productsDtoToFrontAdapter(products), dispatch)
    )
  }, [])

  const productHelpers = createProductHelpers(productsData.products)

  const contextData: ProductDataActions = useMemo(
    () => ({
      products: productsData.products,
      helpers: productHelpers,
      updateProductsData: (): void =>
        updateProductsData(productsData.products, dispatch),
      updateProduct: (productOperations: ProductOperations): void =>
        updateProduct(productOperations, dispatch),
      getProductsByType: (type: string): Product[] =>
        getProductsByType(type, productsData.products),
      getProductsFilteredByCategory: (category: string): Product[] =>
        getProductsFilteredByCategory(
          category,
          userContext,
          productsData.products
        )
    }),
    [productsData]
  )

  return (
    <ProductDataContext.Provider value={contextData}>
      {childComponents}
    </ProductDataContext.Provider>
  )
}
