import {
  GroceryProductInterface,
  KitchenMeasures,
  UpdateGroceryConfig
} from '../../../CakePriceCalculatorContext/Reducer/interfaces'
import styles from './ConfigView.module.scss'
import productRowStyles from '../SingleGroceryRandomProductRow.module.scss'
import {
  calculateProductPrice,
  CalculatorPriceInputNumberValidator
} from '../../../utils'
import { InputNumber } from '../../../../../UI/inputs/InputNumber/InputNumber'
import { SelectLib } from '../../../../../UI/inputs/SelectLib/SelectLib'
import { kitchenMeasureOptions } from '../../../API/mock'
import { InputTextWithValidator } from '../../../../../UI/inputs/InputTextWithValidator/InputTextWithValidator'
import { CalculatorProductNameValidator } from '../../../validators'

interface Props {
  product: GroceryProductInterface
  index: number
  onChangeHandler: (data: UpdateGroceryConfig) => void
  removeRowHandler: (id: string) => void
  changeView: () => void
}

export const ConfigView = ({
  product,
  index,
  onChangeHandler,
  removeRowHandler,
  changeView
}: Props): JSX.Element => (
  <div className={styles.StandardViewContainer}>
    <p className={productRowStyles.IndexConfig}>{`${index + 1}.`}</p>
    <div className={styles.InputContainer}>
      <p className={styles.ProductName}>Miara</p>
      <SelectLib
        options={kitchenMeasureOptions}
        onChangeHandler={(value: unknown): void => {
          onChangeHandler({
            id: product.id,
            inputName: 'measure',
            value: value as KitchenMeasures
          })
        }}
        value={product.measure}
      />
    </div>

    <div className={styles.InputContainer}>
      <p className={styles.ProductName}>Nazwa produktu</p>

      <InputTextWithValidator
        type='text'
        // className={productRowStyles.Input}
        value={product.name}
        onChangeHandler={(value: string): void =>
          onChangeHandler({
            id: product.id,
            inputName: 'name',
            value
          })
        }
        validateFnc={CalculatorProductNameValidator}
      />
    </div>
    <div className={styles.InputContainer}>
      <p className={styles.ProductName}>Zakupiona ilość</p>
      <InputNumber
        type='number'
        value={product.quantityBought}
        placeholder='Ilość...'
        containerStyles={styles.SingleInput}
        onChangeHandler={(value: number): void =>
          onChangeHandler({
            id: product.id,
            inputName: 'quantityBought',
            value
          })
        }
        validateFnc={CalculatorPriceInputNumberValidator}
        label={product.measure}
      />
    </div>

    <div className={styles.InputContainer}>
      <p className={styles.ProductName}>Cena zakupu</p>
      <InputNumber
        type='number'
        value={product.price}
        placeholder='Cena...'
        containerStyles={styles.SingleInput}
        onChangeHandler={(value: number): void =>
          onChangeHandler({
            id: product.id,
            inputName: 'price',
            value
          })
        }
        validateFnc={CalculatorPriceInputNumberValidator}
        label='zł'
      />
    </div>
    <div className={styles.InputContainer}>
      <p className={styles.ProductName}>Zużyta ilość</p>
      <InputNumber
        type='number'
        value={product.quantityUsed}
        placeholder='Ilość...'
        containerStyles={styles.SingleInput}
        onChangeHandler={(value: number): void =>
          onChangeHandler({
            id: product.id,
            inputName: 'quantityUsed',
            value
          })
        }
        validateFnc={CalculatorPriceInputNumberValidator}
        label={product.measure}
      />
    </div>

    <p
      className={`${productRowStyles.Price} ${productRowStyles.MarginBottom20}`}
    >{`${calculateProductPrice(product)} pln`}</p>
    <div
      role='presentation'
      onClick={changeView}
      className={`${productRowStyles.EditText} ${productRowStyles.MarginBottom20}`}
    >
      zapisz zmiany
    </div>
    <p
      role='presentation'
      onClick={(): void => removeRowHandler(product.id)}
      className={`${productRowStyles.RemoveText} ${productRowStyles.MarginBottom20}`}
    >
      usuń
    </p>
  </div>
)
