import styles from './GlobalNotification.module.scss'
import { NotificationType, Notification } from './Types'

interface Props {
  type: Notification
  text: string
  visible?: boolean
}

export const GlobalNotification = ({
  type = NotificationType.INFO,
  text,
  visible = false
}: Props): JSX.Element => (
  <div
    className={`${styles.Container} ${
      type === NotificationType.INFO ? styles.Info : styles.Error
    } ${visible && styles.Visible}`}
  >
    {text}
  </div>
)
