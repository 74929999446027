import { useReducer, useState } from 'react'
import styles from './UserSettingsForm.module.scss'
import {
  userSettingsFormReducer,
  initialUserSettingsReducer,
  UserSettingsFormReducerActions
} from './UserSettingsFormReducer'
import { User } from '../../../service/User/UserService'
import { UserOperations } from '../../../Context/User/UserReducer'
import { SingleFieldWrapper } from './SingleFieldWrapper/SingleFieldWrapper'
import { SectionSeparator } from '../../UI/sectionSeparator/sectionSeparator'
import { ResetPasswordSection } from './ResetPasswordSection/ResetPasswordSection'
import { HeaderWithIcon } from '../../UI/headers/CustomHeader/CustomHeader'
import { ExpandedStatus } from '../../UI/ExpandedStatus/ExpandedStatus'
import { ButtonByAdam } from '../../UI/buttons/ButtonByAdam/ButtonByAdam'
import { getQueryParamsFromLocationSearch } from '../../../helpers/http'
import { ParagraphDefault } from '../../UI/Paragraphs/ParagraphDefault/ParagraphDefault'

interface Props {
  user: User
  onSaveHandler: (userData: User) => void
}

export const UserSettingsForm = ({
  user,
  onSaveHandler
}: Props): JSX.Element => {
  const [showSettings, setShowSettings] = useState<boolean>(
    typeof getQueryParamsFromLocationSearch(window.location.search).token ===
      'string'
  )
  const [userData, dispatch] = useReducer(userSettingsFormReducer, {
    ...initialUserSettingsReducer,
    userData: { ...user }
  })

  const updateUserFormValue = (data: UserOperations): void =>
    dispatch({
      type: UserSettingsFormReducerActions.UPDATE_USER_FORM_VALUE,
      data
    })

  return (
    <section className={styles.SectionContainer}>
      <div
        aria-hidden='true'
        tabIndex={0}
        role='button'
        className={styles.MainRow}
        onClick={(): void => setShowSettings(!showSettings)}
      >
        <HeaderWithIcon headerType='typical'>
          Ustawienia użytkownika
        </HeaderWithIcon>
        <ExpandedStatus isExpanded={showSettings} />
      </div>
      {showSettings && (
        <div className={styles.InputsWrapper}>
          <SingleFieldWrapper
            label='Imię'
            placeholder='Uzupełnij swoje imię...'
            onBlurHandler={(val: string): void =>
              updateUserFormValue({
                fieldName: 'name',
                fieldValue: val
              })
            }
            value={userData.userData.name}
          />

          <SingleFieldWrapper
            label='Nazwisko'
            placeholder='Uzupełnij swoje nazwisko...'
            onBlurHandler={(val: string): void =>
              updateUserFormValue({
                fieldName: 'surname',
                fieldValue: val
              })
            }
            value={userData.userData.surname}
          />

          <SingleFieldWrapper
            label='Email'
            placeholder='Uzupełnij swój email...'
            onBlurHandler={(val: string): void =>
              updateUserFormValue({
                fieldName: 'email',
                fieldValue: val
              })
            }
            value={userData.userData.email}
          />

          <div style={{ marginTop: '20px' }}>
            <ParagraphDefault>
              Zapisz wprowadzone zmiany w ustawieniach użytkownika
            </ParagraphDefault>
            <ButtonByAdam
              onClick={(): void => onSaveHandler(userData.userData)}
            >
              Zapisz zmiany
            </ButtonByAdam>
          </div>

          <SectionSeparator />

          <ResetPasswordSection />
        </div>
      )}
    </section>
  )
}
