import {
  CalculatorOperations,
  getIngredientsBySpongeCakeType,
  roundedSpongeCakeField
} from '../../../../helpers/cake'
import { roundedSpongeCakeValidator } from './RoundedSpongeCakeValidator'

interface RoundedSpongeCakeCalculatorActions {
  d: string
  errors: string[]
  chosenSpongeType: string
  ingredients: string[]
  result: number | null
  calculate: () => void
}

export const initialRoundedSpongeCakeReducer: RoundedSpongeCakeCalculatorActions =
  {
    d: '',
    errors: [],
    chosenSpongeType: '',
    ingredients: [],
    result: null,
    calculate: (): void => {
      // Initial method
    }
  }

const updateValidationErrors = (
  errors: string[],
  state: RoundedSpongeCakeCalculatorActions
): RoundedSpongeCakeCalculatorActions => ({
  ...state,
  errors,
  ingredients: [],
  result: null
})

const calculateResult = (
  state: RoundedSpongeCakeCalculatorActions
): RoundedSpongeCakeCalculatorActions => {
  const result = Math.round(roundedSpongeCakeField(parseFloat(state.d)))
  return {
    ...state,
    result,
    ingredients: getIngredientsBySpongeCakeType(state.chosenSpongeType, result),
    errors: []
  }
}

export enum RoundedSpongeCakeReducerActionNames {
  CALCULATE_RESULT = 'CALCULATE_RESULT',
  UPDATE_ROUNDED_SPONGE_CAKE_CALC_DATA = 'UPDATE_ROUNDED_SPONGE_CAKE_CALC_DATA'
}

export type RoundedSpongeCakeReducerActions =
  | {
      type: RoundedSpongeCakeReducerActionNames.CALCULATE_RESULT
    }
  | {
      type: RoundedSpongeCakeReducerActionNames.UPDATE_ROUNDED_SPONGE_CAKE_CALC_DATA
      data: CalculatorOperations
    }

const calculate = (
  state: RoundedSpongeCakeCalculatorActions
): RoundedSpongeCakeCalculatorActions => {
  const validationErrors = roundedSpongeCakeValidator(state.d)
  if (validationErrors.length > 0) {
    return updateValidationErrors(validationErrors, state)
  }
  return calculateResult(state)
}

const updateCalcData = (
  data: CalculatorOperations,
  state: RoundedSpongeCakeCalculatorActions
): RoundedSpongeCakeCalculatorActions => ({
  ...state,
  [data.fieldName]: data.fieldValue
})

export const roundedSpongeCakeReducer = (
  state: RoundedSpongeCakeCalculatorActions,
  action: RoundedSpongeCakeReducerActions
): RoundedSpongeCakeCalculatorActions => {
  switch (action.type) {
    case RoundedSpongeCakeReducerActionNames.CALCULATE_RESULT:
      return calculate(state)
    case RoundedSpongeCakeReducerActionNames.UPDATE_ROUNDED_SPONGE_CAKE_CALC_DATA:
      return updateCalcData(action.data, state)
    default:
      return state
  }
}
