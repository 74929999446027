import {Suspense} from 'react'
import ReactDOM from 'react-dom/client';
import 'semantic-ui-css/semantic.min.css'
import './index.css'
import {App} from './App'
import {UserContextProvider} from './Context/User/UserContext'
import {GlobalLoaderProvider} from './Context/GlobalLoader/GlobalLoader'
import {GlobalNotificationProvider} from './Context/GlobalNotification/GlobalNotificationProvider'
import {DictionariesContextProvider} from './Context/Dictionaries/DictionariesContext'
import {Loader} from './components/UI/Loader/Loader'
import {PLEASE_WAIT} from './const/consts'

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <Suspense fallback={<Loader text={PLEASE_WAIT} show fullScreen/>}>
        <GlobalLoaderProvider>
            <GlobalNotificationProvider>
                <UserContextProvider>
                    <DictionariesContextProvider>
                        <App/>
                    </DictionariesContextProvider>
                </UserContextProvider>
            </GlobalNotificationProvider>
        </GlobalLoaderProvider>
    </Suspense>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
