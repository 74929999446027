import { ProductDto, Product } from '../../service/Product/ProductService'
import { lessonsDtoToFrontAdapter } from './lesson'
import { ProductHelpers } from '../../Context/Product/ProductDataReducer'

export const productDtoToFrontAdapter = (productDto: ProductDto): Product => ({
  ...productDto,
  lessons: lessonsDtoToFrontAdapter(productDto.lessons),
  invisibleProduct: !!productDto.invisibleProduct,
  // eslint-disable-next-line no-underscore-dangle
  id: productDto._id
})

export const productFrontToDtoAdapter = (product: Product): ProductDto => ({
  ...product,
  // eslint-disable-next-line no-underscore-dangle
  _id: product.id,
  price: Number(product.price)
})

export const productsDtoToFrontAdapter = (
  productsDto: ProductDto[]
): Product[] =>
  productsDto.map(
    (productDto: ProductDto): Product => ({
      ...productDto,
      lessons: lessonsDtoToFrontAdapter(productDto.lessons),
      // eslint-disable-next-line no-underscore-dangle
      id: productDto._id
    })
  )

export const productsFrontToDtoAdapter = (products: Product[]): ProductDto[] =>
  products.map(
    (product: Product): ProductDto => ({
      ...product,
      _id: product.id
    })
  )

export function createProductHelpers(products: Product[]): ProductHelpers {
  const allCourses = products.filter(
    (product): boolean => product.type === 'course'
  )
  const allLives = products.filter(
    (product): boolean => product.type === 'live'
  )
  return {
    allProductsLength: products.length,
    allCoursesLength: allCourses.length,
    allLivesLength: allLives.length,
    allCourses,
    allLives
  }
}
