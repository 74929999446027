import { useEffect, useMemo, useReducer } from 'react'
import { AdvancedImage, placeholder, responsive } from '@cloudinary/react'
import styles from '../../SharedCalculatorCss.module.scss'
import { InputTextLabeled } from '../../../UI/inputs/InputTextLabeled/InputTextLabeled'
import { ButtonByAdam } from '../../../UI/buttons/ButtonByAdam/ButtonByAdam'
import { HeaderWithIcon } from '../../../UI/headers/CustomHeader/CustomHeader'
import { ErrorListMessage } from '../../../UI/MessageComponents/ErrorListMessage/ErrorListMessage'
import { SuccessMessage } from '../../../UI/MessageComponents/SuccessMessage/SuccessMessage'
import {
  initialSquarePortionsReducer,
  squareCakeReducer,
  SquarePortionsReducerActionName
} from './SquarePortionsReducer'
import { CalculatorOperations } from '../../../../helpers/cake'
import { useCloudinary } from '../../../../hooks/useCloudinary'

interface Props {
  countAutomatic: boolean
}

export const SquareCake = ({ countAutomatic }: Props): JSX.Element => {
  const [calculator, dispatch] = useReducer(
    squareCakeReducer,
    initialSquarePortionsReducer
  )

  const { getImageInstance } = useCloudinary()

  const squareCakeImage = useMemo(() => {
    const imageInstance = getImageInstance(
      'szkolamajcake/kalkulatory/tort_kwadrat_wzor'
    )

    return (
      <AdvancedImage
        cldImg={imageInstance}
        plugins={[responsive(), placeholder()]}
      />
    )
  }, [])

  const calculate = (): void =>
    dispatch({ type: SquarePortionsReducerActionName.CALCULATE_RESULT })
  const updateCalculatorData = (data: CalculatorOperations): void =>
    dispatch({
      type: SquarePortionsReducerActionName.UPDATE_SQUARE_PORTIONS_CALC_DATA,
      data
    })

  useEffect((): void => {
    if (countAutomatic) {
      calculate()
    }
  }, [calculator.sideLength, calculator.height])

  return (
    <section className={styles.CalculatorContainer}>
      <div className={styles.Content_Inputs}>
        <HeaderWithIcon headerType='typical'>Torty kwadratowe</HeaderWithIcon>

        <InputTextLabeled
          type='number'
          titleColor='pink'
          title='Podaj szerokość tortu'
          dataRequired
          value={calculator.sideLength}
          placeholder='Podaj szerokość tortu'
          onChangeHandler={(val): void =>
            updateCalculatorData({ fieldName: 'sideLength', fieldValue: val })
          }
        />

        <InputTextLabeled
          type='number'
          titleColor='blue'
          title='Podaj wysokość tortu'
          dataRequired
          value={calculator.height}
          placeholder='Podaj wysokość tortu'
          onChangeHandler={(val): void =>
            updateCalculatorData({ fieldName: 'height', fieldValue: val })
          }
        />

        {!countAutomatic && (
          <ButtonByAdam btnType='premium' onClick={(): void => calculate()}>
            Oblicz
          </ButtonByAdam>
        )}

        <div className={styles.ResultContainer}>
          {calculator.errors.length > 0 && (
            <ErrorListMessage
              title='Błędne wartości w kalkulatorze'
              errorList={calculator.errors}
            />
          )}
          {calculator.result && (
            <SuccessMessage>
              {'Ten tort przewidujemy na: od '}
              <strong>{calculator.result.MIN}</strong>
              {' do '}
              <strong>{calculator.result.MAX}</strong>
              {' porcji.'}
            </SuccessMessage>
          )}
        </div>
      </div>

      <div className={styles.Content_Image}>{squareCakeImage}</div>
    </section>
  )
}
