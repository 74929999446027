import { Link } from 'react-router-dom'
import {fill} from "@cloudinary/url-gen/actions/resize";
import {AdvancedImage} from "@cloudinary/react";
import styles from './CalculatorCard.module.scss'
import { ParagraphDefault } from '../../Paragraphs/ParagraphDefault/ParagraphDefault'
import { DefaultHeader } from '../../headers/DefaultHeader/DefaultHeader'
import { AuthorRow } from './AuthorRow/AuthorRow'
import { Overlay } from '../../Overlay/Overlay'
import {
  subscriptionTypes,
  getTextByCode
} from '../../../../helpers/dictionaries'
import { LabelTag } from '../../Pills/LabelTag/LabelTag'
import { SubscriptionTypes } from '../../../../service/Product/ProductService'
import {useCloudinary} from "../../../../hooks/useCloudinary";

interface Props {
  calculatorID: string
  title: string
  shortDescription: string
  author: string
  subscriptionType: SubscriptionTypes
  productViewPath: string
  cardImagePath: string
}

export const CalculatorCard = ({
  calculatorID,
  title,
  shortDescription,
  author,
  subscriptionType,
  productViewPath,
  cardImagePath
}: Props): JSX.Element => {

  const {getImageInstance} = useCloudinary();
  const aboutPhoto = getImageInstance(cardImagePath)
      .resize(fill()
          .width(630))
      .quality('auto')

  return (
      <article className={styles.CalculatorCardContainer}>
        <Link id={`calculator-card-${calculatorID}`} to={productViewPath}>
          <div className={styles.ContentPositioning}>
            <div>
              <div className={styles.ImageSection}>
                <div className={styles.LabelTagContainer}>
                  <LabelTag
                      text={getTextByCode(subscriptionType, subscriptionTypes)}
                      pillType={subscriptionType}
                      size='big'
                  />
                </div>
                <AdvancedImage cldImg={aboutPhoto} />

                <Overlay />
              </div>
            </div>
            <header className={styles.HeaderSection}>
              <DefaultHeader>{title}</DefaultHeader>
              <ParagraphDefault>{shortDescription}</ParagraphDefault>
            </header>
            <AuthorRow author={author} />
          </div>
        </Link>
      </article>
  )
}
