import React from 'react'
import styles from './ConfirmationModal.module.scss'
import { FaTimesIcon } from '../../../../Assets/ReactIcons'
import { SectionSeparator } from '../../sectionSeparator/sectionSeparator'
import { ButtonByAdam } from '../../buttons/ButtonByAdam/ButtonByAdam'

interface Props {
  children: React.ReactElement
  onClose: () => void
  headerLabel?: string
  headerColor: 'warning' | 'positive' | 'information' | 'normal'
  onConfirmHandler: () => void
  onDeclineHandler: () => void
  confirmBtnText: string
  declineBtnText: string
}

export const ConfirmationModal = ({
  children,
  onClose,
  headerLabel = 'Czy na pewno tego chcesz?',
  headerColor,
  onConfirmHandler,
  onDeclineHandler,
  confirmBtnText,
  declineBtnText
}: Props): JSX.Element => (
  <div className={`${styles.ModalMask}`}>
    <div>
      <div className={`${styles.HeaderContainer} ${styles[headerColor]}`}>
        <h2>{headerLabel}</h2>
        <div role='presentation' onClick={(): void => onClose()}>
          {FaTimesIcon}
        </div>
      </div>

      <div className={styles.ModalWrapper}>
        <div className={styles.ContentContainer}>{children}</div>

        <SectionSeparator width={100} marginTop={30} marginBottom={10} />

        <div className={styles.InputsContainer}>
          <ButtonByAdam
            btnType='green'
            onClick={(): void => onConfirmHandler()}
          >
            {confirmBtnText}
          </ButtonByAdam>
          <ButtonByAdam btnType='red' onClick={(): void => onDeclineHandler()}>
            {declineBtnText}
          </ButtonByAdam>
        </div>
      </div>
    </div>
  </div>
)
