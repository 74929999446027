import { useContext } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { HelloView } from '../containers/HelloView/HelloView'
import { ProductChooseView } from '../components/product/ProductChooseView'
import { UserSettings } from '../containers/UserSettings/UserSettings'
import { SuccessfulProductPayment } from '../containers/PaymentPages/SuccessfulProductPayment'
import { CanceledProductPayment } from '../containers/PaymentPages/CanceledProductPayment'
import { SuccessfulSubscriptionPayment } from '../containers/PaymentPages/SuccessfulSubscriptionPayment'
import { CanceledSubscriptionPayment } from '../containers/PaymentPages/CanceledSubscriptionPayment'
import { ErrorPage } from '../containers/ErrorPages/ErrorPage'
import { Products } from '../containers/products/Products'
import { Subscriptions } from '../containers/Subscriptions/Subscriptions'
import { CreateNewProductForm } from '../components/product/SingleProductForm/CreateNewProductForm'
import { UserReducerActions } from '../Context/User/UserReducer'
import { UserContext } from '../Context/User/UserContext'
import { Calculators } from '../containers/Calculators/Calculators'
import { CakePortionsCounter } from '../components/Calculators/CakePortionsCounter/CakePortionsCounter'
import { CakeIcingCounter } from '../components/Calculators/CakeIcingCounter/CakeIcingCounter'
import { SpongeCakeCounter } from '../components/Calculators/SpongeCakeCounter/SpongeCakeCounter'
import { Benefits } from '../containers/Benefits/Benefits'
import { CakePricing } from '../components/Calculators/CakePricing/CakePricing'
import { CakePriceCalculatorContextProvider } from '../components/Calculators/CakePricing/CakePriceCalculatorContext/CakePriceCalculatorContext'
import { UserRole } from '../service/User/UserService'

export const MainViewRouting = (): JSX.Element => {
  const userContext: UserReducerActions = useContext(UserContext)
  const cakePriceCalculator = () => (
    <CakePriceCalculatorContextProvider>
      <CakePricing />
    </CakePriceCalculatorContextProvider>
  )
  return (
    <Routes>
      <Route path='/' element={<HelloView />} />
      <Route path='/products' element={<Products />} />
      <Route
        path='/products/create'
        element={
          userContext.userDetails.role === UserRole.ADMIN ? (
            <CreateNewProductForm />
          ) : (
            <Navigate to='/' />
          )
        }
      />
      <Route path='/products/:productCategory/' element={<Products />} />
      <Route
        path='/products/:productCategory/:productID'
        element={<ProductChooseView />}
      />
      <Route path='/user/settings' element={<UserSettings />} />
      <Route path='/benefits' element={<Benefits />} />

      {/* Calculators */}
      <Route path='/calculators' element={<Calculators />} />
      <Route
        path='/calculators/cake-portions'
        element={<CakePortionsCounter />}
      />
      <Route path='/calculators/cake-icing' element={<CakeIcingCounter />} />
      <Route path='/calculators/sponge-cakes' element={<SpongeCakeCounter />} />
      <Route path='/calculators/cake-price' element={cakePriceCalculator()} />

      <Route path='/subskrypcje' element={<Subscriptions />} />
      <Route
        path='/checkout/product/success'
        element={<SuccessfulProductPayment />}
      />
      <Route
        path='/checkout/product/cancel'
        element={<CanceledProductPayment />}
      />
      <Route
        path='/checkout/subscription/success'
        element={<SuccessfulSubscriptionPayment />}
      />
      <Route
        path='/checkout/subscription/cancel'
        element={<CanceledSubscriptionPayment />}
      />
      <Route element={<ErrorPage />} />
    </Routes>
  )
}
