import { SubscriptionCharge } from '../../../../service/User/UserService'
import styles from '../SubscriptionSection.module.scss'
import { DAY_IN_MILISECONDS } from '../../../../const/consts'
import {SectionSeparator} from "../../../UI/sectionSeparator/sectionSeparator";

export const SingleChargePanel = ({
  duration,
  created,
  subscriptionType,
                                      index,
}: SubscriptionCharge) => (
    <div>
        <p className={styles.SingleRow}>
            <span>{`${index + 1}. Aktywny pakiet`}</span>
        </p>
          <p className={styles.SingleRow}>
        <span>Poziom:</span>
        <span
          className={`
                  ${styles.SecondarySubscriptionType}
                  ${subscriptionType === 'free' ? 'free-color' : ''}
                  ${subscriptionType === 'basic' ? 'basic-color' : ''}
                  ${subscriptionType === 'premium' ? 'premium-color' : ''}
                  `}
        >
          {` ${subscriptionType || 'Brak'}`}
        </span>
      </p>
      <p className={styles.SingleRow}>
        <span>Data zakupu</span>

        {`: ${new Date(created).toISOString().split('T')[0]}`}
      </p>
      <p className={styles.SingleRow}>
        <span>Koniec dostępu</span>

        {`: ${
          new Date(created + duration * DAY_IN_MILISECONDS)
            .toISOString()
            .split('T')[0]
        }`}
      </p>
        <SectionSeparator />
    </div>
  )
