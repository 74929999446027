import { useState } from 'react'
import nextId from 'react-id-generator'
import styles from './SingleProductView.module.scss'
import { Product, INIT_LESSON } from '../../../service/Product/ProductService'
import { EmbedMovie } from '../../UI/EmbedMovie/EmbedMovie'
import { LessonContentGenerator } from '../ProductLesson/LessonContentGenerator/LessonContentGenerator'
import { SwitchLessonRow } from '../SwitchLessonRow/SwitchLessonRow'
import { createDictionaryFromLessons } from '../../../helpers/dictionaries'
import { SectionSeparator } from '../../UI/sectionSeparator/sectionSeparator'
import { BuyProductRow } from '../BuyProductRow/BuyProductRow'
import { Lesson } from '../../../service/Product/lessonTypes'
import { DefaultHeader } from '../../UI/headers/DefaultHeader/DefaultHeader'
import {
  ModalPaymentProps,
  ProductPaymentModal
} from '../../UI/Modals/ProductPaymentModal/ProductPaymentModal'

interface Props {
  product: Product
  isBought: boolean
}

export const SingleProductView = ({
  product,
  isBought
}: Props): JSX.Element => {
  const [currentLesson, setCurrentLesson] = useState<Lesson>({
    ...(product.lessons.length > 0
      ? { ...product.lessons[0] }
      : {
          ...INIT_LESSON,
          uId: nextId()
        })
  })
  const [paymentModalData, setPaymentModalData] = useState<ModalPaymentProps>({
    isModalVisible: false,
    product: null
  })

  const switchLessonsData = createDictionaryFromLessons(product.lessons)
  const lessonsLength = product.lessons.length
  const currentLessonIndex = product.lessons.findIndex(
    (lesson: Lesson) => lesson.sectionName === currentLesson.sectionName
  )

  const onModalCloseHandler = () =>
    setPaymentModalData({ isModalVisible: false, product: null })

  return (
    <>
      <section className={styles.SectionContainer}>
        {/* Payment modal */}
        {paymentModalData.isModalVisible && (
          <ProductPaymentModal
            onModalClose={onModalCloseHandler}
            paymentData={paymentModalData}
          />
        )}

        {/* Payment information section */}
        {!isBought && (
          <BuyProductRow
            productID={product.id}
            productPrice={product.price}
            getPaymentModalData={setPaymentModalData}
          />
        )}

        <div className={styles.SectionHeader}>
          <DefaultHeader>{product.name}</DefaultHeader>
        </div>

        <section className={styles.ProductDetailsSection}>
          <EmbedMovie
            id={currentLesson.video.movieId || '447098158'}
            // placeholderImageUrl={`/api/${currentLesson.video.placeholderImage}` || 'omg'}
            placeholderImageUrl={
              currentLesson.video.placeholderImage ||
              'szkolamajcake/logo/zajawka.jpg'
            }
            source={currentLesson.video.sourceType || 'vimeo'}
          />
        </section>

        <SwitchLessonRow
          currentValue={currentLesson.sectionName}
          switchInputData={switchLessonsData}
          leftChevronHandler={(): void => {
            if (currentLessonIndex === 0) {
              setCurrentLesson(product.lessons[lessonsLength - 1])
            } else {
              setCurrentLesson(product.lessons[currentLessonIndex - 1])
            }
          }}
          rightChevronHandler={(): void => {
            if (currentLessonIndex === lessonsLength - 1) {
              setCurrentLesson({ ...product.lessons[0] })
            } else {
              setCurrentLesson(product.lessons[currentLessonIndex + 1])
            }
          }}
          selectInputChandler={(sectionName: string): void => {
            const findCurrentLessonIndex = product.lessons.findIndex(
              (lesson: Lesson) => lesson.sectionName === sectionName
            )
            setCurrentLesson(product.lessons[findCurrentLessonIndex])
          }}
        />
      </section>

      <section className={styles.LessonContent}>
        <h1 className={styles.LessonHeader}>{currentLesson.sectionName}</h1>
        <SectionSeparator width={98} />
        <LessonContentGenerator fields={currentLesson.sectionFields} />
      </section>
    </>
  )
}
