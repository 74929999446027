import {FC, ReactNode} from 'react'
import styles from './MainViewWrapper.module.css'

interface MainViewWrapperProps {
    children: ReactNode;
}

export const MainViewWrapper: FC<MainViewWrapperProps> = ({children}: MainViewWrapperProps): JSX.Element => (
        <div id='main-view-wrapper' className={styles.MainViewContainer}>
            {children}
        </div>
    )
