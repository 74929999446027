import nextId from 'react-id-generator'
import { InputText } from '../../../../UI/inputs/InputText/InputText'
import { LessonTextField } from '../../../../../service/Product/lessonTypes'

interface Props {
  updateFieldHandler: (data: LessonTextField) => void
  fieldValue: string
}

export const HeaderContentField = ({
  updateFieldHandler,
  fieldValue
}: Props): JSX.Element => (
  <InputText
    label='Nagłówek'
    placeholder='Wpisz nagłówek'
    value={fieldValue}
    onBlurHandler={(value: string): void => {
      updateFieldHandler({
        type: 'header',
        text: value,
        uId: nextId('header')
      })
    }}
    dataRequired
  />
)
