export const getFormattedDate = (date: string): string => date.split('T')[0]

export const convertMongoDateToISO = (dataToChange: Date): string => {
  const timeStamp = dataToChange.getTime()
  const getOffset = dataToChange.getTimezoneOffset()
  const properData = new Date(timeStamp + getOffset * -60000)
  return properData.toISOString()
}

export function getDateXHoursFromNow(hours: number): Date {
  return new Date(new Date().getTime() + hours * 60 * 60 * 1000)
  // /do whatever you want to do with the new time
}

export function getDateXHoursFromProvidedDate(date: Date, hours: number): Date {
  return new Date(date.getTime() + hours * 60 * 60 * 1000)
  // /do whatever you want to do with the new time
}

export function getDateXDaysFromNow(days: number): Date {
  return new Date(Date.now() + days * 24 * 60 * 60 * 1000)
}

interface TimeParts {
  days: number
  daysms: number
  hours: number
  hoursms: number
  minutes: number
  minutesms: number
  sec: number
}

export function splitMSIntoTimeParts(ms: number): TimeParts {
  const days = Math.floor(ms / (24 * 60 * 60 * 1000))
  const daysms = ms % (24 * 60 * 60 * 1000)
  const hours = Math.floor(daysms / (60 * 60 * 1000))
  const hoursms = ms % (60 * 60 * 1000)
  const minutes = Math.floor(hoursms / (60 * 1000))
  const minutesms = ms % (60 * 1000)
  const sec = Math.floor(minutesms / 1000)
  // return days + ":" + hours + ":" + minutes + ":" + sec;
  return {
    days,
    daysms,
    hours,
    hoursms,
    minutes,
    minutesms,
    sec
  }
}
