import {
  SubscriptionType,
  SubscriptionTypes
} from '../../service/Product/ProductService'

export interface Calculator {
  type: string
  calculatorID: string
  title: string
  description: string
  shortDescription: string
  author: string
  subscriptionType: SubscriptionTypes
  productViewPath: string
  cardImagePath: string
}

export const calculators: Calculator[] = [
  {
    type: 'portions',
    calculatorID: 'calc11',
    title: 'Kalkulator do wyliczania ilości porcji w torcie',
    description: `Ten kalkulator służy do wyliczania w przybliżeniu, na ile porcji możesz podzielić tort o dowolnych wybranych rozmiarach. Wystarczy, że wpiszesz poprawne wymagane dane do kalkulatora, a po kliknięciu przycisku "Oblicz" natychmiast dostaniesz informację zwrotną.
Kalkulatory dostępne są w 3 wersjach – do tortów okrągłych, prostokątnych i kwadratowych. Dodatkowo możesz skorzystać z opcji przeliczania automatycznego, dzięki czemu nie będziesz musiała za każdym razem klikać przycisku "Oblicz" po wprowadzeniu nowych danych.
Wzory dla tych obliczeń przygotowała Agnieszka Majewska, opierając się na swoim wieloletnim doświadczeniu w branży.`,
    shortDescription: '',
    author: 'Agnieszka Majewska',
    subscriptionType: SubscriptionType.FREE,
    productViewPath: '/calculators/cake-portions',
    cardImagePath: '/szkolamajcake/kalkulatory/kalkulator_porcjowania.jpg'
  },
  {
    type: 'icing',
    calculatorID: 'calc22',
    title: 'Kalkulator do wyliczania ilości tynku potrzebnego na tort',
    description: `Ten kalkulator służy do wyliczania w przybliżeniu, ile kremu jest potrzebne do otynkowania tortu o dowolnym rozmiarze. Wystarczy, 
    że wpiszesz poprawne wymagane dane do kalkulatora, a po kliknięciu przycisku "Oblicz" natychmiast dostaniesz informację zwrotną.
Kalkulatory dostępne są w 3 wersjach – do tortów okrągłych, prostokątnych i kwadratowych. Dodaliśmy możliwość wyboru dowolnego kremu do 
tynkowania dostępnego na naszej platformie. Jeżeli takiego wyboru dokonasz, kalkulator pod Ci sugerowaną ilość poszczególnych składników do 
wykonania takiej ilości tynku, jakiej potrzebujesz na obłożenie danego tortu.
Dodatkowo możesz skorzystać z opcji przeliczania automatycznego, dzięki czemu nie będziesz musiała za każdym razem klikać przycisku "Oblicz" po wprowadzeniu nowych danych.
Wzory dla tych obliczeń przygotowała Agnieszka Majewska, opierając się na swoim wieloletnim doświadczeniu w branży.
`,
    shortDescription: '',
    author: 'Agnieszka Majewska',
    subscriptionType: SubscriptionType.PREMIUM,
    productViewPath: '/calculators/cake-icing',
    cardImagePath: '/szkolamajcake/kalkulatory/kalkulator-tynku.jpg'
  },
  {
    type: 'spongecake',
    calculatorID: 'calc33',
    title: 'Kalkulator do przeliczania biszkoptów',
    description: `Ten kalkulator służy do wyliczania ile i jakich składników potrzebujemy, aby upiec biszkopt danej wielkości.
Wystarczy, że wpiszesz poprawne wymagane dane do kalkulatora, a po kliknięciu przycisku "Oblicz" oraz wybraniu, któregoś z naszych biszkoptów,
natychmiast dostaniesz informację zwrotną. Kalkulatory dostępne są w 3 wersjach – do form okrągłych, prostokątnych i kwadratowych. 
Dodatkowo możesz skorzystać z opcji przeliczania automatycznego, dzięki czemu nie będziesz musiała za każdym razem klikać przycisku "Oblicz"
po wprowadzeniu nowych danych. Wzory dla tych obliczeń przygotowała Agnieszka Majewska,
opierając się na swoim wieloletnim doświadczeniu w branży.`,
    shortDescription: '',
    author: 'Agnieszka Majewska',
    subscriptionType: SubscriptionType.BASIC,
    productViewPath: '/calculators/sponge-cakes',
    cardImagePath: '/szkolamajcake/kalkulatory/kalkulator-biszkopty.jpg'
  },
  {
    type: 'cakeprice',
    calculatorID: 'calc44',
    title: 'Kalkulator do wyceny tortów',
    description:
      'Ten kalkulator służy do wyliczenia w przybliżeniu ceny pojedynczego tortu. Bierze pod uwagę cenę produktów spożywczych, dodatkowych wydatków, czas Twojej pracy, dostawę oraz wylicza koszty stałe na jeden tort w oparciu o Twoje dane. ' +
      'Wycenę możesz zapisać, aby móc do niej wrócić jeśli masz wykupioną subskrypcję, w wersji darmowej nie można zapisać wyceny. W subskrypcji Podstawowej możesz zapisać - 2, w Premium możesz zapisać 20.',
    shortDescription: '',
    author: 'Agnieszka Majewska',
    subscriptionType: SubscriptionType.PREMIUM,
    productViewPath: '/calculators/cake-price',
    cardImagePath: '/szkolamajcake/kursy/kalkulator-wycena.jpg'
  }
]
