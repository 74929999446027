export enum KitchenMeasure {
  KG = 'kg',
  G = 'g',
  SZT = 'szt'
}

export type KitchenMeasures =
  | KitchenMeasure.KG
  | KitchenMeasure.G
  | KitchenMeasure.SZT

export interface GroceryProductInterface {
  id: string
  name: string
  price: number
  quantityUsed: number
  quantityBought: number
  measure: KitchenMeasures
}

export interface AdditionalSpendingsInterface {
  id: string
  name: string
  price: number
  quantityUsed: number
  quantityBought: number
  measure: KitchenMeasures
}

export interface WorkingTimeInterface {
  id: string
  price: number
  quantity: number
}

export interface DeliveryTimeInterface {
  id: string
  pricePerKilometer: number
  distance: number
}

export interface FixedCostRow {
  id: string
  name: string
  price: number
}

export interface FixedCostsInterface {
  costs: FixedCostRow[]
  sweetsCount: number
}

export type UpdateAdditionalSpendingsConfig = {
  id: string
  inputName: string
  value: string | number
}

export type UpdateGroceryConfig = {
  id: string
  inputName: string
  value: string | number
}

export type UpdateFixedCostsConfig = {
  id: string
  inputName: string
  value: number | string
}

export type UpdateWorkingTime = {
  id: string
  propName: string
  value: number
}

export type UpdateDeliveryTime = {
  id: string
  propName: string
  value: number
}

export interface CakePriceCalcReducer {
  id: string
  pricingName: string
  quantity: number
  groceryProducts: GroceryProductInterface[]
  additionalSpending: AdditionalSpendingsInterface[]
  fixedCosts: FixedCostsInterface
  workingTime: WorkingTimeInterface
  deliveryTime: DeliveryTimeInterface
}

export enum ActionNames {
  ADD_GROCERY_PRODUCT = 'ADD_GROCERY_PRODUCT',
  REMOVE_GROCERY_PRODUCT = 'REMOVE_GROCERY_PRODUCT',
  UPDATE_GROCERY_PRODUCT = 'UPDATE_GROCERY_PRODUCT',
  ADD_ADDITIONAL_SPENDINGS = 'ADD_ADDITIONAL_SPENDINGS',
  REMOVE_ADDITIONAL_SPENDINGS = 'REMOVE_ADDITIONAL_SPENDINGS',
  UPDATE_ADDITIONAL_SPENDINGS = 'UPDATE_ADDITIONAL_SPENDINGS',
  ADD_FIXED_COST = 'ADD_FIXED_COST',
  UPDATE_FIXED_COST = 'UPDATE_FIXED_COST',
  REMOVE_FIXED_COST = 'REMOVE_FIXED_COST',
  UPDATE_FIXED_SWEET_COUNTER = 'UPDATE_FIXED_SWEET_COUNTER',
  UPDATE_WORKING_TIME = 'UPDATE_WORKING_TIME',
  UPDATE_DELIVERY_TIME = 'UPDATE_DELIVERY_TIME',
  UPDATE_CALCULATOR_DATA = 'UPDATE_CALCULATOR_DATA',
  UPDATE_CALCULATOR_PRODUCT_NAME = 'UPDATE_CALCULATOR_PRODUCT_NAME'
}

// Grocery Product
export type UPDATE_GROCERY_PRODUCT = {
  type: ActionNames.UPDATE_GROCERY_PRODUCT
  data: UpdateGroceryConfig
}
export type ADD_GROCERY_PRODUCT = { type: ActionNames.ADD_GROCERY_PRODUCT }
export type REMOVE_GROCERY_PRODUCT = {
  type: ActionNames.REMOVE_GROCERY_PRODUCT
  id: string
}

// Additional Spendings
export type UPDATE_ADDITIONAL_SPENDINGS = {
  type: ActionNames.UPDATE_ADDITIONAL_SPENDINGS
  data: UpdateGroceryConfig
}
export type ADD_ADDITIONAL_SPENDINGS = {
  type: ActionNames.ADD_ADDITIONAL_SPENDINGS
}
export type REMOVE_ADDITIONAL_SPENDINGS = {
  type: ActionNames.REMOVE_ADDITIONAL_SPENDINGS
  id: string
}

// Working time
export type UPDATE_WORKING_TIME = {
  type: ActionNames.UPDATE_WORKING_TIME
  data: UpdateWorkingTime
}

// Working time
export type UPDATE_DELIVERY_TIME = {
  type: ActionNames.UPDATE_DELIVERY_TIME
  data: UpdateDeliveryTime
}

// Fixed
export type UPDATE_FIXED_COST = {
  type: ActionNames.UPDATE_FIXED_COST
  data: UpdateGroceryConfig
}
export type ADD_FIXED_COST = { type: ActionNames.ADD_FIXED_COST }
export type REMOVE_FIXED_COST = {
  type: ActionNames.REMOVE_FIXED_COST
  id: string
}
export type UPDATE_FIXED_SWEET_COUNTER = {
  type: ActionNames.UPDATE_FIXED_SWEET_COUNTER
  value: number
}

export type UPDATE_CALCULATOR_DATA = {
  type: ActionNames.UPDATE_CALCULATOR_DATA
  data: CakePriceCalcReducer
}

export type UPDATE_CALCULATOR_PRODUCT_NAME = {
  type: ActionNames.UPDATE_CALCULATOR_PRODUCT_NAME
  data: string
}

export type Action =
  | UPDATE_GROCERY_PRODUCT
  | ADD_GROCERY_PRODUCT
  | REMOVE_GROCERY_PRODUCT
  | UPDATE_ADDITIONAL_SPENDINGS
  | ADD_ADDITIONAL_SPENDINGS
  | REMOVE_ADDITIONAL_SPENDINGS
  | UPDATE_FIXED_COST
  | ADD_FIXED_COST
  | REMOVE_FIXED_COST
  | UPDATE_FIXED_SWEET_COUNTER
  | UPDATE_WORKING_TIME
  | UPDATE_DELIVERY_TIME
  | UPDATE_CALCULATOR_DATA
  | UPDATE_CALCULATOR_PRODUCT_NAME
