import { BrowserRouter as Router } from 'react-router-dom'
import styles from './WholeAppLayout.module.css'
import { Sidebar } from '../../hoc/sidebar/Sidebar'
import { HeaderSection as SidebarHeader } from '../../components/sidebar/headerSection/HeaderSection'
import { ProductsSection } from '../../components/sidebar/ProductsSection/ProductsSection'
import { MainViewWrapper } from '../MainViewWrapper/MainViewWrapper'
import { UserDetails } from '../../components/sidebar/UserDetails/UserDetails'
import { Navbar } from '../../components/Navbar/Navbar'
import { MainViewRouting } from '../../routing/MainViewRouting'
import { Logout } from '../../components/sidebar/Logout/Logout'
import { SectionSeparator } from '../../components/UI/sectionSeparator/sectionSeparator'
import { CalculatorSection } from '../../components/sidebar/CalculatorSection/CalculatorSection'

export const WholeAppLayout = (): JSX.Element => (
  <div id='whole-app-layout' className={styles.CMSContainer}>
    <Router>
      <Navbar />

      <Sidebar>
        <SidebarHeader />
        <UserDetails />
        <ProductsSection />
        <CalculatorSection />
        <Logout />
      </Sidebar>

      <MainViewWrapper>
        <MainViewRouting />
        <SectionSeparator marginTop={40} marginBottom={20} />
      </MainViewWrapper>
    </Router>
  </div>
)
