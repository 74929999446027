import { FormEvent } from 'react'
import { Checkbox, CheckboxProps } from 'semantic-ui-react'
import styles from './CheckboxSimple.module.css'

interface Props {
  label: string
  onChangeHandler: (val: boolean) => void
  value: boolean
}

export const CheckboxSimple = ({
  label,
  onChangeHandler,
  value
}: Props): JSX.Element => (
  <div className={styles.CheckboxContainer}>
    <Checkbox
      checked={value}
      label={label}
      onChange={(
        input: FormEvent<HTMLInputElement>,
        checkboxProps: CheckboxProps
      ): void => onChangeHandler(!!checkboxProps.checked)}
    />
  </div>
)
