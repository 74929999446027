import { isStringNumeric } from '../../../../helpers/validators'
import { commasToDots } from '../../../../helpers/adapters/custom'

export const roundedCakeValidator = (height: string, d: string): string[] => {
  const errors: string[] = []
  const H = commasToDots(height)
  const D = commasToDots(d)

  if (!isStringNumeric(H)) {
    errors.push('Wartość w polu "wysokość tortu" nie jest wartością liczbową')
  }

  if (!isStringNumeric(D)) {
    errors.push('Wartość w polu "Średnica tortu" nie jest wartością liczbową')
  }

  if (typeof parseFloat(H) === 'number' && parseFloat(H) <= 0) {
    errors.push('Wartość w polu "wysokość tortu" musi być większa od 0')
  }

  if (typeof parseFloat(D) === 'number' && parseFloat(D) <= 0) {
    errors.push('Wartość w polu "Średnica tortu" musi być większa od 0')
  }

  return errors
}
