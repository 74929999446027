import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import {
  Dictionaries,
  DictionariesContext,
  getDictionariesReq,
  getSubscriptionsReq
} from './api'
import { UserReducerActions } from '../User/UserReducer'
import { checkIfUserSubscriptionTypeIsEnough } from '../../helpers/utils'
import { checkIfArrayHasSameElements } from '../../helpers/array/MyArray'
import {
  APP_DOMAIN_APP,
  FRAUD_WARNING,
  LOGOUT_WARNING,
  PLEASE_WAIT
} from '../../const/consts'
import { GlobalNotificationContext } from '../GlobalNotification/GlobalNotificationProvider'
import { GlobalLoaderContext } from '../GlobalLoader/GlobalLoader'
import { CookieStorageService } from '../../service/CookieStorageService'
import { VimeoHackingRadar } from '../../hoc/VimeoHackingRadar'
import { UserContext } from '../User/UserContext'
import { NotificationType } from '../GlobalNotification/Types'

const initialDictionary = {
  vimeoIds: [],
  subscriptions: []
}

const initialDictionaryContext: DictionariesContext = {
  dictionaries: {
    vimeoIds: [],
    subscriptions: []
  }
}

const CookieService = new CookieStorageService('token')

export const DictionaryContext = createContext<DictionariesContext>(
  initialDictionaryContext
)

export const DictionariesContextProvider = (props: {
  children: React.ReactNode
}): JSX.Element => {
  const [dictionaries, setDictionaries] =
    useState<Dictionaries>(initialDictionary)
  const userContext: UserReducerActions = useContext(UserContext)
  const showNotification = useContext(GlobalNotificationContext)
  const showLoader = useContext(GlobalLoaderContext)
  const { children: childComponents } = props
  useEffect((): void => {
    if (userContext.userDetails.id !== '' && !dictionaries.vimeoIds.length) {
      showLoader(true, PLEASE_WAIT)
      Promise.all([getDictionariesReq(), getSubscriptionsReq()])
        .then(data => {
          const vimeoIds = data[0]?.data?.vimeoIds
          const subs = data[1]?.data
          if (vimeoIds?.length > 0 && subs.length > 0) {
            showLoader(false)
            setDictionaries({ vimeoIds, subscriptions: subs })
          }
        })
        .catch(err => {
          showLoader(false, PLEASE_WAIT)
          console.log(err)
        })
    }
  }, [userContext.userDetails])

  const dictionaryToPass = useMemo(() => ({ dictionaries }), [dictionaries])

  return (
    <DictionaryContext.Provider value={dictionaryToPass}>
      <VimeoHackingRadar
        violationFoundCallback={(videoVimeoItem): void => {
          if (
            !checkIfUserSubscriptionTypeIsEnough(
              userContext.userDetails,
              videoVimeoItem.sub
            ) &&
            !checkIfArrayHasSameElements(
              userContext.userDetails.boughtProducts,
              [videoVimeoItem.productId]
            )
          ) {
            showNotification(NotificationType.ERROR, FRAUD_WARNING)
            CookieService.removeItem()
            showLoader(true, LOGOUT_WARNING)
            setTimeout((): void => {
              window.location.replace(`${APP_DOMAIN_APP}`)
            }, 6000)
          }
        }}
        idAdminUser={userContext.userDetails.role === 'admin'}
        dictionaries={dictionaries}
      >
        {/* {dictionaries?.vimeoIds.length === 0 && (<Loader text={PLEASE_WAIT} fullScreen show/>)} */}
        {childComponents}
      </VimeoHackingRadar>
    </DictionaryContext.Provider>
  )
}
