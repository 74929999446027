import styles from './AboutQuestions.module.scss'
import { HeaderWithIcon } from '../../UI/headers/CustomHeader/CustomHeader'
import { ParagraphDefault } from '../../UI/Paragraphs/ParagraphDefault/ParagraphDefault'
import {
  APP_DOMAIN_FACEBOOK,
  APP_DOMAIN_WIZYTOWKA
} from '../../../const/consts'
import { FaFacebookIcon } from '../../../Assets/ReactIcons'

export const AboutQuestions = (): JSX.Element => (
  <div className={styles.AboutQuestions_Container}>
    <HeaderWithIcon className={styles.Header}>
      Masz jakieś pytania?
    </HeaderWithIcon>
    <ParagraphDefault style={{ margin: '10px 0 0 0' }} size='bigger'>
      Przejdź do specjalnie zaprojektowanej sekcji FAQ, w której znajdziesz
      odpowiedzi na wszystkie często zadawane pytania -
      <strong>
        <a
          href={`${APP_DOMAIN_WIZYTOWKA}/pytania-i-odpowiedzi`}
          target='_blank'
          rel='noopener noreferrer'
        >
          {' '}
          Przejdz do sekcji FAQ
        </a>
      </strong>
    </ParagraphDefault>
    <ParagraphDefault size='bigger'>
      bądź skontaktuj się z nami poprzez{' '}
      <strong>
        <a
          href={`${APP_DOMAIN_FACEBOOK}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          {FaFacebookIcon}
        </a>{' '}
        lub e-mail{' '}
        <a href='mailto:kontakt@slodkaszkola.pl'>kontakt@slodkaszkola.pl</a>
      </strong>
    </ParagraphDefault>
  </div>
)
