import { useEffect, useMemo, useReducer } from 'react'
import { AdvancedImage, placeholder, responsive } from '@cloudinary/react'
import { Link } from 'react-router-dom'
import styles from '../../SharedCalculatorCss.module.scss'
import { InputTextLabeled } from '../../../UI/inputs/InputTextLabeled/InputTextLabeled'
import { ButtonByAdam } from '../../../UI/buttons/ButtonByAdam/ButtonByAdam'
import { HeaderWithIcon } from '../../../UI/headers/CustomHeader/CustomHeader'
import { ErrorListMessage } from '../../../UI/MessageComponents/ErrorListMessage/ErrorListMessage'
import { SuccessMessage } from '../../../UI/MessageComponents/SuccessMessage/SuccessMessage'
import {
  initialRectanglePortionsReducer,
  rectangleCakeReducer,
  RectanglePortionsActionNames
} from './RectanglePortionsReducer'
import { CalculatorOperations } from '../../../../helpers/cake'
import { Select } from '../../../UI/inputs/Select/Select'
import { frostingIngredients } from '../../../../helpers/dictionaries'
import { SuccessListMessage } from '../../../UI/MessageComponents/SuccessListMessage/SuccessListMessage'
import {
  CAKE_COMPOSITIONS,
  CALCULATOR_NAME,
  CHOOSE_TYPE_OF_CREAM,
  PROVIDE_CALCULATOR_HEIGHT,
  PROVIDE_CALCULATOR_LENGTH,
  PROVIDE_CALCULATOR_WIDTH
} from './consts'
import {
  CALCULATE,
  WRONG_VALUES_INSIDE_CALCULATOR
} from '../../../../const/consts'
import { displayCremeExpectations } from './utils'
import { ErrorMessage } from '../../../UI/MessageComponents/ErrorMessage/ErrorMessage'
import { SubscriptionType } from '../../../../service/Product/ProductService'
import { translateSubscriptionType } from '../../../../helpers/utils'
import { useCloudinary } from '../../../../hooks/useCloudinary'

interface Props {
  countAutomatic: boolean
  isAvailableForUser: boolean
  subType: SubscriptionType
}

export const RectangleCake = ({
  countAutomatic,
  isAvailableForUser,
  subType
}: Props): JSX.Element => {
  const [calculator, dispatch] = useReducer(
    rectangleCakeReducer,
    initialRectanglePortionsReducer
  )
  const { getImageInstance } = useCloudinary()

  const rectangleCakeImage = useMemo(() => {
    const imageInstance = getImageInstance(
      'szkolamajcake/kalkulatory/tort_prostokat_wzor'
    )

    return (
      <AdvancedImage
        cldImg={imageInstance}
        plugins={[responsive(), placeholder()]}
      />
    )
  }, [])

  const calculate = (): void =>
    dispatch({ type: RectanglePortionsActionNames.CALCULATE_RESULT })
  const updateCalculatorData = (data: CalculatorOperations): void =>
    dispatch({
      type: RectanglePortionsActionNames.UPDATE_RECTANGLE_PORTIONS_CALC_DATA,
      data
    })

  useEffect((): void => {
    if (countAutomatic && isAvailableForUser) {
      calculate()
    }
  }, [calculator.a, calculator.height, calculator.chosenIcing, calculator.b])

  return (
    <section className={styles.CalculatorContainer}>
      <div className={styles.Content_Inputs}>
        <HeaderWithIcon headerType='typical'>{CALCULATOR_NAME}</HeaderWithIcon>

        <InputTextLabeled
          type='number'
          titleColor='pink'
          title={PROVIDE_CALCULATOR_LENGTH}
          dataRequired
          disabled={!isAvailableForUser}
          value={calculator.a}
          placeholder={PROVIDE_CALCULATOR_LENGTH}
          onChangeHandler={(val): void =>
            updateCalculatorData({ fieldName: 'a', fieldValue: val })
          }
        />

        <InputTextLabeled
          type='number'
          titleColor='green'
          title={PROVIDE_CALCULATOR_WIDTH}
          dataRequired
          disabled={!isAvailableForUser}
          value={calculator.b}
          placeholder={PROVIDE_CALCULATOR_WIDTH}
          onChangeHandler={(val): void =>
            updateCalculatorData({ fieldName: 'b', fieldValue: val })
          }
        />

        <InputTextLabeled
          type='number'
          titleColor='blue'
          title={PROVIDE_CALCULATOR_HEIGHT}
          dataRequired
          disabled={!isAvailableForUser}
          value={calculator.height}
          placeholder={PROVIDE_CALCULATOR_HEIGHT}
          onChangeHandler={(val): void =>
            updateCalculatorData({ fieldName: 'height', fieldValue: val })
          }
        />

        <Select
          label={CHOOSE_TYPE_OF_CREAM}
          value={calculator.chosenIcing}
          placeholder={CHOOSE_TYPE_OF_CREAM}
          disabled={!isAvailableForUser}
          options={frostingIngredients}
          onChange={(val): void =>
            updateCalculatorData({
              fieldName: 'chosenIcing',
              fieldValue: val as string
            })
          }
        />

        {!isAvailableForUser && (
          <div className={styles.SubscriptionErrorMessage}>
            <ErrorMessage>
              Z kalkulatora mogą korzystać tylko użytkownicy o poziomie
              subskrypcji <strong>{translateSubscriptionType(subType)}</strong>.
              <strong>
                <Link to='/subskrypcje'>
                  {' '}
                  Zapoznaj się z dostępnymi możliwościami
                </Link>
              </strong>
            </ErrorMessage>
          </div>
        )}

        {!countAutomatic && (
          <ButtonByAdam
            disabled={!isAvailableForUser}
            btnType='premium'
            onClick={(): void => calculate()}
          >
            {CALCULATE}
          </ButtonByAdam>
        )}

        <div className={styles.ResultContainer}>
          {calculator.errors.length > 0 && (
            <ErrorListMessage
              title={WRONG_VALUES_INSIDE_CALCULATOR}
              errorList={calculator.errors}
            />
          )}
          {calculator.result && (
            <SuccessMessage>
              {displayCremeExpectations(calculator.result)}
            </SuccessMessage>
          )}
          {calculator.ingredients.length > 0 && (
            <SuccessListMessage
              title={CAKE_COMPOSITIONS}
              successList={calculator.ingredients}
            />
          )}
        </div>
      </div>

      <div className={styles.Content_Image}>{rectangleCakeImage}</div>
    </section>
  )
}
