import React from 'react'
import styles from './ExpandedStatus.module.scss'
import { FiChevronUpIcon, FiChevronDownIcon } from '../../../Assets/ReactIcons'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  onClickHandler?: () => void
  isExpanded: boolean
}

export const ExpandedStatus = ({
  onClickHandler,
  isExpanded,
  ...props
}: Props): JSX.Element => (
  <div
    role='presentation'
    {...props}
    onClick={(): void => {
      if (onClickHandler) {
        onClickHandler()
      }
    }}
    className={styles.Icon}
  >
    {isExpanded ? FiChevronUpIcon : FiChevronDownIcon}
  </div>
)
