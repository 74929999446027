import {
  Difficulty,
  SubscriptionTypes
} from '../../../../service/Product/ProductService'

export function getProductErrors(
  type: string,
  name: string,
  author: string,
  description: string,
  difficulty: Difficulty | '',
  subscription: SubscriptionTypes | '',
  price: number,
  courseDuration: string
): string[] {
  const errors = []

  if (type === '') {
    errors.push('Typ produktu nie jest wybrany')
  }

  if (name === '') {
    errors.push('Nazwa produktu nie jest wybrana')
  }

  if (author === '') {
    errors.push('Autor produktu nie jest wybrany')
  }

  if (description === '') {
    errors.push('Opis produktu nie jest wybrany')
  }

  if (difficulty === '') {
    errors.push('Poziom trudności produktu nie jest wybrany')
  }

  if (subscription === '') {
    errors.push('Poziom subskrypcji produktu nie jest wybrany')
  }

  if (price <= 0) {
    errors.push('Cena produktu nie jest wybrana lub jest błędna')
  }

  if (courseDuration === '') {
    errors.push('Długośc trwania kursu nie została wpisana')
  }

  return errors
}
