import { Product } from '../../service/Product/ProductService'

export enum ProductOperationsActionType {
  ADD = 'ADD',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE'
}

const PRODUCT_OPERATIONS_ACTION_TYPE = [
  ProductOperationsActionType.ADD,
  ProductOperationsActionType.DELETE,
  ProductOperationsActionType.UPDATE
] as const

export type ProductOperationsActionTypes =
  typeof PRODUCT_OPERATIONS_ACTION_TYPE[number]

export interface ProductHelpers {
  allProductsLength: number
  allCoursesLength: number
  allLivesLength: number
  allCourses: Product[]
  allLives: Product[]
}

export interface ProductOperations {
  actionType: ProductOperationsActionTypes
  product: Product
  productID: string
}

export interface ProductDataActions {
  products: Product[]
  helpers: ProductHelpers
  updateProductsData: () => void
  updateProduct: (productOperations: ProductOperations) => void
  getProductsByType: (type: string) => void
  getProductsFilteredByCategory: (category: string) => Product[]
}

export const initialProductHelpers = {
  allProductsLength: 0,
  allCoursesLength: 0,
  allLivesLength: 0,
  allCourses: [],
  allLives: []
}

export const initialProductState: ProductDataActions = {
  products: [],
  helpers: initialProductHelpers,
  updateProductsData: (): void => {
    // Initial value
  },
  updateProduct: (): void => {
    // Initial value
  },
  getProductsByType: (): Product[] => [],
  getProductsFilteredByCategory: (): Product[] => []
}

export enum ProductReducerActions {
  UPDATE_PRODUCTS_DATA = 'UPDATE_PRODUCTS_DATA',
  UPDATE_PRODUCT = 'UPDATE_PRODUCT'
}

export type ProductDataReducerActions =
  | {
      type: ProductReducerActions.UPDATE_PRODUCTS_DATA
      data: Product[]
    }
  | {
      type: ProductReducerActions.UPDATE_PRODUCT
      data: ProductOperations
    }

const updateProductsData = (
  data: Product[],
  state: ProductDataActions
): ProductDataActions => ({
  ...state,
  products: data
})

const updateProduct = (
  data: ProductOperations,
  state: ProductDataActions
): ProductDataActions => {
  const productsCopy = state.products.slice()
  if (data.actionType === ProductOperationsActionType.ADD) {
    productsCopy.push({ ...data.product })
    return {
      ...state,
      products: productsCopy
    }
  }
  if (data.actionType === ProductOperationsActionType.UPDATE) {
    const updatedProducts = productsCopy.map(
      (singleProduct: Product): Product => {
        if (singleProduct.id === data.productID) {
          return { ...data.product }
        }
        return singleProduct
      }
    )
    return {
      ...state,
      products: updatedProducts
    }
  }
  if (data.actionType === ProductOperationsActionType.DELETE) {
    return {
      ...state,
      products: productsCopy.filter(
        (singleProduct: Product): boolean => singleProduct.id !== data.productID
      )
    }
  }
  return { ...state }
}

export const productDataReducer = (
  state: ProductDataActions,
  action: ProductDataReducerActions
): ProductDataActions => {
  switch (action.type) {
    case ProductReducerActions.UPDATE_PRODUCTS_DATA:
      return updateProductsData(action.data, state)
    case ProductReducerActions.UPDATE_PRODUCT:
      return updateProduct(action.data, state)
    default:
      return state
  }
}
