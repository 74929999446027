import { ChangeEvent, useEffect, useState } from 'react'
import { TextArea as SemanticTextArea } from 'semantic-ui-react'
import styles from '../SharedInputStyles.module.scss'
import inputstyles from './TextArea.module.scss'

interface Props {
  label?: string
  onBlurHandler?: (val: string) => void
  onChangeHandler?: (value: string) => void
  placeholder: string
  value: string
  dataRequired?: boolean
}

export const TextArea = ({
  value,
  onBlurHandler,
  onChangeHandler,
  placeholder,
  label,
  dataRequired
}: Props): JSX.Element => {
  const [inputValue, setInputValue] = useState<string>(value || '')

  useEffect((): void => {
    if (value !== inputValue) {
      setInputValue(value)
    }
  }, [value])

  return (
    <div className={styles.InputContainer}>
      <span
        className={styles.Label}
        style={{ visibility: label ? 'visible' : 'hidden' }}
      >
        {label}
        {dataRequired && <span style={{ color: 'red' }}>*</span>}
      </span>
      <SemanticTextArea
        className={inputstyles.TextArea}
        value={inputValue}
        placeholder={placeholder}
        onChange={(event: ChangeEvent<HTMLTextAreaElement>): void => {
          setInputValue(event?.target?.value)
          if (onChangeHandler) {
            onChangeHandler(event?.target?.value)
          }
        }}
        onBlur={(): void => {
          if (onBlurHandler) {
            onBlurHandler(inputValue)
          }
        }}
        rows={3}
      />
    </div>
  )
}
