import { isStringNumeric } from '../../../../helpers/validators'
import { commasToDots } from '../../../../helpers/adapters/custom'

export const squareCakeValidator = (
  height: string,
  sideLength: string
): string[] => {
  const errors: string[] = []
  const H = commasToDots(height)
  const A = commasToDots(sideLength)

  if (!isStringNumeric(H)) {
    errors.push('Wartość w polu "wysokość tortu" nie jest wartością liczbową')
  }

  if (!isStringNumeric(A)) {
    errors.push('Wartość w polu "szerokość tortu" nie jest wartością liczbową')
  }

  if (typeof parseFloat(H) === 'number' && parseFloat(H) <= 0) {
    errors.push('Wartość w polu "wysokość tortu" musi być większa od 0')
  }

  if (typeof parseFloat(A) === 'number' && parseFloat(A) <= 0) {
    errors.push('Wartość w polu "szerokość tortu" musi być większa od 0')
  }

  return errors
}
