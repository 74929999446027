import { Product, INIT_PRODUCT } from '../../../service/Product/ProductService'
import { Lesson } from '../../../service/Product/lessonTypes'

export interface SingleField {
  data: Product
  fieldName: string
  fieldValue: string | number | boolean | string[]
}

export interface LessonReducer {
  operationType: 'ADD' | 'REMOVE' | 'UPDATE'
  lesson: Lesson
  lessonIndex?: number
}

export interface ProductFormDataActions {
  data: Product
  updateProductFormData: (product: Product) => void
  updateProductFormMainData: (singleField: SingleField) => void
}

export const initialProductFormState: ProductFormDataActions = {
  data: INIT_PRODUCT,
  updateProductFormData: (): void => {
    // Empty function
  },
  updateProductFormMainData: (): void => {
    // Empty function
  }
}

const updateProductFormData = (
  data: Product,
  state: ProductFormDataActions
): ProductFormDataActions => ({
  ...state,
  data
})

const updateProductFormMainData = (
  data: SingleField,
  state: ProductFormDataActions
): ProductFormDataActions => {
  if (
    data.fieldName === 'type' ||
    data.fieldName === 'name' ||
    data.fieldName === 'author' ||
    data.fieldName === 'description' ||
    data.fieldName === 'difficulty' ||
    data.fieldName === 'tags' ||
    data.fieldName === 'subscription' ||
    data.fieldName === 'courseDuration' ||
    data.fieldName === 'image' ||
    data.fieldName === 'price' ||
    data.fieldName === 'cardImagePath' ||
    data.fieldName === 'courseDuration' ||
    data.fieldName === 'invisibleProduct'
  ) {
    return {
      ...state,
      data: {
        ...data.data,
        [data.fieldName]: data.fieldValue
      }
    }
  }
  return { ...state }
}

const updateProductFormLessons = (
  data: LessonReducer,
  state: ProductFormDataActions
): ProductFormDataActions => {
  if (data.operationType === 'ADD') {
    const currentLessons = state.data.lessons.slice()
    currentLessons.push(data.lesson)
    return {
      ...state,
      data: {
        ...state.data,
        lessons: [...currentLessons]
      }
    }
  }
  if (
    data.operationType === 'UPDATE' &&
    typeof data.lessonIndex === 'number' &&
    typeof data.lesson
  ) {
    const updatedLessons = state.data.lessons.map(
      (singleLesson: Lesson, idx: number): Lesson => {
        if (idx === data.lessonIndex) {
          return { ...data.lesson }
        }
        return singleLesson
      }
    )
    return {
      ...state,
      data: {
        ...state.data,
        lessons: [...updatedLessons]
      }
    }
  }
  if (data.operationType === 'REMOVE' && typeof data.lessonIndex === 'number') {
    const updatedLessons = state.data.lessons.filter(
      (singleLesson: Lesson, idx: number): boolean => idx !== data.lessonIndex
    )
    return {
      ...state,
      data: {
        ...state.data,
        lessons: [...updatedLessons]
      }
    }
  }
  return { ...state }
}

export enum productFormDataActionNames {
  UPDATE_PRODUCT_FORM_DATA = 'UPDATE_PRODUCT_FORM_DATA',
  UPDATE_PRODUCT_FORM_MAIN_DATA = 'UPDATE_PRODUCT_FORM_MAIN_DATA',
  UPDATE_PRODUCT_FORM_LESSONS = 'UPDATE_PRODUCT_FORM_LESSONS'
}

export type productFormDataReducerAction =
  | {
      type: productFormDataActionNames.UPDATE_PRODUCT_FORM_DATA
      data: Product
    }
  | {
      type: productFormDataActionNames.UPDATE_PRODUCT_FORM_MAIN_DATA
      data: SingleField
    }
  | {
      type: productFormDataActionNames.UPDATE_PRODUCT_FORM_LESSONS
      data: LessonReducer
    }

export const productFormDataReducer = (
  state: ProductFormDataActions,
  action: productFormDataReducerAction
): ProductFormDataActions => {
  switch (action.type) {
    case productFormDataActionNames.UPDATE_PRODUCT_FORM_DATA:
      return updateProductFormData(action.data, state)
    case productFormDataActionNames.UPDATE_PRODUCT_FORM_MAIN_DATA:
      return updateProductFormMainData(action.data, state)
    case productFormDataActionNames.UPDATE_PRODUCT_FORM_LESSONS:
      return updateProductFormLessons(action.data, state)
    default:
      return state
  }
}
