import { ChangeEvent, useState } from 'react'
import { Input, InputOnChangeData } from 'semantic-ui-react'
import styles from './ProductDetails.module.scss'
import { Select } from '../../../UI/inputs/Select/Select'
import {
  productTypeOptions,
  tagsForSelect
} from '../../../../const/Dictionaries/product'
import { InputText } from '../../../UI/inputs/InputText/InputText'
import {
  difficultyTypes,
  subscriptionTypes
} from '../../../../helpers/dictionaries'
import { TextArea } from '../../../UI/inputs/TextArea/TextArea'
import { Product } from '../../../../service/Product/ProductService'
import { HeaderWithIcon } from '../../../UI/headers/CustomHeader/CustomHeader'
import { IoLogoYoutubeIcon } from '../../../../Assets/ReactIcons'
import { ExpandedStatus } from '../../../UI/ExpandedStatus/ExpandedStatus'
import { DropdownExampleMultipleSearchSelection } from '../../../UI/DropdownMultipleSearchSelection/DropdownMultipleSearchSelection'
import { CheckboxSimple } from '../../../UI/checkboxSimple/CheckboxSimple'
import { SingleField } from '../ProductFormReducer'

interface Props {
  updateFormMainDataHandler: (product: SingleField) => void
  productFormData: Product
}

export const ProductDetails = ({
  productFormData,
  updateFormMainDataHandler
}: Props): JSX.Element => {
  const [showProductDetails, setShowProductDetails] = useState<boolean>(true)

  return (
    <>
      <div className={styles.ProductDetailsHeaderWrapper}>
        <HeaderWithIcon reactIcon={IoLogoYoutubeIcon} className={styles.Header}>
          Dane Produktu
        </HeaderWithIcon>

        <ExpandedStatus
          isExpanded={showProductDetails}
          onClickHandler={(): void =>
            setShowProductDetails(!showProductDetails)
          }
        />
      </div>

      {showProductDetails && (
        <>
          <Select
            label='Typ kursu'
            value={productFormData.type}
            placeholder='Wybierz typ kursu'
            options={productTypeOptions}
            onChange={(val): void =>
              updateFormMainDataHandler({
                data: { ...productFormData },
                fieldName: 'type',
                fieldValue: val as string
              })
            }
          />

          <InputText
            label='Nazwa kursu'
            value={productFormData.name}
            placeholder='Podaj nazwę kursu'
            onBlurHandler={(val: string): void =>
              updateFormMainDataHandler({
                data: { ...productFormData },
                fieldName: 'name',
                fieldValue: val
              })
            }
            dataRequired
          />

          <DropdownExampleMultipleSearchSelection
            options={tagsForSelect}
            placeholder='Wybierz tagi'
            defaultValue={productFormData.tags}
            onBlurHandler={(val): void =>
              updateFormMainDataHandler({
                data: { ...productFormData },
                fieldName: 'tags',
                fieldValue: val as string[]
              })
            }
          />

          <InputText
            label='Długość trwania kursu'
            value={productFormData.courseDuration}
            placeholder='Wpisz długość trwania kursu'
            onBlurHandler={(val: string): void =>
              updateFormMainDataHandler({
                data: { ...productFormData },
                fieldName: 'courseDuration',
                fieldValue: val
              })
            }
            dataRequired
          />

          <Select
            label='Poziom trudności'
            value={productFormData.difficulty}
            placeholder='Wybierz poziom trudności'
            options={difficultyTypes}
            onChange={(val): void =>
              updateFormMainDataHandler({
                data: { ...productFormData },
                fieldName: 'difficulty',
                fieldValue: val as string
              })
            }
          />

          <Select
            label='Subskrypcja'
            value={productFormData.subscription}
            placeholder='Wybierz subskrypcję'
            options={subscriptionTypes}
            onChange={(val): void =>
              updateFormMainDataHandler({
                data: { ...productFormData },
                fieldName: 'subscription',
                fieldValue: val as string
              })
            }
          />

          <InputText
            label='Autor kursu'
            value={productFormData.author}
            placeholder='Autor kursu'
            onBlurHandler={(val: string): void =>
              updateFormMainDataHandler({
                data: { ...productFormData },
                fieldName: 'author',
                fieldValue: val
              })
            }
            dataRequired
          />

          <Input
            type='number'
            label='Cena kursu'
            value={productFormData.price}
            placeholder='Wpisz cenę kursu'
            onChange={(
              val: ChangeEvent<HTMLInputElement>,
              data: InputOnChangeData
            ): void => {
              updateFormMainDataHandler({
                data: { ...productFormData },
                fieldName: 'price',
                fieldValue: data.value
              })
            }}
          />

          <TextArea
            label='Krótki opis kursu'
            value={productFormData.description}
            placeholder='Opis'
            onBlurHandler={(val: string): void =>
              updateFormMainDataHandler({
                data: { ...productFormData },
                fieldName: 'description',
                fieldValue: val
              })
            }
            dataRequired
          />

          <InputText
            label='Obrazek na karcie'
            value={productFormData.cardImagePath}
            placeholder='Dodaj ścieżkę dla obrazku na karcie'
            onBlurHandler={(val: string): void =>
              updateFormMainDataHandler({
                data: { ...productFormData },
                fieldName: 'cardImagePath',
                fieldValue: val
              })
            }
            dataRequired
          />

          <CheckboxSimple
            label='Ukryj kurs'
            onChangeHandler={(val: boolean): void =>
              updateFormMainDataHandler({
                data: { ...productFormData },
                fieldName: 'invisibleProduct',
                fieldValue: val
              })
            }
            value={productFormData.invisibleProduct}
          />
        </>
      )}
    </>
  )
}
