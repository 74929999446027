import { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { SidebarSection } from '../../../hoc/sidebarSection/SidebarSection'
import { SidebarProducts, sidebarProducts } from '../../../const/crm-sidebar'
import { SingleItem } from '../singleItem/SingleItem'
import { ProductDataActions } from '../../../Context/Product/ProductDataReducer'
import { ProductDataContext } from '../../../Context/Product/ProductDataContext'
import { SidebarItems } from '../../../hoc/SidebarItems/SidebarItems'
import { HeaderWithIcon } from '../../UI/headers/CustomHeader/CustomHeader'
import { FaVideoIcon } from '../../../Assets/ReactIcons'

export const ProductsSection = (): JSX.Element => {
  const productsContext: ProductDataActions = useContext(ProductDataContext)

  return (
    <SidebarSection>
      <NavLink
        to='/products'
        className={({ isActive }) =>
          `nav-menu-header-item_clickable  ${
            isActive ? 'nav-menu-header-item_clickable_active' : ''
          }`
        }
      >
        <HeaderWithIcon reactIcon={FaVideoIcon}>
          {`Produkty (${productsContext.products.length})`}
        </HeaderWithIcon>
      </NavLink>
      <SidebarItems>
        <ul id='cms-sidebar-products'>
          {sidebarProducts.map(
            (product: SidebarProducts, index: number): JSX.Element => (
              <li key={`cms-sidebar-product-${index + 1}`}>
                <SingleItem
                  id={product.id}
                  className='nav-menu-item'
                  path={product.path}
                  activeClassName='nav-menu-item_active'
                >
                  {`${product.name} (${
                    productsContext.getProductsFilteredByCategory(
                      product.productCode
                    ).length
                  })`}
                </SingleItem>
              </li>
            )
          )}
        </ul>
      </SidebarItems>
    </SidebarSection>
  )
}
