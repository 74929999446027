import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer
} from 'react'
import { AxiosError } from 'axios'
import {
  initialUserReducer,
  UserOperations,
  userReducer,
  UserReducerActions
} from './UserReducer'
import { Product } from '../../service/Product/ProductService'
import { CookieStorageService } from '../../service/CookieStorageService'
import { UserDtoToFrontAdapter } from '../../helpers/adapters/user'
import { parseJwt } from '../../helpers/http'
import { GlobalNotificationContext } from '../GlobalNotification/GlobalNotificationProvider'
import { GlobalLoaderContext } from '../GlobalLoader/GlobalLoader'
import { checkIfAvailableToWatch, updateUser, updateUserValue } from './actions'
import { LoginPage } from '../../containers/LoginPage/LoginPage'
import { NotificationType } from '../GlobalNotification/Types'
import { checkTokenReq } from '../../service/User/api'
import { AxiosBackend } from '../../service/HTTP/AxiosInstance'

const CookieService = new CookieStorageService('token')

export const UserContext = createContext<UserReducerActions>(initialUserReducer)

export const UserContextProvider = (props: {
  children: ReactNode
}): JSX.Element => {
  const [userSettings, dispatch] = useReducer(userReducer, initialUserReducer)
  const showNotification = useContext(GlobalNotificationContext)
  const showLoader = useContext(GlobalLoaderContext)

  const { children: childComponents } = props
  useEffect((): void => {
    // Check if user has a valid token
    if (CookieService.getItem()) {
      checkTokenReq()
        .then(data => {
          const { token, expirationDate } = data
          if (token) {
            CookieService.setItem(token, expirationDate)
            const { user } = parseJwt(token)
            updateUser(UserDtoToFrontAdapter(user), dispatch)
          }
        })
        .catch(undefined)
    }
  }, [])

  // Interceptor to update user and token if token is added to response
  useEffect((): (() => void) => {
    const toEject = AxiosBackend.interceptors.response.use(
      response => {
        const token = response && response.data && response.data.token
        if (token) {
          const { expirationDate } = response.data
          CookieService.setItem(token, expirationDate)
          const { user } = parseJwt(token)
          updateUser(UserDtoToFrontAdapter(user), dispatch)
        }
        return response
      },
      (error: AxiosError): Promise<void> => {
        if (error.response) {
          const statusCode = error.response.status
          if (statusCode === 498 && CookieService.getItem()) {
            showNotification(
              NotificationType.INFO,
              'Skończyła się Twoja sesja, zaloguj się jeszcze raz'
            )
            showLoader(true, 'Przekierowuję do ekranu logowania...')
            CookieService.removeItem()
            setTimeout((): void => {
              window.location.replace('/')
            }, 2000)
          }
        }
        return Promise.reject(error.response)
      }
    )
    return (): void => AxiosBackend.interceptors.response.eject(toEject)
  }, [])

  const userDetails = useMemo(() => userSettings.userDetails, [userSettings])

  const updateUserHandler = useCallback(
    () => updateUser(userSettings.userDetails, dispatch),
    [userSettings]
  )

  const updateUserValueHandler = useCallback(
    (userOperations: UserOperations): void =>
      updateUserValue(userOperations, dispatch),
    []
  )

  const checkIfAvailableToWatchHandler = useCallback(
    (product: Product): boolean =>
      checkIfAvailableToWatch(product, userSettings.userDetails),
    [userSettings]
  )

  const contextValue = useMemo(
    () => ({
      userDetails,
      updateUser: updateUserHandler,
      updateUserValue: updateUserValueHandler,
      checkIfAvailableToWatch: checkIfAvailableToWatchHandler
    }),
    [userSettings]
  )

  return (
    <UserContext.Provider value={contextValue}>
      {userSettings.userDetails.id === '' ? <LoginPage /> : childComponents}
    </UserContext.Provider>
  )
}
