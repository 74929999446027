import styles from './StandardView.module.scss'
import productRowStyles from '../AdditionalSpendingsRow.module.scss'
import {
  calculateProductPrice,
  CalculatorPriceInputNumberValidator
} from '../../../utils'
import {
  AdditionalSpendingsInterface,
  UpdateAdditionalSpendingsConfig
} from '../../../CakePriceCalculatorContext/Reducer/interfaces'
import { InputNumber } from '../../../../../UI/inputs/InputNumber/InputNumber'

interface Props {
  product: AdditionalSpendingsInterface
  index: number
  onChangeHandler: (data: UpdateAdditionalSpendingsConfig) => void
  removeRowHandler: (id: string) => void
  changeView: () => void
}

export const StandardView = ({
  product,
  index,
  onChangeHandler,
  removeRowHandler,
  changeView
}: Props): JSX.Element => (
  <div className={styles.StandardViewContainer}>
    <div className={`${styles.SectionContainer} ${styles.NameSection}`}>
      <p className={productRowStyles.IndexStandard}>{`${index + 1}.`}</p>
      <div className={styles.ProductName}>{product.name}</div>
      <div className={styles.InputContainer}>
        <InputNumber
          type='number'
          value={product.quantityUsed}
          placeholder='Kupiona ilość...'
          onChangeHandler={(value: number): void =>
            onChangeHandler({
              id: product.id,
              inputName: 'quantityUsed',
              value
            })
          }
          label={product.measure}
          validateFnc={CalculatorPriceInputNumberValidator}
        />
      </div>
    </div>
    <div className={styles.SectionContainer}>
      <div className={productRowStyles.Price}>{`${calculateProductPrice(
        product
      )} pln`}</div>
      <div
        role='presentation'
        onClick={changeView}
        className={productRowStyles.EditText}
      >
        edytuj
      </div>
      <div
        role='presentation'
        onClick={(): void => removeRowHandler(product.id)}
        className={productRowStyles.RemoveText}
      >
        usuń
      </div>
    </div>
  </div>
)
