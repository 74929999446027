import { memo, useState } from 'react'
import {
  GroceryProductInterface,
  UpdateGroceryConfig
} from '../../CakePriceCalculatorContext/Reducer/interfaces'
import { StandardView } from './StandardView/StandardView'
import { ConfigView } from './ConfigView/ConfigView'

interface Props {
  product: GroceryProductInterface
  index: number
  onChangeHandler: (data: UpdateGroceryConfig) => void
  removeRowHandler: (id: string) => void
}

const SingleGroceryRandomProductRow = ({
  product,
  index,
  onChangeHandler,
  removeRowHandler
}: Props): JSX.Element => {
  const [showConfig, setShowConfig] = useState<boolean>(false)

  return (
    <div>
      {showConfig ? (
        <ConfigView
          product={product}
          index={index}
          onChangeHandler={onChangeHandler}
          removeRowHandler={removeRowHandler}
          changeView={(): void => setShowConfig(!showConfig)}
        />
      ) : (
        <StandardView
          product={product}
          index={index}
          onChangeHandler={onChangeHandler}
          removeRowHandler={removeRowHandler}
          changeView={(): void => setShowConfig(!showConfig)}
        />
      )}
    </div>
  )
}

function rowPropsAreEqual(): boolean {
  return false
}

export const MemoizedSingleGroceryProductRow = memo(
  SingleGroceryRandomProductRow,
  rowPropsAreEqual
)
