import { SubscriptionType, SubscriptionTypes } from '../Product/ProductService'

export enum UserRole {
  ADMIN = 'admin',
  USER = 'user'
}

export const USER_ROLE_TYPES = [UserRole.USER, UserRole.ADMIN] as const

export type UserRoleType = typeof USER_ROLE_TYPES[number]

export interface SubscriptionData {
  subscriptionId: string
  subType: string
  planId: string
  customerId: string
  items: unknown
  trialEnd: Date | null
  currentPeriodStart: Date | null
  currentPeriodEnd: Date | null
  status: string
  canceledAt: Date | null
  cancel_at_period_end: boolean | null
}

export interface SubscriptionCharge {
  subscriptionType: SubscriptionType
  created: number
  duration: number
  index: number
}

export interface User {
  id: string
  stripeCustomerId: string
  name: string
  surname: string
  email: string
  subscriptionType: SubscriptionTypes
  boughtProducts: string[]
  role: UserRoleType
  subscriptionData?: SubscriptionData[]
  subscriptionCharges?: SubscriptionCharge[]
}

export interface UserDto {
  _id: string
  stripeCustomerId: string
  name: string
  surname: string
  email: string
  subscriptionType: SubscriptionTypes
  boughtProducts: string[]
  role: UserRoleType
  subscriptionData?: SubscriptionData[]
  subscriptionCharges?: SubscriptionCharge[]
}

export interface LoginData {
  username: string
  password: string
}

export interface SignupData {
  username: string
  password: string
  name: string
  surname: string
  email: string
}

export interface TokenResponse {
  token: string
  expirationDate: Date
}

export type PatchUser = Pick<User, 'name' | 'surname' | 'email'>

export const INIT_USER: User = {
  id: '',
  stripeCustomerId: '',
  name: '',
  surname: '',
  email: '',
  subscriptionType: SubscriptionType.FREE,
  boughtProducts: [],
  role: UserRole.USER
}
