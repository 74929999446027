import { AdvancedImage } from '@cloudinary/react'
import styles from './ProductImage.module.css'
import { LessonImageData } from '../../../../../service/Product/lessonTypes'
import { useCloudinary } from '../../../../../hooks/useCloudinary'

interface Props {
  imgData: LessonImageData
}

export const ProductImage = ({ imgData }: Props): JSX.Element => {
  const { getImageInstance } = useCloudinary()
  const productImage = getImageInstance(imgData.path)

  return (
    <div className={styles.ImageContainer}>
      <AdvancedImage cldImg={productImage} />
    </div>
  )
}
