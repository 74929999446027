import { Embed } from 'semantic-ui-react'
import { createCloudinaryUrlForVideoPlaceholder } from '../../../helpers/utils'

interface Props {
  id: string
  placeholderImageUrl: string
  source: 'vimeo' | 'youtube'
}

export const EmbedMovie = ({
  id,
  placeholderImageUrl,
  source
}: Props): JSX.Element => (
  <Embed
    id={id}
    placeholder={createCloudinaryUrlForVideoPlaceholder(placeholderImageUrl)}
    source={source}
    iframe={{
      allowFullScreen: true
    }}
  />
)
