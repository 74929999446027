import React from 'react'
import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem'
import { Icon, Label } from 'semantic-ui-react'
import styles from './PricingPills.module.scss'
import { getPricing } from '../../API/mock'
import { CakePriceCalcReducer } from '../../CakePriceCalculatorContext/Reducer/interfaces'

interface Props {
  pricingPillsData: DropdownItemProps[]
  setCurrentPricing: (data: CakePriceCalcReducer) => void
  setAvaliablePricings: (data: string) => void
  copyPricingHandler: () => void
  activePillId: string
}

export const PricingPills = ({
  pricingPillsData,
  setCurrentPricing,
  setAvaliablePricings,
  copyPricingHandler,
  activePillId
}: Props): JSX.Element => (
  <div className={styles.Container}>
    <Label.Group>
      {pricingPillsData.map(item => (
        <Label
          color={activePillId === item.value ? 'yellow' : 'blue'}
          className={styles.Label}
          onClick={(e: React.MouseEvent<HTMLElement>): void => {
            e.stopPropagation()
            const targetNodeName = e.currentTarget.nodeName
            if (targetNodeName === 'DIV') {
              getPricing(item.value as string).then(response => {
                if (response.status === 200) {
                  setCurrentPricing(response.data)
                }
              })
            }
          }}
          key={`${item.key}`}
          size='huge'
        >
          {item.text}
          <span className={styles.IconContainer}>
            <Icon
              onClick={(e: React.MouseEvent<HTMLElement>): void => {
                e.stopPropagation()
                const targetNodeName = e.currentTarget.nodeName
                if (targetNodeName === 'I') {
                  setAvaliablePricings(item.value as string)
                }
              }}
              name='close'
            />
          </span>
          <span className={styles.IconContainer}>
            <Icon
              onClick={(e: React.MouseEvent<HTMLElement>): void => {
                e.stopPropagation()
                const targetNodeName = e.currentTarget.nodeName
                if (targetNodeName === 'I') {
                  copyPricingHandler()
                }
              }}
              name='copy'
            />
          </span>
        </Label>
      ))}
    </Label.Group>
  </div>
)
