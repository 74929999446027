import { Link } from 'react-router-dom'
import styles from './ErrorPage.module.scss'
import { DefaultHeader } from '../../components/UI/headers/DefaultHeader/DefaultHeader'
import { ParagraphDefault } from '../../components/UI/Paragraphs/ParagraphDefault/ParagraphDefault'
import { ButtonByAdam } from '../../components/UI/buttons/ButtonByAdam/ButtonByAdam'

export const ErrorPage = (): JSX.Element => (
  <div className={styles.MainContainer}>
    <div className={styles.ErrorContent}>
      <div className={styles.ContentWave}>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'>
          <path
            fill='#1156bb'
            fillOpacity='1'
            d='M0,64L30,80C60,96,120,128,180,138.7C240,149,300,139,360,133.3C420,128,480,128,540,149.3C600,171,660,213,720,218.7C780,224,840,192,900,176C960,160,1020,160,1080,176C1140,192,1200,224,1260,197.3C1320,171,1380,85,1410,42.7L1440,0L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z'
          />
        </svg>
      </div>
      <DefaultHeader className={styles.ContentTitle}>
        4<span>0</span>4
      </DefaultHeader>
      <ParagraphDefault className={styles.ContentSubtitle}>
        Strony nie znaleziono :(
      </ParagraphDefault>
      <Link to='/'>
        <ButtonByAdam btnType='blue'>Strona główna</ButtonByAdam>
      </Link>
    </div>
  </div>
)
