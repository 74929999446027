import { Markup } from 'interweave'
import styles from './ProductParagraph.module.css'

interface Props {
  text: string
}

export const ProductParagraph = ({ text }: Props): JSX.Element => (
  <p className={styles.Paragraph}>
    <Markup content={text} />
  </p>
)
