import styles from './AuthorRow.module.scss'

interface Props {
  author: string
}

export const AuthorRow = ({ author }: Props): JSX.Element => (
  <div className={styles.AuthorContainer}>
    <span>Autor: </span>
    <span style={{ fontWeight: 'bold' }}>{author}</span>
  </div>
)
