import axios from "axios";

export function getUrlToHit(env: string) {
    if(!env) {
        return 'http://localhost:5000/api/';
    }
    if (env === 'production') {
        return 'https://api.slodkaszkola.pl/api/'
    }
    if (env === 'development' || env === 'preview') {
        return 'https://test.slodkaszkola.pl/api/'
    }
    return 'http://localhost:5000/api/'
}

export const AxiosBackend = axios.create({
    baseURL: getUrlToHit(process.env.REACT_APP_VERCEL_ENV as string),
    withCredentials: true,
});
