interface Button {
  text: string
  type: 'green' | 'red' | 'blue-invert' | 'premium-invert'
}

export interface Bullet {
  text: string
}

export interface SubOffer {
  type: 'basic' | 'premium'
  price: string
  period: string
  name: string
  bullets: Bullet[]
  button: Button
}

export const basicSubscription: SubOffer = {
  type: 'basic',
  name: 'Podstawowy',
  price: '69,90',
  period: 'miesięcznie',
  bullets: [
    {
      text: 'Nieograniczony dostęp do wszystkich kursów online typu PODSTAWOWY'
    },
    { text: 'Dostęp do kalkulatorów cukierniczych typu PODSTAWOWY' },
    {
      text: 'Nielimitowany dostęp do zaktualizowanych wersji kursów online typu PODSTAWOWY'
    },
    {
      text: 'Dostęp do prywatnej grupy na FB gdzie odpowiadamy na pytania dotyczące materiału ze szkoleń i ogólnie tematyki cukierniczej'
    },
    { text: 'Minimum 1 nowy kurs online typu PODSTAWOWY w miesiącu' },
    { text: 'Zniżki w partnerskich firmach' }
  ],
  button: {
    text: 'Wybierz plan',
    type: 'blue-invert'
  }
}

export const premiumSubscription: SubOffer = {
  type: 'premium',
  name: 'Premium',
  price: '99,90',
  period: 'miesięcznie',
  bullets: [
    {
      text: 'Nieograniczony dostęp do wszystkich kursów online na naszej stronie'
    },
    { text: 'Nieograniczony dostęp do wszystkich kalkulatorów cukierniczych' },
    {
      text: 'Nielimitowany dostęp do zaktualizowanych wersji wszystkich kursów'
    },
    {
      text: 'Dostęp do prywatnej grupy na FB gdzie odpowiadamy na pytania dotyczące materiału ze szkoleń i ogólnie tematyki cukierniczej'
    },
    { text: 'Dostęp do wszystkich nowych kursów na naszej stronie' },
    { text: 'Zniżki w partnerskich firmach' },
    {
      text: 'Zniżki na szkolenia cukiernicze organizowane przez współpracujące firmy'
    }
  ],
  button: {
    text: 'Wybierz plan',
    type: 'premium-invert'
  }
}
