import {
  getRectangleCakePortionsMax,
  CalculatorOperations,
  getRectangleCakePortionsMin
} from '../../../../helpers/cake'
import { rectangleCakeValidator } from './RectangleCakeValidator'

interface Result {
  MIN: number
  MAX: number
}

interface RectanglePortionsCalculatorActions {
  height: string
  a: string
  b: string
  errors: string[]
  result: Result | null
  calculate: () => void
}

export const initialRectanglePortionsReducer: RectanglePortionsCalculatorActions =
  {
    height: '',
    a: '',
    b: '',
    errors: [],
    result: null,
    calculate: (): void => {
      // INITIAL VALUE
    }
  }

const updateValidationErrors = (
  errors: string[],
  state: RectanglePortionsCalculatorActions
): RectanglePortionsCalculatorActions => ({
  ...state,
  errors,
  result: null
})

const calculateResult = (
  state: RectanglePortionsCalculatorActions
): RectanglePortionsCalculatorActions => ({
  ...state,
  result: {
    MIN: Math.round(
      getRectangleCakePortionsMin(
        parseFloat(state.height),
        parseFloat(state.a),
        parseFloat(state.b)
      )
    ),
    MAX: Math.round(
      getRectangleCakePortionsMax(
        parseFloat(state.height),
        parseFloat(state.a),
        parseFloat(state.b)
      )
    )
  },
  errors: []
})

export const CALCULATE_RESULT = 'CALCULATE_RESULT'
export const UPDATE_RECTANGLE_PORTIONS_CALC_DATA =
  'UPDATE_RECTANGLE_PORTIONS_CALC_DATA'

export enum RectanglePortionsReducerActionName {
  CALCULATE_RESULT = 'CALCULATE_RESULT',
  UPDATE_RECTANGLE_PORTIONS_CALC_DATA = 'UPDATE_RECTANGLE_PORTIONS_CALC_DATA'
}

export type RectanglePortionsReducerActions =
  | {
      type: RectanglePortionsReducerActionName.CALCULATE_RESULT
    }
  | {
      type: RectanglePortionsReducerActionName.UPDATE_RECTANGLE_PORTIONS_CALC_DATA
      data: CalculatorOperations
    }

const calculate = (
  state: RectanglePortionsCalculatorActions
): RectanglePortionsCalculatorActions => {
  const validationErrors = rectangleCakeValidator(
    state.height,
    state.a,
    state.b
  )
  if (validationErrors.length > 0) {
    return updateValidationErrors(validationErrors, state)
  }
  return calculateResult(state)
}

const updateCalcData = (
  data: CalculatorOperations,
  state: RectanglePortionsCalculatorActions
): RectanglePortionsCalculatorActions => ({
  ...state,
  [data.fieldName]: data.fieldValue
})

export const rectangleCakeReducer = (
  state: RectanglePortionsCalculatorActions,
  action: RectanglePortionsReducerActions
): RectanglePortionsCalculatorActions => {
  switch (action.type) {
    case RectanglePortionsReducerActionName.CALCULATE_RESULT:
      return calculate(state)
    case RectanglePortionsReducerActionName.UPDATE_RECTANGLE_PORTIONS_CALC_DATA:
      return updateCalcData(action.data, state)
    default:
      return state
  }
}
