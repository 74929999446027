import { useState } from 'react'
import { AdvancedImage } from '@cloudinary/react'
import styles from './LoginPage.module.scss'
import { Authenticate } from './Authenticate/Authenticate'
import { Signup } from './Signup/Signup'
import { ModalFancy } from '../../components/UI/Modals/ModalFancy/ModalFancy'
import { SimpleFooter } from '../../components/footer/SimpleFooter'
import { RestorePassword } from './RestorePassword/RestorePassword'
import { getQueryParamsFromLocationSearch } from '../../helpers/http'
import { ResetPasswordModal } from '../../components/ResetPasswordModal/ResetPasswordModal'
import { RecoverPasswordModal } from '../../components/RecoverPasswordModal/RecoverPasswordModal'
import { WebsiteDescription } from './WebsiteDescription/WebsiteDescription'
import { LOGIN_PAGE_PRODUCT_DESCRIPTION } from '../../const/consts'
import { IntroductionVideo } from './IntroductionVideo/IntroductionVideo'
import { FeaturesList } from './FeaturesList/FeaturesList'
import { LoginPageType, LoginPageTypes } from './LoginPageTypes'
import { useCloudinary } from '../../hooks/useCloudinary'

export const LoginPage = (): JSX.Element => {
  const [formType, setFormType] = useState<LoginPageTypes>(LoginPageType.LOGIN)
  const { getImageInstance } = useCloudinary()
  const logoSlodka = getImageInstance(
    'szkolamajcake/logo/logo_slodka_szkola.png'
  )

  const { token, type } = getQueryParamsFromLocationSearch(
    window.location.search
  )
  return (
    <div className={styles.LoginPageContainer}>
      <div className={styles.ImageContainer}>
        <a
          style={{ cursor: 'pointer' }}
          target='_blank'
          rel='noopener noreferrer'
          href='https://slodkaszkola.pl/'
        >
          <AdvancedImage cldImg={logoSlodka} />
        </a>
      </div>

      {token && type === 'recovery' && <RecoverPasswordModal token={token} />}
      {token && type === 'reset' && <ResetPasswordModal token={token} />}
      {!token && (
        <div className={styles.InfoSectionsContainer}>
          <IntroductionVideo />
          <ModalFancy>
            <>
              <WebsiteDescription
                productDescription={LOGIN_PAGE_PRODUCT_DESCRIPTION}
              />
              {formType === 'LOGIN' && (
                <Authenticate changeFormHandler={setFormType} />
              )}
              {formType === 'SIGNUP' && (
                <Signup changeFormHandler={setFormType} />
              )}
              {formType === 'RESTORE' && (
                <RestorePassword changeFormHandler={setFormType} />
              )}
            </>
          </ModalFancy>
          <div>
            <FeaturesList />
          </div>
        </div>
      )}

      <div className={styles.FooterContainer}>
        <SimpleFooter>
          <p>
            Copyright &copy; 2022 Słodka Szkoła{' '}
            <a
              className={styles.Link}
              target='_blank'
              rel='noopener noreferrer'
              href='https://slodkaszkola.pl/regulamin'
            >
              Regulamin
            </a>{' '}
            |{' '}
            <a
              className={styles.Link}
              target='_blank'
              rel='noopener noreferrer'
              href='https://slodkaszkola.pl/polityka-prywatnosci'
            >
              Polityka prywatności
            </a>
          </p>
        </SimpleFooter>
      </div>
    </div>
  )
}
