import {getUrlToHit} from "../service/HTTP/AxiosInstance";

export const PRODUCTS_PER_PAGE = 12
export const CONTACT_EMAIL = 'kontakt@slodkaszkola.pl'
// eslint-disable-next-line no-undef
export const APP_DOMAIN_APP = getUrlToHit(process.env.REACT_APP_VERCEL_ENV as string);
// eslint-disable-next-line no-undef
export const APP_DOMAIN_WIZYTOWKA =
  process.env.REACT_APP_VERCEL_ENV === 'production'
    ? 'https://slodkaszkola.pl'
    : 'https://slodkaszkola.pl'

export const APP_DOMAIN_FACEBOOK = 'https://www.facebook.com/SlodkaSzkola/'

export enum SUBSCRIPTION_TYPES {
  PREMIUM = 'premium',
  BASIC = 'basic',
  FREE = 'free'
}

export function getProperAppUrl() {
  const env = process.env.REACT_APP_VERCEL_ENV
  if (env === 'production') {
    return 'https://app.slodkaszkola.pl/'
  }
  if (env === 'development' || env === 'preview') {
    return 'https://maj-courses-cms.vercel.app/'
  }
  return 'http://localhost:3000/'
}

export const HOUR_IN_MILISECONDS = 1000 * 60 * 60
export const DAY_IN_MILISECONDS = HOUR_IN_MILISECONDS * 24
export const authCookieMaxAge = HOUR_IN_MILISECONDS * 4 // 4h

export const SUBSCRIPTION_IDS = {
  premium_180: '62d3f76a4b88d74d14c017d5',
  basic_180: '62d43fcbec04703808946ea1'
}

export const USER_CREATED_SUCCESSFULLY =
  'Użytkownik został poprawnie stworzony. Udanej nauki!'
export const LOGIN_PAGE_PRODUCT_DESCRIPTION =
  'Platforma szkoleniowa z kursami online dzięki, którym nauczysz się prawidłowego tworzenia tortów od podstaw w domowym zaciszu kiedy tylko chcesz'
export const INTRODUCTION_VIDEO_YOUTUBE_URL =
  'https://www.youtube.com/embed/_IoJSoO-Yr0'
export const PLATFORM_BENEFITS: string[] = [
  'Kilkadziesiąt kursów o tematyce tortowej dla początkujących, jak i doświadczonych osób',
  'Kalkulatory cukiernicze pomagające w codziennej pracy',
  'Kalkulator do wyceny tortów z możliwością ich zapisu na swoim koncie!',
  'Archiwalne nagrania na żywo naszych instruktorów',
  'Zniżki na narzędzia i produkty cukiernicze u partnerskich firm',
  'Zniżki na szkolenia stacjonarne dla naszych subskrybentów',
  'Dostęp do specjalnej grupy na FB, gdzie każdy uzyska szybką poradę, jeśli napotka problem podczas pracy nad tortami'
]

export const PLEASE_WAIT = 'Poczekaj proszę...'
export const LOGOUT_WARNING = 'Za chwilę zostaniesz wylogowana z aplikacji...'
export const FRAUD_WARNING =
  'Wykryliśmy próbę oszustwa. Prosimy zaprestać takich działań. Jeżeli uważasz, że komunikat wyskakuje bez' +
  ' przyczyny - daj nam znać. Dziękujemy!'

// CALCULATORS
export const CALCULATE = 'Oblicz'
export const WRONG_VALUES_INSIDE_CALCULATOR = 'Błędne wartości w kalkulatorze'

// ERRORS
export const STRIPE_NOT_DEFINED = 'Stripe is not defined'

// STYLES
export const NAVLINK_CLASSNAME = 'nav-menu-header-item_clickable'
export const ACTIVE_NAVLINK_CLASSNAME = 'nav-menu-header-item_clickable_active'
