export enum SAVED_PRICINGS_COUNT {
  FREE = 2,
  BASIC = 10,
  PREMIUM = 50
}

export const MAX_PRICE_PER_KILOMETER_DELIVERY = 400

export const GROCERY_PRODUCTS_DESCRIPTION =
  'W tej sekcji wpisz wszystkie produkty spożywcze użyte do zrobienia tortu.  ' +
  'Na pewno uwzględnij składniki: biszkoptu, kremu, nasączenia, dodatków typu chrupka czy żelka, kremu do tynkowania, dripu, owoców itp. Masz do wyboru miarę w gramach, kilogramach i sztukach.'

export const ADDITIONAL_SPENDINGS_DESCRIPTION =
  'W tej sekcji wpisz wszystkie dodatkowe wydatki typu: podkład, pudełko na tort, kwiaty do dekoracji, figurka, topper, wsporniki itp. Masz do wyboru miarę w gramach, kilogramach i sztukach.'

export const WORKING_TIME_DESCRIPTION =
  'W tej sekcji wpisz ile chcesz zarabiać za godzinę swojej pracy. ' +
  'Pamiętaj, że minimalna krajowa na rękę wynosi na ten moment 12 zł za godzinę, a wątpię abyś chciała/chciał tyle zarabiać przy pracy, jaką jest tworzenie tortów. ' +
  'Pamiętaj też, że np. rozmowa z klientem, ustalanie szczegółów, mycie i sprzątanie, czy wyprawa na zakupy to też Twój czas roboczy, więc w drugiej rubryce wpisz ile faktycznie czasu poświęciłaś/łeś na to zamówienie.'

export const DELIVERY_PRICE_DESCRIPTION =
  'Jeśli dostarczasz swoje torty wpisz swoją cenę za kilometr oraz przebyty dystans w obydwie strony. Pamiętaj, że koszt dostawy to nie tylko paliwo, które zużyjesz, a też eksploatacja samochodu oraz Twój czas, który jest najcenniejszy.'

export const FIXED_COSTS_DESCRIPTION =
  'W tej sekcji wypisz wszystkie koszty stałe, które ponosisz miesięcznie przy produkcji tortów tj.  prąd, woda, ogrzewanie, wynajem lokalu, reklama, księgowość, telefon, internet, utrzymanie strony internetowej itp., a na końcu podaj' +
  ' szacunkową ilość tortów, które robisz miesięcznie. Uzyskasz dzięki temu stałą kwotę, która powinna być doliczana do tortu.'
