export class ArrayUtilsByAdam<T> {
  private myArray: T[]

  constructor(myArray: T[]) {
    this.myArray = myArray.slice()
  }

  swapArrayItems(indexA: number, indexB: number): T[] {
    const myArrayCopy: T[] = this.myArray.slice()
    const temp = myArrayCopy[indexA]
    myArrayCopy[indexA] = myArrayCopy[indexB]
    myArrayCopy[indexB] = temp
    return myArrayCopy.slice()
  }
}

export const checkIfArrayHasSameElements = (
  arr1: string[],
  array2: string[]
): boolean => arr1.some((item: string) => array2.includes(item))
