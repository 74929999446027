import { Link } from 'react-router-dom'
import styles from './LinksContainer.module.scss'
import { ParagraphDefault } from '../../UI/Paragraphs/ParagraphDefault/ParagraphDefault'

export const LinksContainer = (): JSX.Element => (
  <div className={styles.Links_Container}>
    <Link id='hello-view-subscriptions' to='/subskrypcje'>
      <div className={styles.Container_SingleSection}>
        <ParagraphDefault className={styles.SingleSection_LinkTitle}>
          Subskrypcje
        </ParagraphDefault>
        <ParagraphDefault className={styles.SingleSection_LinkSubtitle}>
          Tutaj znajdziesz informacje dotyczące subskrypcji oraz ich kupna
        </ParagraphDefault>
      </div>
    </Link>

    <Link id='hello-view-products' to='/products'>
      <div className={styles.Container_SingleSection}>
        <ParagraphDefault className={styles.SingleSection_LinkTitle}>
          Produkty
        </ParagraphDefault>
        <ParagraphDefault className={styles.SingleSection_LinkSubtitle}>
          Tutaj znajdziesz wszystkie dostępne materiały i kursy
        </ParagraphDefault>
      </div>
    </Link>

    <Link id='hello-view-calculators' to='/calculators'>
      <div className={styles.Container_SingleSection}>
        <ParagraphDefault className={styles.SingleSection_LinkTitle}>
          Kalkulatory cukiernicze
        </ParagraphDefault>
        <ParagraphDefault className={styles.SingleSection_LinkSubtitle}>
          Tutaj znajdują się kalkulatory cukiernicze
        </ParagraphDefault>
      </div>
    </Link>
  </div>
)
