import { isStringNumeric } from '../../../../helpers/validators'
import { commasToDots } from '../../../../helpers/adapters/custom'

export const roundedSpongeCakeValidator = (diameter: string): string[] => {
  const errors: string[] = []
  const D = commasToDots(diameter)

  if (!isStringNumeric(D)) {
    errors.push('Wartość w polu "Średnica tortu" nie jest wartością liczbową')
  }

  if (typeof parseFloat(D) === 'number' && parseFloat(D) <= 0) {
    errors.push('Wartość w polu "Średnica tortu" musi być większa od 0')
  }

  return errors
}
