import {
  checkIfStringHasWhitespaces,
  emailIsValid,
  validateEmail
} from '../../../helpers/validators'

interface Props {
  username: string
  password: string
  passwordRepeated: string
  name: string
  surname: string
  email: string
}

export function getSignupErrors({
  username,
  password,
  passwordRepeated,
  email,
  name,
  surname
}: Props): string[] {
  const errors = []

  if (username === '') {
    errors.push('Uzupełnij nazwę użytkownika')
  }

  if (username.includes('@')) {
    errors.push('W nazwie użytkownika nie może znajdować się znak "@"')
  }

  if (password === '') {
    errors.push('Uzupełnij hasło')
  }

  if (password !== passwordRepeated && password !== '') {
    errors.push('Hasło powtórzone niepoprawnie')
  }

  if (email === '') {
    errors.push('Uzupełnij email')
  }

  if (!emailIsValid(email) && !validateEmail(email)) {
    errors.push('Błędny email')
  }

  if (name === '') {
    errors.push('Uzupełnij imię')
  }

  if (surname === '') {
    errors.push('Uzupełnij nazwisko')
  }

  if (checkIfStringHasWhitespaces(username)) {
    errors.push('Spacje w nazwie użytkownika są niedozwolone')
  }

  if (checkIfStringHasWhitespaces(password)) {
    errors.push('Spacje w haśle są niedozwolone')
  }

  if (checkIfStringHasWhitespaces(email)) {
    errors.push('Spacje w emailu są niedozwolone')
  }

  return errors
}
