import { useState } from 'react'
import { InputText } from '../../../../UI/inputs/InputText/InputText'
import { LessonImageData } from '../../../../../service/Product/lessonTypes'
import { ButtonByAdam } from '../../../../UI/buttons/ButtonByAdam/ButtonByAdam'
import { SectionSeparator } from '../../../../UI/sectionSeparator/sectionSeparator'

interface Props {
  imgData: LessonImageData
  updateFieldHandler: (data: { path: string; title: string }) => void
}

export const ImageContentField = ({
  imgData,
  updateFieldHandler
}: Props): JSX.Element => {
  const [imagePath, setImagePath] = useState<string>(
    imgData.path || 'szkolamajcake/'
  )

  return (
    <div>
      <InputText
        label='Sciezka do obrazka w Cloudinary'
        placeholder='Wpisz sciezke do obrazka w Cloudinary'
        value={imagePath}
        onBlurHandler={(val: string): void => setImagePath(val)}
      />

      <SectionSeparator width={100} />

      <ButtonByAdam
        onClick={(): void => {
          updateFieldHandler({
            path: imagePath,
            title: ''
          })
        }}
      >
        Zapisz obrazek
      </ButtonByAdam>

      <SectionSeparator width={100} />
    </div>
  )
}
