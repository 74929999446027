import { ProductDto } from './ProductService'
import {AxiosBackend} from "../HTTP/AxiosInstance";

const getProductsReq = async (queryParams: string) =>
    AxiosBackend.get<ProductDto[]>(`/products${queryParams ? `?${queryParams}` : ''}`)

export const getProducts = async (queryParams = '') => {
  try {
    const { data } = await getProductsReq(queryParams)
    if (data) {
      return Promise.resolve(data)
    }
    return Promise.reject(new Error('Get products failed'))
  } catch (err) {
    throw new Error('Get products failed')
  }
}

const postProductsReq = async (product: ProductDto) =>
    AxiosBackend.post<ProductDto>('/product', product)

export const postProduct = async (product: ProductDto) => {
  try {
    const { data } = await postProductsReq(product)
    if (data) {
      return Promise.resolve(data)
    }
    return Promise.reject()
  } catch (err) {
    throw new Error('Post products failed')
  }
}

const putProductReq = async (product: ProductDto) =>
    AxiosBackend.put<ProductDto>('/product', product)

export const putProduct = async (product: ProductDto) => {
  try {
    const { data } = await putProductReq(product)
    if (data) {
      return Promise.resolve(data)
    }
    return Promise.reject()
  } catch (err) {
    throw new Error('Put product failed')
  }
}

const getProductReq = async (productID: string) =>
    AxiosBackend.get<ProductDto>('/product', { params: { productID } })

export const getProduct = async (productID: string) => {
  try {
    const { data } = await getProductReq(productID)
    if (data) {
      return Promise.resolve(data)
    }
    return Promise.reject()
  } catch (err) {
    throw new Error('Get product failed')
  }
}

const deleteProductReq = async (productID: string) =>
    AxiosBackend.delete<ProductDto>(`/product/${productID}`)

export const deleteProduct = async (productID: string) => {
  try {
    const { data } = await deleteProductReq(productID)
    if (data) {
      return Promise.resolve(data)
    }
    return Promise.reject()
  } catch (err) {
    throw new Error('Delete product failed')
  }
}
