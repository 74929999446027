import React from 'react'
import styles from './Ribbon.module.scss'
import { FaStarIcon } from '../../../../Assets/ReactIcons'

interface RibbonProps {
  text: string
}

export const Ribbon = ({ text }: RibbonProps) => (
  <div className={styles.Ribbon}>
    <div className={styles.Ribbon_Text}>
      {text} {FaStarIcon}
    </div>
  </div>
)
