export const CanceledProductPayment = (): JSX.Element => {
  setTimeout((): void => {
    window.location.replace('/products/bought')
  }, 2000)
  return (
    <section>
      <h1 style={{ color: 'red' }}>Platność nie powiodła się</h1>
    </section>
  )
}
