import {
  FaBirthdayCake,
  FaUser,
  FaVideo,
  FaCheck,
  FaTimes,
  FaSearch,
  FaCalculator
} from 'react-icons/fa'
import {
  BsDot,
  BsTrash,
  BsCardImage,
  IoLogoYoutube,
  FiChevronDown,
  FiChevronLeft,
  FiChevronUp,
  FiChevronRight,
  BiEdit,
  BiImageAdd,
  IoIosRemoveCircle,
  IoMdAddCircle,
  GoPrimitiveDot,
  TiWarningOutline,
  FaTags,
  GiPresent,
  FaRegArrowAltCircleDown,
  FaRegArrowAltCircleRight,
  FaFacebook,
  FcApproval,
  BsFillPlusCircleFill,
  BiError,
  FaStar,
  ImCross
} from 'react-icons/all'

export const FaUserIcon: JSX.Element = <FaUser />
export const FaStarIcon: JSX.Element = <FaStar />

export const FaVideoIcon: JSX.Element = <FaVideo />
export const IoLogoYoutubeIcon: JSX.Element = <IoLogoYoutube />
export const BsCardImageIcon: JSX.Element = <BsCardImage />

export const FaBirthdayCakeIcon: JSX.Element = <FaBirthdayCake />

export const FiChevronDownIcon: JSX.Element = <FiChevronDown />
export const FiChevronUpIcon: JSX.Element = <FiChevronUp />
export const FiChevronLeftIcon: JSX.Element = <FiChevronLeft />
export const FiChevronRightIcon: JSX.Element = <FiChevronRight />

export const BsDotIcon: JSX.Element = <BsDot />
export const GoPrimitiveDotIcon: JSX.Element = <GoPrimitiveDot />

export const BsTrashIcon: JSX.Element = <BsTrash />
export const BiEditIcon: JSX.Element = <BiEdit />
export const FaCheckIcon: JSX.Element = <FaCheck />
export const FaTimesIcon: JSX.Element = <FaTimes />

export const SearchIcon: JSX.Element = <FaSearch />

export const BiImageAddIcon: JSX.Element = <BiImageAdd />
export const IoIosRemoveCircleIcon: JSX.Element = <IoIosRemoveCircle />
export const IoMdAddCircleIcon: JSX.Element = <IoMdAddCircle />

export const FaCalculatorIcon: JSX.Element = <FaCalculator />
export const GiPresentIcon: JSX.Element = <GiPresent />

export const TiWarningIcon: JSX.Element = <TiWarningOutline />

export const FaTagsIcon: JSX.Element = <FaTags />

export const FaArrowDownIcon: JSX.Element = <FaRegArrowAltCircleDown />
export const FaArrowRightIcon: JSX.Element = <FaRegArrowAltCircleRight />

export const FaFacebookIcon: JSX.Element = <FaFacebook />

export const FcApprovalIcon: JSX.Element = <FcApproval />
export const BsFillPlusCircleFillIcon: JSX.Element = <BsFillPlusCircleFill />

export const BiErrorIcon: JSX.Element = <BiError />
export const CrossClear: JSX.Element = <ImCross />
