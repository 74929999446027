import React from 'react'
import styles from './SearchBar.module.scss'
import { CrossClear, SearchIcon } from '../../../Assets/ReactIcons'

interface Props {
  id?: string
  className?: string
  placeholder?: string
  query: string
  onChange: (val: string) => void
  onClick?: () => void
}

export const SearchBar = ({
  className = '',
  id,
  placeholder = '',
  query,
  onChange,
  onClick
}: Props): JSX.Element => (
  <div className={`${styles.Container} ${className}`}>
    <input
      id={id}
      className={styles.SearchBar}
      placeholder={placeholder}
      value={query}
      onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
        onChange(e.target.value)
      }
      onClick={(): void => {
        if (onClick) {
          onClick()
        }
      }}
    />

    <div
      role='presentation'
      className={styles.Searchbar__icon}
      onClick={(): void => {
        onChange('')
      }}
    >
      {query === '' ? SearchIcon : CrossClear}
    </div>
  </div>
)
