import { Message } from 'semantic-ui-react'

interface Props {
  title: string
  successList: string[]
}

export const SuccessListMessage = ({
  title,
  successList
}: Props): JSX.Element => <Message success header={title} list={successList} />
