import { useEffect, useMemo, useReducer } from 'react'
import { Link } from 'react-router-dom'
import { AdvancedImage, placeholder, responsive } from '@cloudinary/react'
import styles from '../../SharedCalculatorCss.module.scss'
import { InputTextLabeled } from '../../../UI/inputs/InputTextLabeled/InputTextLabeled'
import { ButtonByAdam } from '../../../UI/buttons/ButtonByAdam/ButtonByAdam'
import { HeaderWithIcon } from '../../../UI/headers/CustomHeader/CustomHeader'
import { ErrorListMessage } from '../../../UI/MessageComponents/ErrorListMessage/ErrorListMessage'
import { SuccessMessage } from '../../../UI/MessageComponents/SuccessMessage/SuccessMessage'
import {
  roundedCakeReducer,
  initialSquarePortionsReducer,
  SquarePortionsReducerActionName
} from './SquarePortionsReducer'
import { CalculatorOperations } from '../../../../helpers/cake'
import { Select } from '../../../UI/inputs/Select/Select'
import { frostingIngredients } from '../../../../helpers/dictionaries'
import { SuccessListMessage } from '../../../UI/MessageComponents/SuccessListMessage/SuccessListMessage'
import { ErrorMessage } from '../../../UI/MessageComponents/ErrorMessage/ErrorMessage'

import { SubscriptionType } from '../../../../service/Product/ProductService'
import { translateSubscriptionType } from '../../../../helpers/utils'
import { useCloudinary } from '../../../../hooks/useCloudinary'

interface Props {
  countAutomatic: boolean
  isAvailableForUser: boolean
  subType: SubscriptionType
}

export const SquareCake = ({
  countAutomatic,
  isAvailableForUser,
  subType
}: Props): JSX.Element => {
  const [calculator, dispatch] = useReducer(
    roundedCakeReducer,
    initialSquarePortionsReducer
  )
  const { getImageInstance } = useCloudinary()

  const squareCakeImage = useMemo(() => {
    const imageInstance = getImageInstance(
      'szkolamajcake/kalkulatory/tort_kwadrat_wzor'
    )
    return (
      <AdvancedImage
        cldImg={imageInstance}
        plugins={[responsive(), placeholder()]}
      />
    )
  }, [])

  const calculate = (): void =>
    dispatch({ type: SquarePortionsReducerActionName.CALCULATE_RESULT })
  const updateCalculatorData = (data: CalculatorOperations): void =>
    dispatch({
      type: SquarePortionsReducerActionName.UPDATE_SQUARE_PORTIONS_CALC_DATA,
      data
    })

  useEffect((): void => {
    if (countAutomatic && isAvailableForUser) {
      calculate()
    }
  }, [calculator.sideLength, calculator.height, calculator.chosenIcing])

  return (
    <section className={styles.CalculatorContainer}>
      <div className={styles.Content_Inputs}>
        <HeaderWithIcon headerType='typical'>Torty kwadratowe</HeaderWithIcon>

        <InputTextLabeled
          type='number'
          titleColor='pink'
          title='Podaj szerokość tortu'
          dataRequired
          disabled={!isAvailableForUser}
          value={calculator.sideLength}
          placeholder='Podaj szerokość tortu'
          onChangeHandler={(val): void =>
            updateCalculatorData({ fieldName: 'sideLength', fieldValue: val })
          }
        />

        <InputTextLabeled
          type='number'
          titleColor='blue'
          title='Podaj wysokość tortu'
          dataRequired
          disabled={!isAvailableForUser}
          value={calculator.height}
          placeholder='Podaj wysokość tortu'
          onChangeHandler={(val): void =>
            updateCalculatorData({ fieldName: 'height', fieldValue: val })
          }
        />

        <Select
          label='Wybierz rodzaj kremu'
          value={calculator.chosenIcing}
          placeholder='Wybierz rodzaj kremu'
          disabled={!isAvailableForUser}
          options={frostingIngredients}
          onChange={(val): void =>
            updateCalculatorData({
              fieldName: 'chosenIcing',
              fieldValue: val as string
            })
          }
        />

        {!isAvailableForUser && (
          <div className={styles.SubscriptionErrorMessage}>
            <ErrorMessage>
              Z kalkulatora mogą korzystać tylko użytkownicy o poziomie
              subskrypcji <strong>{translateSubscriptionType(subType)}</strong>.
              <strong>
                <Link to='/subskrypcje'>
                  {' '}
                  Zapoznaj się z dostępnymi możliwościami
                </Link>
              </strong>
            </ErrorMessage>
          </div>
        )}

        {!countAutomatic && (
          <ButtonByAdam
            disabled={!isAvailableForUser}
            btnType='premium'
            onClick={(): void => calculate()}
          >
            Oblicz
          </ButtonByAdam>
        )}

        <div className={styles.ResultContainer}>
          {calculator.errors.length > 0 && (
            <ErrorListMessage
              title='Błędne wartości w kalkulatorze'
              errorList={calculator.errors}
            />
          )}
          {calculator.result && (
            <SuccessMessage>
              {'Na ten tort przewidujemy '}
              <strong>{calculator.result}</strong>
              {'g kremu '}
            </SuccessMessage>
          )}
          {calculator.ingredients.length > 0 && (
            <SuccessListMessage
              title='Skład kremu'
              successList={calculator.ingredients}
            />
          )}
        </div>
      </div>

      <div className={styles.Content_Image}>{squareCakeImage}</div>
    </section>
  )
}
