import {FC, ReactNode} from 'react'
import styles from './SidebarItems.module.css'

interface SidebarItemsProps {
  children: ReactNode
}
export const SidebarItems: FC<SidebarItemsProps> = (props): JSX.Element => {
  const { children: childComponents } = props
  return <div className={styles.SidebarItemsContainer}>{childComponents}</div>
}
