import { PaymentType, Subscription } from '../../../../Context/Dictionaries/api'
import styles from '../SubscriptionCard.module.scss'

interface PriceViewProps {
  data: Subscription
}

export const PriceView = ({ data }: PriceViewProps) => {
  const { price, fakePrice, paymentType } = data

  return (
    <>
      <div className={styles.NewPriceContainer}>
        <div className={styles.Content}>
          <h2 className={styles.NewPrice}>{price}</h2>
          <span className={styles.NewPriceCurrency}>zł </span>
          {paymentType === PaymentType.SUBSCRIPTION && (
            <span className={styles.NewPriceSubscriptionMonth}>/msc</span>
          )}

          {!!fakePrice && fakePrice > price && (
            <p className={styles.CrossedPrice}>
              {fakePrice}
              <span className={styles.CrossedCurrency}>zł</span>
            </p>
          )}
        </div>
      </div>
      {!!fakePrice && fakePrice > price && (
        <p className={styles.PromotionText}>
          Oszczędzasz <span>{fakePrice - price}</span>
          <span> zł</span> kupując ten plan!
        </p>
      )}
    </>
  )
}
