import styles from './WorkingTimeRow.module.scss'
import {
  UpdateWorkingTime,
  WorkingTimeInterface
} from '../../CakePriceCalculatorContext/Reducer/interfaces'
import { InputNumber } from '../../../../UI/inputs/InputNumber/InputNumber'
import { CalculatorPriceInputNumberValidator, ErrorsData } from '../../utils'
import { MAX_PRICE_PER_KILOMETER_DELIVERY } from '../../../../../const/calculators/cakePricing'

interface Props {
  data: WorkingTimeInterface
  onChangeHandler: (data: UpdateWorkingTime) => void
  fullPrice: number
}

export const WorkingTimeRow = ({
  data,
  onChangeHandler,
  fullPrice
}: Props): JSX.Element => (
  <div className={styles.Container}>
    <div className={styles.InputContainer}>
      <p className={styles.Paragraph}>Cena za godzinę</p>
      <InputNumber
        type='number'
        value={data.price}
        placeholder='Cena za godzine...'
        containerStyles={styles.MarginRight}
        onChangeHandler={(value: number): void =>
          onChangeHandler({
            id: data.id,
            propName: 'price',
            value
          })
        }
        validateFnc={(val: number): ErrorsData =>
          CalculatorPriceInputNumberValidator(
            val,
            MAX_PRICE_PER_KILOMETER_DELIVERY,
            0
          )
        }
        label='zł'
      />
    </div>

    <div className={styles.InputContainer}>
      <p className={styles.Paragraph}>Ilość przepracowanych godzin</p>
      <InputNumber
        type='number'
        value={data.quantity}
        placeholder='Ilość...'
        containerStyles={styles.MarginRight}
        onChangeHandler={(value: number): void =>
          onChangeHandler({
            id: data.id,
            propName: 'quantity',
            value
          })
        }
        validateFnc={CalculatorPriceInputNumberValidator}
        label='godzina'
      />
    </div>

    <p className={styles.Price}>{`${fullPrice} pln`}</p>
  </div>
)
