import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/all'
import styles from './SwitchLessonRow.module.scss'
import { SearchableSelect } from '../../UI/SearchableSelect/SearchableSelect'
import { DictionaryItem } from '../../../helpers/dictionaries'

interface Props {
  leftChevronHandler: () => void
  rightChevronHandler: () => void
  currentValue: string
  switchInputData: DictionaryItem[]
  selectInputChandler: (val: string) => void
}

export const SwitchLessonRow = ({
  leftChevronHandler,
  rightChevronHandler,
  selectInputChandler,
  switchInputData,
  currentValue
}: Props): JSX.Element => (
  <section className={styles.SwitchLessonRowContainer}>
    <span
      aria-hidden='true'
      tabIndex={0}
      role='button'
      className={styles.ChevronIconContainer}
      onClick={(): void => leftChevronHandler()}
    >
      <FaChevronCircleLeft />
    </span>
    <div className={styles.SearchContainer}>
      <SearchableSelect
        id='choose_lesson'
        className={styles.SearchSelect}
        options={switchInputData}
        placeholder=''
        dataRequired
        value={currentValue}
        onChange={(val: string): void => selectInputChandler(val)}
        noMargin
      />
    </div>
    <span
      role='presentation'
      className={styles.ChevronIconContainer}
      onClick={(): void => rightChevronHandler()}
    >
      <FaChevronCircleRight />
    </span>
  </section>
)
