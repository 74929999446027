import { useState } from 'react'
import nextId from 'react-id-generator'
import styles from './ListDotContentField.module.scss'
import { InputText } from '../../../../UI/inputs/InputText/InputText'
import { TextArea } from '../../../../UI/inputs/TextArea/TextArea'
import {
  BsTrashIcon,
  IoMdAddCircleIcon
} from '../../../../../Assets/ReactIcons'
import {
  DotListData,
  LessonDotListOption
} from '../../../../../service/Product/lessonTypes'
import { ButtonByAdam } from '../../../../UI/buttons/ButtonByAdam/ButtonByAdam'
import { SectionSeparator } from '../../../../UI/sectionSeparator/sectionSeparator'

interface Props {
  updateFieldHandler: (data: DotListData) => void
  productDottedList: DotListData
}

export const ListDotContentField = ({
  updateFieldHandler,
  productDottedList
}: Props): JSX.Element => {
  const [listData, setListData] = useState<DotListData>({
    ...productDottedList
  })
  const key = nextId()

  return (
    <section>
      <div className={styles.ListTitleRow}>
        <InputText
          placeholder='List title'
          value={listData.title}
          onBlurHandler={(title: string): void =>
            setListData({
              ...listData,
              title
            })
          }
        />
        <div
          role='presentation'
          className={styles.AddCircleIcon}
          onClick={(): void => {
            const listDataOptionsCopy = listData.listItems.slice()
            listDataOptionsCopy.push({
              uId: key,
              textHtml: ''
            })
            setListData({
              ...listData,
              listItems: listDataOptionsCopy.slice()
            })
          }}
        >
          {IoMdAddCircleIcon}
        </div>
      </div>

      {listData.listItems.map(
        (listItem: LessonDotListOption, index: number): JSX.Element => (
          <div className={styles.ListTitleRow} key={listItem.uId}>
            <TextArea
              placeholder='Wpisz treść podpunktu, możesz wpisać też HTML'
              value={listItem.textHtml}
              onBlurHandler={(text: string): void => {
                const listDataOptionsCopy = listData.listItems.slice()
                listDataOptionsCopy[index].textHtml = text
                setListData({
                  ...listData,
                  listItems: listDataOptionsCopy.slice()
                })
              }}
            />
            <div
              role='presentation'
              className={styles.RemoveCircleIcon}
              onClick={(): void => {
                const listDataOptionsCopy = listData.listItems.slice()
                const filteredOptions = listDataOptionsCopy.filter(
                  (listDataOption: LessonDotListOption, idx): boolean =>
                    index !== idx
                )
                setListData({
                  ...listData,
                  listItems: filteredOptions.slice()
                })
              }}
            >
              {BsTrashIcon}
            </div>
          </div>
        )
      )}

      <SectionSeparator width={100} />

      <ButtonByAdam
        onClick={(): void => {
          updateFieldHandler({
            ...listData
          })
        }}
      >
        Zapisz listę
      </ButtonByAdam>

      <SectionSeparator width={100} />
    </section>
  )
}
