import {
  LessonTextField,
  LessonImageData,
  LessonDotListField,
  LessonImageField
} from '../../service/Product/lessonTypes'

export const getTextFieldInitByType = (
  type: 'header' | 'paragraph'
): LessonTextField => ({
  uId: '',
  type,
  text: ''
})

export const getListDottedInitByType = (
  type: 'dotList'
): LessonDotListField => ({
  uId: '',
  type,
  list: {
    title: '',
    listItems: [
      {
        uId: '',
        textHtml: ''
      }
    ]
  }
})

export const initImgData: LessonImageData = {
  path: '',
  title: ''
}

export const getImageFieldInitByType = (type: 'image'): LessonImageField => ({
  uId: '',
  type,
  imgData: { ...initImgData }
})
