import { useContext, useState } from 'react'
import { Item } from 'semantic-ui-react'
import styles from './ProtectedCodeListItem.module.scss'
import { getPromotionCode } from '../../../../containers/Benefits/api'
import { GlobalNotificationContext } from '../../../../Context/GlobalNotification/GlobalNotificationProvider'
import { Discount } from '../../../../const/benefits'
import { NotificationType } from '../../../../Context/GlobalNotification/Types'

interface Props {
  discountData: Discount
}

const CODE_GENERATION_MESSAGE = 'Kliknij, aby wygenerować kod!'

export const ProtectedCodeListItem = ({ discountData }: Props): JSX.Element => {
  const [promotionCode, setPromotionCode] = useState<string>(
    CODE_GENERATION_MESSAGE
  )

  const showNotification = useContext(GlobalNotificationContext)

  return (
    <Item className={styles.ItemStyles}>
      <Item.Image size='small' src={discountData.thumbnailUrl} />
      <Item.Content>
        <Item.Header as='h3'>
          <a
            href={discountData.siteUrl}
            target='_blank'
            rel='noopener noreferrer'
          >
            {discountData.companyName}
          </a>
        </Item.Header>
        <Item.Meta>{discountData.shortBusinessDescription}</Item.Meta>
        <Item.Description>
          <span className='default-paragraph'>
            {discountData.codeDescription}
          </span>
        </Item.Description>
        <Item.Extra style={{ marginBottom: '10px' }}>
          <a
            href={discountData.siteUrl}
            target='_blank'
            rel='noopener noreferrer'
          >
            {discountData.siteUrl}
          </a>
        </Item.Extra>
        <Item.Extra
          onClick={(): void => {
            if (promotionCode === CODE_GENERATION_MESSAGE) {
              getPromotionCode(discountData.codeKey).then(payload => {
                const thePromoCode = payload.data
                if (thePromoCode) {
                  setPromotionCode(thePromoCode)
                  showNotification(
                    NotificationType.INFO,
                    'Kod zniżkowy pobrany z sukcesem'
                  )
                }
              })
            }
          }}
        >
          <span
            className={styles.CodeSpan}
          >{`Twój kod zniżkowy: ${promotionCode}`}</span>
        </Item.Extra>
      </Item.Content>
    </Item>
  )
}
