import { useContext } from 'react'
import { UserSettingsForm } from '../../components/UserSettings/UserSettingsForm/UserSettingsForm'
import { UserReducerActions } from '../../Context/User/UserReducer'
import { UserContext } from '../../Context/User/UserContext'
import { User } from '../../service/User/UserService'
import { UserFrontToDtoAdapter } from '../../helpers/adapters/user'
import { SubscriptionSection } from '../../components/UserSettings/SubscriptionSection/SubscriptionSection'
import { AdditionalOptions } from '../../components/UserSettings/AdditionalOptions/AdditionalOptions'
import { CookieStorageService } from '../../service/CookieStorageService'
import { GlobalNotificationContext } from '../../Context/GlobalNotification/GlobalNotificationProvider'
import { GlobalLoaderContext } from '../../Context/GlobalLoader/GlobalLoader'
import { NotificationType } from '../../Context/GlobalNotification/Types'
import { updateUserData, userRemove } from '../../service/User/api'

const cookieService = new CookieStorageService('token')

export const UserSettings = (): JSX.Element => {
  const userContext: UserReducerActions = useContext(UserContext)

  const showNotification = useContext(GlobalNotificationContext)
  const showLoader = useContext(GlobalLoaderContext)

  return (
    <>
      <UserSettingsForm
        user={userContext.userDetails}
        onSaveHandler={(userData: User): void => {
          showLoader(true)
          updateUserData(UserFrontToDtoAdapter(userData))
            .then((): void => {
              showLoader(false)
              showNotification(
                NotificationType.INFO,
                'Twoje dane zostały zaktualizowane'
              )
            })
            .catch(() => showLoader(false))
        }}
      />

      {((userContext.userDetails &&
        userContext.userDetails.subscriptionData &&
        userContext.userDetails.subscriptionData.length) ||
        (userContext.userDetails.subscriptionCharges &&
          userContext.userDetails.subscriptionCharges.length > 0)) && (
        <SubscriptionSection user={userContext.userDetails} />
      )}

      <AdditionalOptions
        onUserRemoveHandler={(): void => {
          showLoader(true, 'Usuwanie użytkownika...')
          userRemove()
            .then((): void => {
              // eslint-disable-next-line no-underscore-dangle
              showNotification(
                NotificationType.INFO,
                'Użytkownik usunięty pomyślnie. Do zobaczenia!'
              )
              cookieService.removeItem()
              setTimeout((): void => {
                showLoader(false)
                window.location.replace('/')
              }, 4000)
            })
            .catch(() => showLoader(false))
        }}
        stripeCustomerId={userContext.userDetails.stripeCustomerId}
      />
    </>
  )
}
