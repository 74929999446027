import { FunctionComponent, ReactNode, useEffect } from 'react'
import { HtmlIframeProps } from 'semantic-ui-react'
import { Dictionaries, VimeoIDS } from '../Context/Dictionaries/api'

interface VimeoHackingRadarInterface {
  idAdminUser: boolean
  violationFoundCallback: (videoSubtype: VimeoIDS) => void
  dictionaries: Dictionaries
  children: ReactNode;
}

export const VimeoHackingRadar: FunctionComponent<
  VimeoHackingRadarInterface
> = (props): JSX.Element => {
  const {
    dictionaries,
    violationFoundCallback,
    children: childComponents
  } = props
  const { vimeoIds } = dictionaries
  // eslint-disable-next-line react/prop-types
  const { idAdminUser } = props
  useEffect((): (() => void) => {
    const interval = setInterval(() => {
      const allIFramesOnThePage: HtmlIframeProps[] = Array.from(
        document.querySelectorAll('iframe')
      )
      if (allIFramesOnThePage.length > 0) {
        allIFramesOnThePage.forEach((iframe: HtmlIframeProps): void => {
          if (
            iframe.attributes?.src?.value?.includes('player.vimeo.com/video/')
          ) {
            const theID = iframe.attributes.src.value.substring(
              iframe.attributes.src.value.indexOf('.vimeo.com/video/') + 17,
              iframe.attributes.src.value.indexOf('?')
            )
            const matchingVidos = vimeoIds.filter(
              video => video.vimeoId === theID
            )
            if (matchingVidos.length > 0) {
              const videoVimeo = matchingVidos[0]

              violationFoundCallback(videoVimeo)
            }
          }
        })
      }
    }, 10000)
    return (): void => clearInterval(interval)
  }, [vimeoIds.length, idAdminUser])

  return <div>{childComponents}</div>
}
