import nextId from 'react-id-generator'
import { cloneDeep } from 'lodash'
import { createInitialCost } from '../../utils'
import { CakePriceCalcReducer, UpdateFixedCostsConfig } from './interfaces'

export const updateCost = (
  config: UpdateFixedCostsConfig,
  state: CakePriceCalcReducer
): CakePriceCalcReducer => ({
  ...state,
  fixedCosts: {
    ...state.fixedCosts,
    costs: state.fixedCosts.costs.map(item => {
      if (item.id === config.id) {
        return {
          ...item,
          [config.inputName]: config.value
        }
      }
      return item
    })
  }
})

export const addCost = (state: CakePriceCalcReducer): CakePriceCalcReducer => {
  const stateCopy = cloneDeep(state)
  stateCopy.fixedCosts.costs = [
    ...stateCopy.fixedCosts.costs,
    createInitialCost(nextId(`${state.fixedCosts.costs.length + 1}`))
  ]
  return stateCopy
}

export const deleteCost = (
  id: string,
  state: CakePriceCalcReducer
): CakePriceCalcReducer => {
  const stateCopy = cloneDeep(state)
  stateCopy.fixedCosts.costs = state.fixedCosts.costs.filter(
    cost => cost.id !== id
  )
  return stateCopy
}

export const updateFixedSweetCounter = (
  value: number,
  state: CakePriceCalcReducer
): CakePriceCalcReducer => {
  const stateCopy = cloneDeep(state)
  stateCopy.fixedCosts.sweetsCount = value
  return stateCopy
}
