import { SubscriptionTypes } from '../../../service/Product/ProductService'

export interface SubscriptionOperationData {
  type: 'ADD' | 'REMOVE'
  value: SubscriptionTypes
}

export interface ProductFiltersInterface {
  subscriptionType: SubscriptionTypes[]
  tags: string[]
}

export interface ProductFilterActions {
  filters: ProductFiltersInterface
  updateChosenSubType: () => void
  updateTags: () => void
}

export const INIT_PRODUCT_FILTER_REDUCER: ProductFilterActions = {
  filters: {
    subscriptionType: [],
    tags: []
  },
  updateChosenSubType: (): void => {
    // EMpty
  },
  updateTags: (): void => {
    // EMpty
  }
}

export enum ProductFilterActionNames {
  UPDATE_CHOSEN_SUB_TYPE = 'UPDATE_CHOSEN_SUB_TYPE',
  UPDATE_TAGS = 'UPDATE_TAGS'
}

export type ProductDataReducerAction =
  | {
      type: ProductFilterActionNames.UPDATE_CHOSEN_SUB_TYPE
      data: SubscriptionOperationData
    }
  | {
      type: ProductFilterActionNames.UPDATE_TAGS
      data: string[]
    }

const updateChosenSubType = (
  data: SubscriptionOperationData,
  state: ProductFilterActions
): ProductFilterActions => {
  let subFiltersCopy = state.filters.subscriptionType.slice()
  if (data.type === 'ADD' && !subFiltersCopy.includes(data.value)) {
    subFiltersCopy.push(data.value)
  } else if (data.type === 'REMOVE' && subFiltersCopy.includes(data.value)) {
    subFiltersCopy = subFiltersCopy.filter(
      (value: SubscriptionTypes): boolean => value !== data.value
    )
  }

  return {
    ...state,
    filters: {
      ...state.filters,
      subscriptionType: subFiltersCopy.slice()
    }
  }
}

const updateTags = (
  data: string[],
  state: ProductFilterActions
): ProductFilterActions => ({
  ...state,
  filters: {
    ...state.filters,
    tags: data
  }
})

export const productFilterReducer = (
  state: ProductFilterActions,
  action: ProductDataReducerAction
): ProductFilterActions => {
  switch (action.type) {
    case ProductFilterActionNames.UPDATE_CHOSEN_SUB_TYPE:
      return updateChosenSubType(action.data, state)
    case ProductFilterActionNames.UPDATE_TAGS:
      return updateTags(action.data, state)
    default:
      return state
  }
}
