import { useContext, useReducer } from 'react'
import {
  INIT_PRODUCT_FILTER_REDUCER,
  ProductFilterActionNames,
  ProductFilterActions,
  productFilterReducer,
  SubscriptionOperationData
} from '../../components/product/ProductFilters/ProductFiltersReducer'
import { ProductDataActions } from '../../Context/Product/ProductDataReducer'
import { ProductDataContext } from '../../Context/Product/ProductDataContext'
import { Product } from '../../service/Product/ProductService'
import { checkIfArrayHasSameElements } from '../../helpers/array/MyArray'
import { applyFilters } from '../../helpers/utils'
import {
  getValueByCode,
  productCategoryToType
} from '../../helpers/dictionaries'

interface Hook {
  avaliableProducts: Product[]
  productCategory: string
  productFilters: ProductFilterActions
  updateChosenSubType: (data: SubscriptionOperationData) => void
  updateTags: (data: string[]) => void
}

export function useProductsFilters(
  searchQuery: string,
  isProductVisible: boolean,
  productCategory: string
): Hook {
  const productsContext: ProductDataActions = useContext(ProductDataContext)
  const [productFilters, dispatch] = useReducer(
    productFilterReducer,
    INIT_PRODUCT_FILTER_REDUCER
  )

  const updateChosenSubType = (data: SubscriptionOperationData): void =>
    dispatch({
      type: ProductFilterActionNames.UPDATE_CHOSEN_SUB_TYPE,
      data
    })

  const updateTags = (data: string[]): void =>
    dispatch({
      type: ProductFilterActionNames.UPDATE_TAGS,
      data
    })

  const filterBySubType = (arrItem: Product): boolean =>
    productFilters.filters.subscriptionType.length === 0 ||
    productFilters.filters.subscriptionType.includes(arrItem.subscription)

  const filterByTags = (arrItem: Product): boolean =>
    typeof arrItem.tags === 'undefined' ||
    productFilters.filters.tags.length === 0 ||
    (arrItem && arrItem.tags && arrItem.tags.length === 0)
      ? true
      : checkIfArrayHasSameElements(productFilters.filters.tags, arrItem.tags)

  const filterBySearchQuery = (arrItem: Product): boolean =>
    arrItem.name.toLowerCase().includes(searchQuery.toLowerCase())

  const filterProductsByVisibility = (arrItem: Product) =>
    isProductVisible ? arrItem.invisibleProduct : true

  const category = getValueByCode(productCategory, productCategoryToType)
  const properProducts = productCategory
    ? productsContext.getProductsFilteredByCategory(category)
    : productsContext.products

  if (!properProducts) {
    throw new Error('Invalid proper Products variable')
  }

  const avaliableProducts = applyFilters(properProducts, [
    filterBySubType,
    filterBySearchQuery,
    filterByTags,
    filterProductsByVisibility
  ])

  return {
    avaliableProducts,
    updateChosenSubType,
    updateTags,
    productCategory,
    productFilters
  }
}
