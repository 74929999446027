import styles from './IntroductionVideo.module.scss'
import { INTRODUCTION_VIDEO_YOUTUBE_URL } from '../../../const/consts'
import { WebsiteDescription } from '../WebsiteDescription/WebsiteDescription'

export const IntroductionVideo = (): JSX.Element => (
  <div className={styles.IntroductionVideoContainer}>
    <WebsiteDescription productDescription='Poznaj Słodką Szkołę' />
    <iframe
      title='Introduction-clip'
      width='340'
      height='315'
      src={INTRODUCTION_VIDEO_YOUTUBE_URL}
      frameBorder='0'
      allowFullScreen
    />
  </div>
)
