import React from 'react'
import { NavLink } from 'react-router-dom'

interface Props extends React.HTMLAttributes<HTMLLinkElement> {
  path: string
  activeClassName?: string
}

export const SingleItem = ({ ...props }: Props): JSX.Element => (
  <NavLink
    id={props.id}
    to={props.path}
    className={({ isActive }) =>
      `${props.className}  ${isActive ? props.activeClassName : ''}`
    }
  >
    {props.children}
  </NavLink>
)
