import nextId from 'react-id-generator'
import { ProductParagraph } from '../ViewComponents/productParagraph/ProductParagraph'
import { ProductHeader } from '../ViewComponents/productHeader/ProductHeader'
import { ProductImage } from '../ViewComponents/ProductImage/ProductImage'
import { ProductDotList } from '../ViewComponents/ProductDotList/ProductDotList'
import {
  LessonTextField,
  LessonDotListField,
  LessonImageField
} from '../../../../service/Product/lessonTypes'

interface Props {
  fields: (LessonTextField | LessonDotListField | LessonImageField)[]
}

export const LessonContentGenerator = ({ fields }: Props): JSX.Element => (
  <>
    {fields.map(
      (
        field: LessonTextField | LessonImageField | LessonDotListField
      ): JSX.Element => {
        if (field.type === 'paragraph') {
          return (
            <ProductParagraph
              key={`${field.uId || nextId('field_paragraph')}`}
              text={field.text}
            />
          )
        }
        if (field.type === 'header') {
          return (
            <ProductHeader
              key={`${field.uId || nextId('field_header')}`}
              text={field.text}
            />
          )
        }
        if (field.type === 'image') {
          return (
            <ProductImage
              key={`${field.uId || nextId('field_image')}`}
              imgData={field.imgData}
            />
          )
        }
        if (field.type === 'dotList') {
          return (
            <ProductDotList
              key={`${field.uId || nextId('field_dotList')}`}
              listData={field.list}
            />
          )
        }
        throw new Error('field.type is invalid')
      }
    )}
  </>
)
