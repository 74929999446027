import { memo, useState } from 'react'
import {
  AdditionalSpendingsInterface,
  UpdateAdditionalSpendingsConfig
} from '../../CakePriceCalculatorContext/Reducer/interfaces'
import { ConfigView } from './ConfigView/ConfigView'
import { StandardView } from './StandardView/StandardView'

interface Props {
  product: AdditionalSpendingsInterface
  index: number
  onChangeHandler: (data: UpdateAdditionalSpendingsConfig) => void
  removeRowHandler: (id: string) => void
}

const AdditionalSpendingsRow = ({
  product,
  index,
  onChangeHandler,
  removeRowHandler
}: Props): JSX.Element => {
  const [showConfig, setShowConfig] = useState<boolean>(false)

  return (
    <div>
      {showConfig ? (
        <ConfigView
          product={product}
          index={index}
          onChangeHandler={onChangeHandler}
          removeRowHandler={removeRowHandler}
          changeView={(): void => setShowConfig(!showConfig)}
        />
      ) : (
        <StandardView
          product={product}
          index={index}
          onChangeHandler={onChangeHandler}
          removeRowHandler={removeRowHandler}
          changeView={(): void => setShowConfig(!showConfig)}
        />
      )}
    </div>
  )
}

function rowPropsAreEqual(): boolean {
  return false
}

export const MemoizedAdditionalSpendingsRow = memo(
  AdditionalSpendingsRow,
  rowPropsAreEqual
)
