import {FC, ReactNode, useState} from 'react'
import sharedCss from '../../SharedCalculatorCss.module.scss'
import styles from './SectionWithTitle.module.scss'
import {
  FiChevronDownIcon,
  FiChevronUpIcon
} from '../../../../Assets/ReactIcons'
import { formatNumbers } from '../../../../helpers/formatters'

interface Props {
  sectionName: string
  fullPrice: number
  description?: string
  children: ReactNode
}

export const SectionWithTitle: FC<Props> = ({
  children,
  fullPrice,
  sectionName,
  description
}): JSX.Element => {
  const [expand, setExpand] = useState(false)

  return (
    <section className={`${sharedCss.CalculatorContainer} ${styles.Container}`}>
      <div
        role='presentation'
        className={styles.TitleRow}
        onClick={(): void => setExpand(state => !state)}
      >
        <header className={styles.Title}>
          <p>{sectionName}</p>
          <p className={styles.FullPrice}>{`${formatNumbers(
            fullPrice
          )} pln`}</p>
        </header>
        <div className={styles.ChevronIcon}>
          {expand ? FiChevronUpIcon : FiChevronDownIcon}
        </div>
      </div>
      {expand && (
        <section className={styles.FieldsSection}>
          {description && (
            <div className={styles.Description}>
              <p>{description}</p>
            </div>
          )}
          {children}
        </section>
      )}
    </section>
  )
}
