import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { UserReducerActions } from '../../../Context/User/UserReducer'
import { UserContext } from '../../../Context/User/UserContext'
import styles from '../SpongeCakeCounter/SpongeCakeCounter.module.scss'
import { HeaderWithIcon } from '../../UI/headers/CustomHeader/CustomHeader'
import classes from '../SharedCalculatorCss.module.scss'
import { FaCalculatorIcon } from '../../../Assets/ReactIcons'
import { Calculator, calculators } from '../../../const/calculators/calculators'
import { ParagraphDefault } from '../../UI/Paragraphs/ParagraphDefault/ParagraphDefault'
import { ErrorMessage } from '../../UI/MessageComponents/ErrorMessage/ErrorMessage'
import { checkIfUserSubscriptionTypeIsEnough } from '../../../helpers/utils'
import { CakePriceCalculatorContext } from './CakePriceCalculatorContext/CakePriceCalculatorContext'
import { PriceSummaryWindow } from '../PriceSummaryWindow/PriceSummaryWindow'
import { WorkingTime } from './WorkingTime/WorkingTime'
import { DeliveryTime } from './DeliveryTime/DeliveryTime'
import { AdditionalSpendings } from './AdditionalSpendings/AdditionalSpendings'
import { CakePriceCalcContext } from './CakePriceCalculatorContext/interfaces'
import { GroceryProducts } from './GroceryProducts/GroceryProducts'
import ControlPricingSectionMemoized from './ControlPricingSection/ControlPricingSection'
import { pricingsLimitByUserSubType } from './utils'
import { FixedCostsSection } from './FixedCostsSection/FixedCostsSection'

const CALCULATOR_TYPE = 'cakeprice'

export const CakePricing = (): JSX.Element => {
  const userContext: UserReducerActions = useContext(UserContext)
  const cakePricingContext: CakePriceCalcContext | null = useContext(
    CakePriceCalculatorContext
  )
  const calculatorData: Calculator = calculators.filter(
    (calc): boolean => calc.type === CALCULATOR_TYPE
  )[0]

  const isAvailableForUser = checkIfUserSubscriptionTypeIsEnough(
    userContext.userDetails,
    calculatorData.subscriptionType
  )

  if (cakePricingContext == null) {
    return <p>Pricing context not set</p>
  }

  return (
    <section className={styles.Container}>
      <div className={classes.HeaderContainer}>
        <HeaderWithIcon
          className={classes.Header}
          headerType='typical'
          reactIcon={FaCalculatorIcon}
        >
          {calculatorData.title}
        </HeaderWithIcon>
      </div>

      <div className={classes.ParagraphDescription}>
        <ParagraphDefault>{calculatorData.description}</ParagraphDefault>
      </div>

      {!isAvailableForUser && (
        <div style={{ fontSize: '18px' }}>
          <ErrorMessage>
            Jeżeli chcesz zapisać swoje kalkulacje, musisz mieć aktywną
            subskrypcję <strong>podstawową</strong> lub <strong>premium</strong>
            . Jedyną różnicą między subskrypcjami jest ilość wycen, które możesz
            zapisać.
            <strong>
              <Link to='/subskrypcje'> Wybierz swoją subskrypcję!</Link>
            </strong>
          </ErrorMessage>
        </div>
      )}
      <PriceSummaryWindow />
      <ControlPricingSectionMemoized
        pricingsLimit={pricingsLimitByUserSubType(
          userContext.userDetails.subscriptionType
        )}
        updateCalculateProductName={
          cakePricingContext.updateCalculatorProductName
        }
        setCurrentPricingHandler={cakePricingContext.updateCalculatorData}
        avaliablePricings={cakePricingContext.avaliablePricings}
        setAvaliablePricings={cakePricingContext.setAvaliablePricings}
      />

      <GroceryProducts
        products={cakePricingContext.groceryProducts.products}
        fullPrice={cakePricingContext.summary.groceryProducts}
        addProductHandler={cakePricingContext.groceryProducts.addGroceryProduct}
        onChangeHandler={
          cakePricingContext.groceryProducts.updateGroceryProduct
        }
        removeRowHandler={
          cakePricingContext.groceryProducts.removeGroceryProduct
        }
      />

      <AdditionalSpendings
        products={cakePricingContext.additionalSpending.products}
        fullPrice={cakePricingContext.summary.additionalSpendings}
        addProductHandler={
          cakePricingContext.additionalSpending.addAdditionalSpendings
        }
        onChangeHandler={
          cakePricingContext.additionalSpending.updateAdditionalSpendings
        }
        removeRowHandler={
          cakePricingContext.additionalSpending.removeAdditionalSpendings
        }
      />

      <WorkingTime
        data={cakePricingContext.workingTime.data}
        fullPrice={cakePricingContext.summary.workingTime}
        onChangeHandler={cakePricingContext.workingTime.updateWorkingTime}
      />

      <DeliveryTime
        data={cakePricingContext.deliveryTime.data}
        fullPrice={cakePricingContext.summary.deliveryTime}
        onChangeHandler={cakePricingContext.deliveryTime.updateDeliveryTime}
      />

      <FixedCostsSection
        fullPrice={cakePricingContext.summary.fixedCosts}
        sweetsCounter={cakePricingContext.fixedCosts.data.sweetsCount}
        costs={cakePricingContext.fixedCosts.data.costs}
        onUpdateCostHandler={cakePricingContext.fixedCosts.updateCost}
        addCost={cakePricingContext.fixedCosts.addFixedCost}
        removeCost={cakePricingContext.fixedCosts.removeFixedCost}
        onSweetsCounterHandler={
          cakePricingContext.fixedCosts.updateFixedSweetCounter
        }
      />
    </section>
  )
}
