import { AxiosResponse } from 'axios'
import {AxiosBackend} from "../HTTP/AxiosInstance";

export const deleteCurrentSubscription = async (
  subscriptionId: string
): Promise<any> =>
    AxiosBackend.get('stripe/subscription/delete', { params: { subscriptionId } }).then(
    (response: AxiosResponse<any>): Promise<any> =>
      new Promise<any>((resolve, reject): void => {
        if (response.status) {
          resolve(response.status)
        }
        reject()
      })
  )

export const cancelCurrentSubscription = async (
  subscriptionId: string
): Promise<any> =>
    AxiosBackend.get('/stripe/subscription/cancel', { params: { subscriptionId } }).then(
    (response: AxiosResponse<any>): Promise<any> =>
      new Promise<any>((resolve, reject): void => {
        if (response.status) {
          resolve(response.status)
        }
        reject()
      })
  )

export const renewCurrentSubscription = async (
  subscriptionId: string
): Promise<any> =>
    AxiosBackend.get('/stripe/subscription/renew', { params: { subscriptionId } }).then(
    (response: AxiosResponse<any>): Promise<any> =>
      new Promise<any>((resolve, reject): void => {
        if (response.status) {
          resolve(response.status)
        }
        reject()
      })
  )

export const upgradeCurrentSubscription = async (
  subscriptionId: string
): Promise<any> =>
    AxiosBackend.get('/stripe/subscription/upgrade', {
    params: { subscriptionId }
  }).then(
    (response: AxiosResponse<any>): Promise<any> =>
      new Promise<any>((resolve, reject): void => {
        if (response.status) {
          resolve(response.status)
        }
        reject()
      })
  )

export const getUserSubscriptionData = async (
  stripeCustomerId: string
): Promise<any> =>
    AxiosBackend.get('/stripe/subscription/sync', { params: { stripeCustomerId } }).then(
    (response: AxiosResponse<any>): Promise<any> =>
      new Promise<any>((resolve, reject): void => {
        if (response.status === 200) {
          resolve(response.data)
        }
        reject()
      })
  )
